import { ActionButton, Table } from "components"
import { useEffect, useState } from "react"
import styles from "../vattu.module.less"
import i18n, { languageKeys } from "../../../../i18n"
import { PlusCircleFilled } from "@ant-design/icons"
import { Select } from "components_v2"
import { useDispatch, useSelector } from "react-redux"
import { doFetchManual, doFetchRouteOfUse, doFetchSupplyStorage, doFetchTransferRate, getSharedData } from "ducks/slices/ListData/SharedData.slice"
import SelectSupply from "./SelectSupply"
import { selectLoadingState } from "ducks/slices/loadingSlice"
import InputQuantity from "./InputQuantity"
import { AutoComplete, Input } from "antd"
import { getLieuTrinhData } from "ducks/slices/GoiDichVu/GoiVatTu.slice"
import moment from "moment"
import { ArrowDownCollapse, ArrowRightCollapse } from "assets/svg"
import { getUser } from "ducks/slices/authSlice"
import { formatDateOfBirth, getLot, locdau } from "helpers"

const ChiDinhVatTu = ({ supplyList = [], setSupplyList = () => {}, readOnly, planMode, index, selectedTab, isRIS, showLot }) => {

  const dispatch = useDispatch()
  const userProfile = useSelector(getUser)
  const { usedList } = useSelector(getLieuTrinhData);
  const { supplyStorage, routeOfUse, transferRate, manual } = useSelector(getSharedData)
  const loading = useSelector(selectLoadingState)

  const [expandedRow, setExpandedRow] = useState(["VTCD"])

  useEffect(() => {
    !supplyStorage[0] && dispatch(doFetchSupplyStorage())
    !routeOfUse[0] && dispatch(doFetchRouteOfUse())
    !transferRate[0] && dispatch(doFetchTransferRate())
    !manual[0] && dispatch(doFetchManual())
  }, [])

  useEffect(() => {
    usedList[0] && setExpandedRow([...usedList.flatMap(i => i.KE_HOACH_NGAY), "VTCD"])
  }, [usedList])


  const calcPlanData = usedList
    .filter((_, num) => num !== index)
    .flatMap(i => i.DANH_SACH_VAT_TU).reduce((acc, cur) => {
      const found = acc.find(val => val.VAT_TU_TRONG_KHO_ID === cur.VAT_TU_TRONG_KHO_ID)
      if (found) {
        found.SO_LUONG += Number(cur?.SO_LUONG)
      }
      else {
        acc.push({ ...cur, SO_LUONG: Number(cur?.SO_LUONG) })
      }
      return acc
    }, [])


  const handleCommonChange = (value, rowIndex, keyVal) => {
    setSupplyList(() => {
      let newArr = []
      newArr = supplyList.map((item, index) => {
        if (index === rowIndex) {
          if (keyVal === "KHO_VAT_TU_ID") {
            return { KHO_VAT_TU_ID: value }
          }
          if (keyVal === "MA_LO") {
            return { ...item, ...value }
          }
          if (keyVal === "SO_LUONG" && selectedTab === "PHAC_DO_SU_DUNG") {
            return {
              ...item,
              SO_LUONG: Number(value) + (calcPlanData.find(x => item.VAT_TU_TRONG_KHO_ID === x.VAT_TU_TRONG_KHO_ID)?.SO_LUONG ?? 0)
            }
          }
          return { ...item, [keyVal]: value }
        } else {
          return item;
        }
      })
      // Push new empty record
      if (keyVal === "SO_LUONG" && !newArr.some(i => !i.SO_LUONG)) {
        newArr.push({ KHO_VAT_TU_ID: newArr[rowIndex]?.KHO_VAT_TU_ID })
      }
      return newArr
    });
  }


  const handleSelectSupply = (record, rowIndex) => {
    // if (!!choPhepChonKho && thuoc && !Number(thuoc.TON_CUOI)) {
    //   return notification.error({
    //     placement: "bottomLeft",
    //     message: i18n.t(languageKeys.duoc_so_luong_thuoc_khong_kha_dung),
    //   });
    // }
    setSupplyList(supplyList.map((item, index) =>
      index === rowIndex
        ? {
          ...record,
          KHO_VAT_TU_ID: planMode ? record.KHO_VAT_TU_ID : item.KHO_VAT_TU_ID,
          DON_GIA: record.GIA_BAN,
          //
          TON_CUOI: planMode ? record.SO_LUONG : undefined,
          SO_LUONG: undefined,
          //
          HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
          NGAY_TAO: moment().format('YYYYMMDD'),
          GIO_TAO: moment().format('H:mm'),
          HO_TEN_NGUOI_CAP_NHAT: undefined,
          NGAY_CAP_NHAT: undefined,
          GIO_CAP_NHAT: undefined,
          //
          ...(showLot ? getLot(record.LO_VAT_TU) : {})
        }
        : item
    ));
  }


  // Columns
  const columnsVatTu = [
    {
      title: i18n.t(languageKeys.stt),
      dataIndex: "STT",
      width: 45,
      align: selectedTab === "PHAC_DO_SU_DUNG" ? '' : 'center',
      onCell: (record) => ({ colSpan: record.title ? 8 : 1 }),
      render: (STT, record) => record.title
        ? <label>{record.title}</label>
        : record.TEN_VAT_TU ? STT : <PlusCircleFilled style={{ fontSize: 20, color: 'rgba(101, 118, 255, 1)', marginLeft: 8 }} />
    },
    // {
    //   title: i18n.t(languageKeys.kho_duoc_vat_tu),
    //   dataIndex: "KHO_VAT_TU_ID",
    //   ellipsis: true,
    //   width: 170,
    //   onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
    //   render: (KHO_VAT_TU_ID, record) => record.isUsed
    //     ? supplyStorage.find(i => i.ID === KHO_VAT_TU_ID)?.TEN_KHO
    //     : (
    //       <SelectV2
    //         value={KHO_VAT_TU_ID}
    //         loading={loading}
    //         dataSource={
    //           isCommon
    //             ? supplyStorage.filter(i => i.LOAI_KHO === "KHO_TU_TRUC")
    //             : supplyStorage
    //         }
    //         onChange={(data) => handleCommonChange(data.ID, record.STT - 1, "KHO_VAT_TU_ID")}
    //         labelKey="TEN_KHO"
    //         valueKey="ID"
    //         allowClear={false}
    //         readOnly={readOnly}
    //         showSearch={false}
    //       />
    //     ),
    //   hidden: planMode,
    // },
    {
      title: i18n.t(languageKeys.ten_thuoc_vat_tu),
      dataIndex: "TEN_VAT_TU",
      width: 200,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (TEN_VAT_TU, record) => record.isUsed
        ? TEN_VAT_TU
        : /*(record.KHO_VAT_TU_ID || planMode) &&*/ (
          <SelectSupply
            supplyList={supplyList}
            record={record}
            onSelect={handleSelectSupply}
            readOnly={readOnly}
            planMode={planMode}
            initData={[{
              ID_VT: record.ID_VT,
              VAT_TU_TRONG_KHO_ID: record.VAT_TU_TRONG_KHO_ID,
              TEN_VAT_TU: record.TEN_VAT_TU,
            }]}
            lotMode={showLot}
          />
        ),
      fixed: "left",
    },
    {
      title: i18n.t(languageKeys.field_ma_lo),
      dataIndex: 'MA_LO',
      width: 180,
      render: (MA_LO, record) => record.TEN_VAT_TU && (
        <Select
          value={record.LO_ID}
          dataSource={
            record.LO_VAT_TU
              ?.filter(lo => !supplyList.some(i => i.LO_ID === lo.LO_ID) || record.LO_ID === lo.LO_ID)
              ?.map(i => ({
                ...i,
                label: `${i.MA_LO} - ${formatDateOfBirth(i.HAN_SU_DUNG)}`
              }))
          }
          onChange={(opt) => handleCommonChange(opt, record.STT - 1, "MA_LO")}
          labelKey="label"
          valueKey="LO_ID"
          filterOption={(input, option) => (locdau(option?.label) ?? '').includes(locdau(input))}
          status={!MA_LO && "error"}
          readOnly={readOnly}
          allowClear={false}
        />
      ),
      hidden: !showLot,
    },
    {
      title: i18n.t(languageKeys.field_Ten_kho),
      dataIndex: "TEN_KHO",
      width: 150,
      render: (value) => {
        return value
      },
      hidden: !showLot,
    },
    {
      title: `${i18n.t(languageKeys.field_ham_luong)}, ${i18n.t(languageKeys.field_nong_do).toLowerCase()}`,
      dataIndex: "HAM_LUONG",
      width: 150,
      align: 'center',
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (_, record) => record.HAM_LUONG || record.NONG_DO || record.THUOC_TINH
    },
    {
      title: <label className={styles.important}>{i18n.t(languageKeys.txt_So_luong)}<span> *</span></label>,
      dataIndex: "SO_LUONG",
      width: 140,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (SO_LUONG, record) => record.isUsed
        ? SO_LUONG
        : record.TEN_VAT_TU && (
          <InputQuantity
            record={record}
            onChangeInputNumber={handleCommonChange}
            value={SO_LUONG}
            readOnly={readOnly}
            index={index}
            planMode={planMode}
            selectedTab={selectedTab}
          />
        ),
    },
    {
      title: i18n.t(languageKeys.duong_dung),
      dataIndex: "DUONG_DUNG",
      ellipsis: true,
      width: 150,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (DUONG_DUNG, record) => record.isUsed
        ? DUONG_DUNG
        : record.TEN_VAT_TU && (
          <AutoComplete
            options={routeOfUse.map(i => ({ value: i.TEN_DUONG_DUNG_BYT }))}
            disabled={readOnly}
            defaultValue={DUONG_DUNG}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            allowClear
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={(val) => handleCommonChange(val, record.STT - 1, "DUONG_DUNG")}
            onBlur={(e) => handleCommonChange(e.target.value, record.STT - 1, "DUONG_DUNG")}
          />
        )
    },
    {
      title: i18n.t(languageKeys.toc_do_truyen),
      dataIndex: "TOC_DO_TRUYEN",
      ellipsis: true,
      width: 150,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (TOC_DO_TRUYEN, record) => record.isUsed
        ? TOC_DO_TRUYEN
        : record.TEN_VAT_TU && (
          <Select
            value={TOC_DO_TRUYEN?.length === 0 ? undefined : TOC_DO_TRUYEN}
            loading={loading}
            dataSource={transferRate}
            onChange={(data) => handleCommonChange(data ? data.TEN_TOC_DO_TRUYEN : "", record.STT - 1, "TOC_DO_TRUYEN")}
            labelKey="TEN_TOC_DO_TRUYEN"
            valueKey="TEN_TOC_DO_TRUYEN"
            readOnly={readOnly}
            showSearch={false}
          // filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
          />
        )
    },
    {
      title: i18n.t(languageKeys.huong_dan_su_dung),
      dataIndex: "HUONG_DAN_SU_DUNG",
      width: 200,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (HUONG_DAN_SU_DUNG, record) => record.isUsed
        ? HUONG_DAN_SU_DUNG
        : record.TEN_VAT_TU && (
          <AutoComplete
            options={manual.map(i => ({ value: i.HDSD }))}
            disabled={readOnly}
            defaultValue={HUONG_DAN_SU_DUNG}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            allowClear
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={(val) => handleCommonChange(val, record.STT - 1, "HUONG_DAN_SU_DUNG")}
            onBlur={(e) => handleCommonChange(e.target.value, record.STT - 1, "HUONG_DAN_SU_DUNG")}
          />
        )
    },
    {
      title: i18n.t(languageKeys.ghi_chu),
      dataIndex: "GHI_CHU",
      width: 200,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (GHI_CHU, record) => record.isUsed
        ? GHI_CHU
        : record.TEN_VAT_TU && (
          <Input
            defaultValue={GHI_CHU}
            placeholder={i18n.t(languageKeys.common_Nhap)}
            onBlur={(e) => handleCommonChange(e.target.value, record.STT - 1, "GHI_CHU")}
            readOnly={readOnly}
          />
        )
    },
    {
      title: i18n.t(languageKeys.nguoi_tao),
      dataIndex: "HO_TEN_NGUOI_TAO",
      width: 150,
      hidden: !planMode || isRIS,
    },
    {
      title: i18n.t(languageKeys.ngay_tao),
      dataIndex: "NGAY_TAO",
      width: 150,
      render: (_, record) =>
        (record.NGAY_TAO || record.GIO_TAO)
          ? `${record.GIO_TAO ? `${record.GIO_TAO} -` : ""} ${moment(record.NGAY_TAO).format('DD/MM/YYYY')}`
          : '',
      hidden: !planMode || isRIS,
    },
    {
      title: i18n.t(languageKeys.nguoi_cap_nhat),
      dataIndex: "HO_TEN_NGUOI_CAP_NHAT",
      width: 150,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      hidden: !planMode || isRIS,
    },
    {
      title: i18n.t(languageKeys.ngay_cap_nhat),
      dataIndex: "NGAY_CAP_NHAT",
      width: 150,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (_, record) =>
        (record.NGAY_CAP_NHAT || record.GIO_CAP_NHAT)
          ? `${record.GIO_CAP_NHAT ? `${record.GIO_CAP_NHAT} -` : ""} ${moment(record.NGAY_CAP_NHAT).format('DD/MM/YYYY')}`
          : '',
      hidden: !planMode || isRIS,
    },
    {
      title: "",
      fixed: "right",
      width: 35,
      render: (_, record) => record.old || (record.SO_LUONG && supplyList?.length > 1 && !record.title && !record.isUsed)
        ? (
          <ActionButton
            record={record}
            onDelete={() => setSupplyList(supplyList.filter((_, index) => record.STT !== (index + 1)))}
            showDelete={!readOnly}
            showEdit={false}
          />
        ) : <></>,
    },
  ];


  const formatData = () => {
    let DsVt = []
    DsVt = usedList.map(item => ({
      index: item.KE_HOACH_NGAY,
      title: moment(item.NGAY, "YYYYMMDD").format("DD/MM/YYYY"),
      children: item.DANH_SACH_VAT_TU.map((i, x) => ({ ...i, isUsed: true, STT: x + 1 }))
    }))
    DsVt = [...DsVt, {
      index: "VTCD",
      title: i18n.t(languageKeys.thuoc_vat_tu_chua_dung).toUpperCase(),
      children: supplyList.map((i, x) => ({
        ...i,
        SO_LUONG: i.SO_LUONG - (i.old ? (calcPlanData.find(x => i.VAT_TU_TRONG_KHO_ID === x.VAT_TU_TRONG_KHO_ID)?.SO_LUONG ?? 0) : 0),
        STT: x + 1,
      }))
    }]
    return DsVt.filter(i => i.children[0])
  }


  return (
    <div className={styles["container"]}>
      <Table
        className={styles.table}
        columns={columnsVatTu.filter(i => !i.hidden)}
        dataSource={
          selectedTab === "PHAC_DO_SU_DUNG"
            ? formatData()
            : supplyList.map((item, index) => ({ STT: index + 1, ...item }))
        }
        scroll={{
          x: columnsVatTu.reduce((sum, item) => sum + item.width, 0),
          y: 500
        }}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowKeys: expandedRow,
          onExpand: (expanded, record) => setExpandedRow(!expanded ? expandedRow.filter(i => i !== record.index) : [...expandedRow, record.index]),
          expandIcon: ({ expanded, onExpand, record }) =>
            record.children?.length
              ? expanded
                ? <ArrowDownCollapse onClick={(e) => onExpand(record, e)} />
                : <ArrowRightCollapse onClick={(e) => onExpand(record, e)} />
              : <></>,
        }}
        rowKey={selectedTab === "PHAC_DO_SU_DUNG" ? "index" : "STT"}
      />
    </div>
  )
}

export default ChiDinhVatTu