import { Select } from "components_v2";
import i18n, { languageKeys } from "../../../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { doFetchMedSupplies, getSupplies } from "ducks/slices/ListData/Supply.slice";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { getLieuTrinhData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";

const SelectSupply = ({ supplyList, record, onSelect, readOnly, planMode, initData, lotMode }) => {

  const [data, setData] = useState([])
  // const [currStorageId, setCurrStorageId] = useState()
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  const { loading } = useSelector(getSupplies)
  const { supplyPlanList } = useSelector(getLieuTrinhData);


  const handleFetchSupplies = (search_string) => {
    // if ((!currStorageId || currStorageId !== record.KHO_VAT_TU_ID) && !planMode) {
    //   setCurrStorageId(record.KHO_VAT_TU_ID)
    //   dispatch(doFetchSuppliesFromStorage({ search_string: "", KHO_VAT_TU_ID: record.KHO_VAT_TU_ID, setData }))
    // }
    dispatch(doFetchMedSupplies({ search_string, setData, lotMode }))
  }


  const handleOpen = (state) => {
    state && !data[0] && !planMode && handleFetchSupplies("")
    setOpen(state)
  }


  const handleSearch = useCallback(
    debounce((search_string) => handleFetchSupplies(search_string)
      , 1000), []
  )


  const getDataSource = () => {
    if (!open) {
      return initData;
    } else {
      if (lotMode) {
        return (
          data
            .map(item => ({
              ...item,
              LO_VAT_TU: item.LO_VAT_TU?.filter(lo => !supplyList.some(i => i.LO_ID === lo.LO_ID) || (record.LO_ID === lo.LO_ID))
            }))
            .filter(i => i.LO_VAT_TU?.[0])
        )
      } else {
        return (
          planMode
            ? supplyPlanList.filter(i => i.VAT_TU_TRONG_KHO_ID)
            : data
        )
          .filter(item => !supplyList
            .some(i => i.VAT_TU_TRONG_KHO_ID === item.VAT_TU_TRONG_KHO_ID) ||
            record.VAT_TU_TRONG_KHO_ID === item.VAT_TU_TRONG_KHO_ID
          )
      }
    }
  }


  return (
    <Select
      dataSource={
        // (
        //   planMode
        //     ? supplyPlanList.filter(i => i.VAT_TU_TRONG_KHO_ID)
        //     : !open
        //       ? initData
        //       : data
        // )
        //   .filter(item => !supplyList
        //     .some(i => i.VAT_TU_TRONG_KHO_ID === item.VAT_TU_TRONG_KHO_ID /*&& i.KHO_VAT_TU_ID === item.KHO_VAT_TU_ID*/)
        //     || (record.VAT_TU_TRONG_KHO_ID === item.VAT_TU_TRONG_KHO_ID /*&& record.KHO_VAT_TU_ID === item.KHO_VAT_TU_ID*/)
        //   )
        getDataSource()
      }

      value={
        lotMode
          ? record.ID_VT
          : record.VAT_TU_TRONG_KHO_ID
      }
      onChange={(data) => onSelect(data, record.STT - 1)}
      labelKey={"TEN_VAT_TU"}
      valueKey={lotMode ? "ID_VT" : "VAT_TU_TRONG_KHO_ID"}

      tableProps={{
        visible: true,
        columns,
      }}

      showSearch={!planMode}
      onSearch={(e) => !planMode && handleSearch(e/*, record.KHO_VAT_TU_ID*/)}
      // onFocus={handleFetchSupplies}
      filterOption={false}

      open={open}
      onDropdownVisibleChange={handleOpen}

      loading={loading}
      listHeight={300}
      allowClear={false}
      readOnly={readOnly}
    />
  )
}

export default SelectSupply;

const columns = [
  {
    title: i18n.t(languageKeys.ma_thuoc_vt),
    dataIndex: 'MA_VAT_TU',
    ellipsis: true,
    width: 120,
  },
  {
    title: i18n.t(languageKeys.ten_thuoc_vtyt_hoa_chat),
    dataIndex: 'TEN_VAT_TU',
    ellipsis: true,
    width: 250
  },
  {
    title: i18n.t(languageKeys.field_Don_vi_tinh),
    dataIndex: 'TEN_DVT',
    ellipsis: true,
    width: 140,
    render: (_, record) => record.TEN_DVT_QD || record.TEN_DVT
  },
  // {
  //   title: i18n.t(languageKeys.nong_do_ham_luong),
  //   dataIndex: 'HAM_LUONG',
  //   width: 150,
  //   render: (HAM_LUONG: string, record: Record<string, any>) => HAM_LUONG ? HAM_LUONG : record.NONG_DO
  // },
  // {
  //   title: i18n.t(languageKeys.duoc_ton_kha_dung),
  //   dataIndex: 'SO_LUONG',
  //   ellipsis: true,
  //   width: 120,
  // },
  // {
  //   title: i18n.t(languageKeys.field_ma_lo),
  //   dataIndex: 'MA_LO',
  //   ellipsis: true,
  //   width: 90,
  // },
  {
    title: i18n.t(languageKeys.field_ton_kho),
    dataIndex: "TONG_TON_KHO",
    ellipsis: true,
    width: 120,
  },
]