import { Input, Layout, Segmented } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { SearchOutlined } from "@ant-design/icons";
import ListDvKhamBenh from "pages/QuanLyTiepDon/components/ListDvKhamBenh/ListDvKhamBenh";
import ListDvCls from "pages/QuanLyTiepDon/components/ListDvCls/ListDvCls";
import styles from "../vattu.module.less";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableDeXuat from "./TableDeXuat";
import { formatRateNumber } from "pages/QuanLyGoiDichVu/DanhSachGoiDichVu/helpers";
import { calcTotal } from "helpers";
import ListKHSD from "./ListKHSD";
import moment from "moment";
import { getUser } from "ducks/slices/authSlice";
import { doFetchCustomerTarget, getSharedData } from "ducks/slices/ListData/SharedData.slice";

const ChiDinhDvGoi = ({ dataSource = [], setDataSource = () => {}, planMode, selectedTab, index, readOnly, formBundle }) => {

  const dispatch = useDispatch()
  const userProfile = useSelector(getUser)
  const data = useSelector((state) => state.data)
  const { customerTarget } = useSelector(getSharedData)

  const [currentTab, setCurrentTab] = useState("TAB_KHAM_BENH");
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    !customerTarget[0] && dispatch(doFetchCustomerTarget())
  }, [])


  const initData = useMemo(() => {
    try {
      let result = { cls: [], kham_benh: [] };

      if (Array.isArray(data.cls)) result = { ...result, cls: data.cls.filter(i => !i.DICH_VU?.some(x => x.MA_NHOM_BHYT === "COMBO_DV")) };
      if (Array.isArray(data.kham_benh)) result = { ...result, kham_benh: data.kham_benh };

      return result;
    } catch (error) {
      return { cls: [], kham_benh: [] };
    }
  }, [data]);


  const getNewRateArr = (arr = []) => {
    if (arr[0]) {
      let newData = arr.map(item => ({
        ...item,
        TY_LE: formatRateNumber(item.TONG_TIEN / calcTotal(arr, "TONG_TIEN") * 100),
      }))
      const currRate = calcTotal(newData, "TY_LE")
      if (currRate !== 100) {
        newData[0] = {
          ...newData[0],
          TY_LE: (Number(newData[0].TY_LE) + 100 - currRate).toFixed(2),
        }
      }
      return newData;
    } else {
      return arr;
    }
  }


  const onTreeSelect = (data, setExpandedKeys = () => {}) => {
    if (data.disabled) {
      return;
    }
    if (data.selectable) {

      if (data.checked) {
        setDataSource(() => {
          const newArr = dataSource.filter((item) => item.DV_ID !== data.value.DV_ID)
          return getNewRateArr(newArr)
        })
      }

      else {
        //
        const initQuantity = formBundle?.getFieldValue("SO_BUOI_SU_DUNG") ?? 1
        //
        // Check chọn dv trùng dv có trong combo
        if (dataSource.some(s => s.DV_KEM_THEO?.some(i => i.DV_KEM_THEO_ID === data.value.DV_ID))) {
          return;
        }
        // Check chọn combo có dv trùng
        let newArr = dataSource.filter(s => !data.value?.DV_KEM_THEO?.some(i => i.DV_KEM_THEO_ID === s.DV_ID))
        //
        newArr = [
          ...newArr,
          {
            ...data.value,
            //
            SO_LUONG: initQuantity,
            DON_GIA: data.value.GIA_DICH_VU,
            THANH_TIEN: data.value.GIA_DICH_VU * initQuantity,
            DON_GIA_PHAN_BO: data.value.GIA_DICH_VU,
            THANH_TIEN_PHAN_BO: data.value.GIA_DICH_VU * initQuantity,
            MIEN_GIAM: 0,
            MIEN_GIAM_PHAN_TRAM: null,
            TONG_TIEN: data.value.GIA_DICH_VU * initQuantity,
            TY_LE: "0.0",
            PHONG_THUC_HIEN_ID: data.value.LIST_PHONG_ID?.[0],
            //
            HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
            NGAY_TAO: moment().format('YYYYMMDD'),
            GIO_TAO: moment().format('H:mm'),
            //
            // DV_KEM_THEO: data.value?.DV_KEM_THEO?.map(dvkt => ({
            //   ...dvkt,
            //   SO_LUONG: initQuantity,
            //   DON_GIA: dvkt.GIA_DICH_VU,
            //   THANH_TIEN: dvkt.GIA_DICH_VU * initQuantity,
            //   DON_GIA_PHAN_BO: dvkt.GIA_DICH_VU,
            //   THANH_TIEN_PHAN_BO: dvkt.GIA_DICH_VU * initQuantity,
            //   MIEN_GIAM: 0,
            //   MIEN_GIAM_PHAN_TRAM: null,
            //   TONG_TIEN: dvkt.GIA_DICH_VU * initQuantity,
            //   TY_LE: "0.0",
            // })),
            // DM_DOI_TUONG_ID: customerTarget?.[0]?.ID,
          },
        ]
        // Tính lại tỷ lệ
        newArr = getNewRateArr(newArr)
        // Set new datasource
        setDataSource(newArr);
      }
    } else {
      if (data.expanded) {
        setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.key));
      } else {
        setExpandedKeys((currentArr) => [...currentArr, data.key]);
      }
    }
  }


  return (
    <Layout style={{ background: '#F4F7FF' }}>
      <Layout.Sider className={styles["sider-form"]}>

        {!planMode && (
          <Segmented
            className={styles["segmented"]}
            options={[
              { value: "TAB_KHAM_BENH", label: i18n.t(languageKeys.kham_benh_short) },
              { value: "TAB_CLS", label: i18n.t(languageKeys.dich_vu) },
            ]}
            value={currentTab}
            onChange={setCurrentTab}
            block
          />
        )}

        <Input
          prefix={<SearchOutlined className="blue-txt" />}
          placeholder={i18n.t(languageKeys.common_Tim_kiem)}
          style={{ marginBottom: 10 }}
          value={searchVal}
          onChange={({ target }) => setSearchVal(target.value)}
          allowClear
        />

        {!planMode
          ? (
            <div className={styles["options"]}>

              <div style={currentTab !== "TAB_KHAM_BENH" ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
                <ListDvKhamBenh
                  dsDvDaChon={dataSource.filter(i => i.PHAN_LOAI === "KHAM_BENH")}
                  dsDvKhamBenh={initData.kham_benh}
                  searchVal={searchVal}
                  onTreeKBSelect={onTreeSelect}
                  disabled={readOnly}
                />
              </div>

              <div style={currentTab !== "TAB_CLS" ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
                <ListDvCls
                  dsDvCls={initData.cls}
                  dsDvDaChon={dataSource.filter(i => i.PHAN_LOAI !== "KHAM_BENH")}
                  search_string={searchVal}
                  onTreeClsSelect={onTreeSelect}
                  disabled={readOnly}
                  showFavourite={false}
                />
              </div>

            </div>
          ) : (
            <div className={styles["options"]} style={{ marginLeft: -8 }}>
              <ListKHSD
                dataSource={dataSource}
                searchVal={searchVal}
                setDataSource={setDataSource}
                index={index}
                disabled={readOnly}
              />
            </div>
          )}

      </Layout.Sider>

      <TableDeXuat
        dataSource={dataSource}
        setDataSource={setDataSource}
        planMode={planMode}
        selectedTab={selectedTab}
        index={index}
        readOnly={readOnly}
        formBundle={formBundle}
      />

    </Layout>
  )
}

export default ChiDinhDvGoi;