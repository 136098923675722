import { notification } from "antd";
import { apis, keys } from "../../constants";
import { common_post, HLog, isJsonString } from "../../helpers";
import i18n, { languageKeys } from "../../i18n";
import { fieldDantoc, fieldNgheNghiep, fieldQuocGia } from "../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";
import { fieldTiepDon } from "./fieldsTiepDon";

export const apiLayDsDaTiepNhan = async ({ search_string = "", page = 1, BENH_VIEN_ID, partner_code, TU = undefined, DEN = undefined }) => {
  try {
    const req = {
      BENH_VIEN_ID,
      partner_code,
      limit: keys.limit,
      search_string,
      page,
      TU,
      DEN,
    };
    HLog("apisTiepDon apiLayDsDaTiepNhan...");
    const res = await common_post(apis.tiep_don_ds_da_tiep_nhan, req, false);

    if (res.status === "OK") {
      return res;
    } else {
      HLog("Lay danh sach lich kham da tiep nhan fail", res);
      return null;
    }
  } catch (error) {
    HLog("Lay danh sach lich kham da tiep nhan error", error);
    return null;
  }
};

export const apiLayDsDoiTuongBenhNhan = async () => {
  try {
    const req = {};

    const res = await common_post(apis.tiep_don_ds_doi_tuong_benh_nhan, req, false);

    if (res.status === "OK") return res.result;
    else HLog("Lay ds doi tuong benh nhan fail", res);
  } catch (error) {
    HLog("Lay ds doi tuong benh nhan error", error);
  }
};

export const apiTimKiemBenhNhan = async ({ search_string = "", partner_code, limit = keys.limit,SO_DIEN_THOAI,isSuggest = false }) => {
  try {
    const req = {
      SO_DIEN_THOAI,
      search_string,
      limit,
      from_partner: false,
      partner_code,
      isSuggest,
    };

    const res = await common_post(apis.tiep_don_ds_benh_nhan, req, false);

    if (res.status === "OK") return res.result;
    else HLog("Lay danh sach benh nhan fail", res);
  } catch (error) {
    HLog("Lay danh sach benh nhan error", error);
  }
};

export const apiLayDsPhongTheoDvkt = async ({
  DV_ID = "",
  search_string = "",
  partner_code,
  limit = keys.limit,
  PHAN_LOAI = "",
  TEN_NHOM_BHYT = "",
  NHOM_BHYT_ID = null,
}) => {
  try {
    const req = {
      partner_code,
      DV_ID,
      search_string,
      limit,
      PHAN_LOAI,
      TEN_NHOM_BHYT,
      NHOM_BHYT_ID,
    };

    const res = await common_post(apis.tiep_don_ds_phong_theo_dv, req, false);

    if (res.status === "OK") return res.result;
    else HLog("Lay danh sach phong theo dich vu fail", res);
  } catch (error) {
    HLog("Lay danh sach phong theo dich vu error", error);
  }
};

export const apiTaoBenhNhan = async ({ data, BENH_VIEN_ID, partner_code }) => {
  try {
    if (!!data[fieldTiepDon.ten_benh_nhan]) {
      const ten_benh_nhan = data[fieldTiepDon.ten_benh_nhan];
      const ho_benh_nhan = ten_benh_nhan.split(" ")[0];
      data[fieldTiepDon.ho_benh_nhan] = ho_benh_nhan;
    }
    if (!!data[fieldTiepDon.dan_toc] && isJsonString(data[fieldTiepDon.dan_toc])) {
      const parseVal = JSON.parse(data[fieldTiepDon.dan_toc]);
      data[fieldTiepDon.dan_toc] = parseVal[fieldDantoc.ten_dan_toc];
      data[fieldTiepDon.ma_dan_toc] = parseVal[fieldDantoc.ma_dan_toc];
    }
    if (!!data[fieldTiepDon.nghe_nghiep] && isJsonString(data[fieldTiepDon.nghe_nghiep])) {
      const parseVal = JSON.parse(data[fieldTiepDon.nghe_nghiep]);
      data[fieldTiepDon.nghe_nghiep] = parseVal[fieldNgheNghiep.ten_nghe_nghiep];
      data[fieldTiepDon.ma_nghe_nghiep] = parseVal[fieldNgheNghiep.ma_nghe_nghiep];
    }
    if (!!data[fieldTiepDon.quoc_gia] && isJsonString(data[fieldTiepDon.quoc_gia])) {
      const parseVal = JSON.parse(data[fieldTiepDon.quoc_gia]);
      data[fieldTiepDon.quoc_gia] = parseVal[fieldQuocGia.ten_quoc_gia];
      data[fieldTiepDon.ma_quoc_gia] = parseVal[fieldQuocGia.ma_quoc_gia];
    }

    // if (!!data[fieldTiepDon.tinh_thanh]) {
    //   const parseVal = JSON.parse(data[fieldTiepDon.tinh_thanh]);
    //   data[fieldTiepDon.tinh_thanh] = parseVal[fieldTinh.ten_tinh];
    //   data[fieldTiepDon.ma_tinh_thanh] = parseVal[fieldTinh.id_tinh];
    // }
    // if (!!data[fieldTiepDon.quan_huyen]) {
    //   const parseVal = JSON.parse(data[fieldTiepDon.quan_huyen]);
    //   data[fieldTiepDon.quan_huyen] = parseVal[fieldHuyen.ten_huyen];
    //   data[fieldTiepDon.ma_quan_huyen] = parseVal[fieldHuyen.id_huyen];
    // }
    // if (!!data[fieldTiepDon.xa_phuong]) {
    //   const parseVal = JSON.parse(data[fieldTiepDon.xa_phuong]);
    //   data[fieldTiepDon.xa_phuong] = parseVal[fieldXa.ten_xa];
    //   data[fieldTiepDon.ma_xa_phuong] = parseVal[fieldXa.id_xa];
    // }

    // if (!!data[fieldTiepDon.t_h_x]) {
    //   data[fieldTiepDon.t_h_x] = data[fieldXa.code_thx];
    // }

    const req = {
      ...data,
      BENH_VIEN_ID,
      partner_code,
    };
    HLog("xxx - 2", req);
    const res = await common_post(apis.tiep_don_tao_benh_nhan, req, false);

    if (res.status === "OK") return res;
    else HLog("Tao benh nhan fail", res);
  } catch (error) {
    HLog("Tao benh nhan error", error);
  }
};

export const apiLuuDangKiKham = async ({ data, BENH_VIEN_ID, partner_code }) => {
  try {
    let benh_nhan_id = data[fieldTiepDon.benh_nhan_id];

    if (!benh_nhan_id) {
      const res = await apiTaoBenhNhan({ data, BENH_VIEN_ID, partner_code });

      if (!!res) {
        benh_nhan_id = res.ID;
      } else return false;
    } else {
      benh_nhan_id = data.ID;
      return;

      const res = await common_post(apis.tiep_don_kiem_tra_thong_tin, {
        ...data,
        partner_code,
      });
      if (res.status !== "OK") return false;
    }

    // if (data[fieldTiepDon.thong_tin_kham].length > 0) {
    //   const dich_vu = fieldTiepDon.yeu_cau_kham;
    //   const phong_kham = fieldTiepDon.phong_kham;
    //   const uu_tien = fieldTiepDon.uu_tien;
    //   const gia_dich_vu = fieldTiepDon.gia_dich_vu;
    //   const id_khoa = fieldTiepDon.khoa_id;

    //   let list = data[fieldTiepDon.thong_tin_kham];

    //   data[fieldTiepDon.thong_tin_kham] = list.map((item) => ({
    //     [dich_vu]: JSON.parse(item[dich_vu])[fieldDichVuKiThuat.id],
    //     [phong_kham]: JSON.parse(item[phong_kham])[fieldPhong.id],
    //     [uu_tien]: data[uu_tien],
    //     [gia_dich_vu]: JSON.parse(item[dich_vu])[
    //       fieldDichVuKiThuat.gia_dich_vu
    //     ],
    //     [id_khoa]: JSON.parse(item[phong_kham])[fieldPhong.khoa_id],
    //   }));
    // }

    const req = {
      ...data,
      [fieldTiepDon.benh_nhan_id]: benh_nhan_id,
      BENH_VIEN_ID,
      partner_code,
      NGUON_DAT_KHAM: keys.PLATFORM.TAI_VIEN,
    };

    const res = await common_post(apis.tiep_don_luu_dang_ky_kham, req, false);

    if (res.status === "OK") return res;
    else {
      HLog("Luu dang ky kham fail", res);

      if (res.error === "No data") {
        notification.error({
          message: i18n.t(languageKeys.noti_Chua_co_thong_tin_kham),
          placement: "bottomLeft",
        });
      }
    }
  } catch (error) {
    HLog("Luu dang ky kham error", error);
  }
};

export const apiLayDsPhieuKham = async ({ LICH_KHAM_ID, BENH_VIEN_ID, partner_code }) => {
  const req = {
    LICH_KHAM_ID,
    BENH_VIEN_ID,
    partner_code,
  };

  try {
    const res = await common_post(apis.lay_ds_phieu_tiep_don, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    HLog("lay ds id phieu", error);
    return null;
  }
};
export const apiLayChiTietLichKham = async (dataRequest) => {
  try {
    const res = await common_post(apis.tiep_don_chi_tiet_lich_kham, dataRequest);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    HLog("lay ds id phieu", error);
    return null;
  }
};

export const apiXoaCuocKham = async (dataRequest) => {
  try {
    const res = await common_post(apis.xoa_cuoc_hen, dataRequest);
    if (res.status === "OK") {
      return res;
    }
    if (res.status === "KO" && res.error?.error_code === "023") {
      notification.error({
        message: i18n.t(languageKeys.ko_the_huy_do_cap_nhat_trang_thai),
        placement: "bottomLeft",
      });
      return res;
    }
    if (res.status === "KO" && res.error_code === "017") {
      notification.error({
        message: i18n.t(languageKeys.khong_duoc_phep_xoa_lich_kham),
        placement: "bottomLeft",
      });
      return res;
    }
    return null;
  } catch (error) {
    HLog("Xoa cuoc hẹn lỗi", error);
    return null;
  }
};
