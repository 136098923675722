import { Button, Col, DatePicker, Form, Input, InputNumber, Layout, Row, Segmented, Spin, Tooltip, notification } from "antd";
import i18n, { languageKeys } from "../../../i18n";
import { Select, Table } from "components";
import styles from "./styles.module.less";
import { Collapse } from "components/Collapse";
import { ActionType, BundleGroup, BundleGroupSCT, DataIndexOfServiceTable, PacketServiceInfo } from "./constant";
import { ArrowDownCollapse, ArrowRightCollapse, CopyIcon, SmallPlus } from "assets/svg";
import { TAB_DICH_VU, TAB_KHAM_BENH } from "pages/QuanLyTiepDon/ModalTiepDon.new/ModalTiepDon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import ListDvKhamBenh from "pages/QuanLyTiepDon/components/ListDvKhamBenh/ListDvKhamBenh";
import ListDvCls from "pages/QuanLyTiepDon/components/ListDvCls/ListDvCls";
import ActionButton from "components/ActionButton/ActionButton";
import { calcTotal, common_post, formatCurrency2, handleCheckPermissions, isEmptyObject } from "helpers";
import moment from "moment";
import {
  addPacketService,
  clearCommissionLocal,
  editPacketService,
  getDetailPacketService,
  getServicePackages,
  resetDataSourceTableService,
  setDataSourceTableService,
  updateIsModalExemption,
} from "ducks/slices/GoiDichVu/DSGoiDichVu/DSGoiDichVuSlice";
import { buttonLoadingState, modalLoadingState } from "ducks/slices/loadingSlice";
import { apis } from "../../../constants";
import { featureKeys } from "constants/keys";
import Exemption from "./Exemptions";
import { useEventListener } from "hooks";
import ChiDinhVatTu from "../GoiLieuTrinh/ChiDinhVatTu";
import SelectChangeTarget from "components_v2/SelectChangeTarget/SelectChangeTarget";
import { doFetchCustomerTarget, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import SelectPhongThHien from "../GoiLieuTrinh/ChiDinhDvGoi/SelectPhongThHien";
import { usePacketService } from "./hooks";
import InputAutoFillPrice from "../GoiLieuTrinh/ChiDinhDvGoi/InputAutoFillPrice";


const tabs = [TAB_KHAM_BENH, TAB_DICH_VU];
export const MIEN_GIAM_GOI = "MIEN_GIAM";

const FormGoiDichVu = ({ setVisible, visible, packetServiceId, actionType }) => {

  const [form] = Form.useForm();
  const [formExemptionModal] = Form.useForm()

  const exemptionRef = useRef()
  const syncTargetRef = useRef()

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const data = useSelector((state) => state.data);
  const { customerTarget } = useSelector(getSharedData)
  const isLoadingButton = useSelector(buttonLoadingState)
  const isModalLoading = useSelector(modalLoadingState)

  const initData = useMemo(() => {
    try {
      let result = { cls: [], kham_benh: [] };

      if (Array.isArray(data.cls)) result = { ...result, cls: data.cls };
      if (Array.isArray(data.kham_benh)) result = { ...result, kham_benh: data.kham_benh };

      return result;
    } catch (error) {
      return { cls: [], kham_benh: [] };
    }
  }, [data]);

  const [selectedLoaiDvCls, setSelectedLoaiDvCls] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [idCopyServicePacket, setIdCopyServicePacket] = useState()
  const [optionAvailablePacket, setOptionAvailablePacket] = useState([])
  const [oldExpiredDate, setOldExpiredDate] = useState(moment().format("YYYY-MM-DD"))
  const [nhomDoiTuongNguoiDung, setNhomDoiTuongNguoiDung] = useState([])
  const [supplyList, setSupplyList] = useState([{}])

  const userProfile = useSelector((state) => state.auth.user);
  const { servicePackTypeList } = useSelector((state) => state.servicePacket.ServicePackType);
  const { dataSourceTableService, packetServiceDetail } = useSelector(getServicePackages);
  const persmission = handleCheckPermissions(userProfile, featureKeys.danh_sach_goi_dv);

  // const NHOM_GOI = Form.useWatch(PacketServiceInfo.NHOM_GOI, form);

  // Functions
  const { onTreeSelect, handleChangeTarget, handleSelectRoom, handleAutoCalc, handleDelete } = usePacketService({ syncTargetRef })


  useEffect(() => {
    fetchNhomDoiTuongNguoiDung()
    !customerTarget[0] && dispatch(doFetchCustomerTarget())
  }, [])


  useEffect(() => {
    if (!isEmptyObject(packetServiceDetail)) {
      //
      if (actionType === ActionType.create) {
        form.setFieldValue(PacketServiceInfo.NGAY_TAO, moment().format("DD/MM/YYYY"));
        form.setFieldValue(PacketServiceInfo.NGUOI_TAO, `${userProfile?.HO} ${userProfile?.TEN}`);
        if (idCopyServicePacket) {
          setSupplyList(packetServiceDetail.VAT_TU);
        }
      } else if (actionType === ActionType.edit) {
        if (!!packetServiceDetail) {
          setOldExpiredDate(moment(packetServiceDetail?.NGAY_HET_HAN, "YYYYMMDD"));
          const values = Object.values(PacketServiceInfo).map((i) => {
            if (i === PacketServiceInfo.NGAY_HET_HAN) {
              return {
                name: i,
                value: moment(packetServiceDetail[i]),
              };
            }
            if (i === PacketServiceInfo.NGAY_HIEU_LUC) {
              return {
                name: i,
                value: moment(packetServiceDetail[i]),
              };
            }
            if (i === PacketServiceInfo.NGAY_TAO) {
              return {
                name: i,
                value: moment(packetServiceDetail[i]).format("DD/MM/YYYY"),
              };
            }
            if (i === PacketServiceInfo.NGAY_CAP_NHAT) {
              return {
                name: i,
                value: moment().format("DD/MM/YYYY"),
              };
            }
            if (i === PacketServiceInfo.NGUOI_CAP_NHAT) {
              return {
                name: i,
                value: packetServiceDetail.NGUOI_CAP_NHAT,
              };
            }
            if (i === PacketServiceInfo.NGUOI_TAO) {
              return {
                name: i,
                value: packetServiceDetail.NGUOI_TAO_HIEN_TAI,
              };
            }
            return {
              name: i,
              value: packetServiceDetail[i],
            };
          });
          form.setFields(values);
          setSupplyList(packetServiceDetail.VAT_TU);
        }
      } else if (actionType === ActionType.copy) {
        form.setFieldValue(PacketServiceInfo.NGAY_TAO, moment().format("DD/MM/YYYY"));
        form.setFieldValue(PacketServiceInfo.NGUOI_TAO, `${userProfile?.HO} ${userProfile?.TEN}`);
        // dispatch(getDetailPacketService({ id: packetServiceId, isCopy: true, currentDetail: packetServiceDetail, actionType }))
        setSupplyList(packetServiceDetail.VAT_TU);
      }
    }
    if (!visible) {
      form.resetFields();
      formExemptionModal.resetFields();
      // setDsKhamBenhDaChon([])
      setSelectedLoaiDvCls(null)
      // setDsDvClsDaChon([])
      setCurrentTab(tabs[0])
      setIdCopyServicePacket()
      setOldExpiredDate(moment().format("YYYY-MM-DD"))
      !isEmptyObject(packetServiceDetail) && dispatch(resetDataSourceTableService())
      setSupplyList([{}])
    }
  }, [visible, userProfile, packetServiceDetail, actionType, packetServiceId]);


  useEffect(() => {
    // setDsKhamBenhDaChon(dataSourceTableService);
    // setDsDvClsDaChon(dataSourceTableService);
    form.setFieldValue(
      PacketServiceInfo.GIA_GOI_DV,
      calcTotal(dataSourceTableService, "TONG_TIEN")
    );
  }, [dataSourceTableService])


  useEffect(() => {
    fetchOptionAvaiableOption();
  }, [packetServiceId, actionType]);


  useEffect(() => {
    if (actionType === ActionType.edit && !isEmptyObject(packetServiceDetail)) {
      const { MIEN_GIAM } = packetServiceDetail;
      formExemptionModal.setFieldValue(
        MIEN_GIAM_GOI,
        MIEN_GIAM?.map((item) => {
          return {
            DM_NHOM_NGUON_KHACH_ID: +item.NHOM_DOI_TUONG_ID,
            MIEN_GIAM_DATA: item?.MIEN_GIAM?.map((item) => ({
              customer: item.TEN_NGUON_KHACH,
              checked: item.MIEN_GIAM > 0,
              exemption: item.MIEN_GIAM,
              id: item.NGUON_KHACH_ID,
            })),
          };
        })
      );
    }
  }, [packetServiceDetail]);


  const fetchNhomDoiTuongNguoiDung = async () => {
    const res = await common_post(apis.getNhomNguonKhach, {
      limit: 100,
    });
    if (!!res && res.status === "OK") {
      setNhomDoiTuongNguoiDung(res.result);
    }
  };


  const fetchOptionAvaiableOption = async () => {
    // A Tuấn (BE) bảo truyền thế này :(
    const res = await common_post(apis.get_packet_service_list, {
      TU_NGAY: "20200101",
      DEN_NGAY: moment().format("YYYYMMDD"),
      limit: 1000,
      NHOM_GOI: ["DICH_VU", "GOI_LIEU_TRINH"],
    });
    if (res.status === "OK") {
      let options;
      if (actionType === ActionType.create || actionType === ActionType.copy) {
        options = res.result;
      } else if (actionType === ActionType.edit) {
        options = res.result.filter((i) => i.ID !== packetServiceId);
      }
      setOptionAvailablePacket(options);
    } else {
      notification.error({
        message: i18n.t(languageKeys.common_co_loi_xay_ra),
      });
    }
  };


  const ColumnTablePacketService = [
    {
      title: i18n.t(languageKeys.field_Ma_dich_vu),
      dataIndex: 'MA_DICHVU',
      width: 100,
      render: (_, record) => record.MA_DICHVU || record.MA_DV
    },
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      dataIndex: 'TEN_DICHVU',
      width: 200,
      render: (_, record) => record.TEN_DICHVU || record.TEN_DICH_VU_KEM_THEO || record.TEN_DV
    },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "SO_LUONG",
      width: 75,
      render: (SO_LUONG, record) => (
        <InputNumber
          value={SO_LUONG}
          min={1}
          onChange={(value) => handleAutoCalc(value, "SO_LUONG", record)}
          readOnly={record.DV_KEM_THEO_ID || !persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
        />
      ),
    },
    {
      title: i18n.t(languageKeys.cate_doi_tuong),
      dataIndex: "LOAI_GIA",
      width: 140,
      render: (id, record) => !id && actionType === ActionType.edit
        ? (
          <Input value={i18n.t(languageKeys.title_Gia_dich_vu)} readOnly />
        ) : (
          <SelectChangeTarget
            id={id}
            record={record}
            syncTargetRef={syncTargetRef}
            handleChangeTarget={handleChangeTarget}
            dataSource={dataSourceTableService}
            readOnly={!persmission.SUA || ((actionType === ActionType.edit) && !!packetServiceDetail?.BENH_NHAN_ID)}
          />
        )
    },
    {
      title: i18n.t(languageKeys.field_don_gia),
      dataIndex: "DON_GIA",
      width: 105,
      render: (DON_GIA) => formatCurrency2(DON_GIA, '', true)
    },
    {
      title: i18n.t(languageKeys.thanh_tien),
      dataIndex: "THANH_TIEN",
      width: 105,
      render: (THANH_TIEN) => formatCurrency2(THANH_TIEN, '', true)
    },
    {
      title: i18n.t(languageKeys.don_gia_phan_bo),
      dataIndex: "DON_GIA_PHAN_BO",
      ellipsis: true,
      width: 145,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (DON_GIA_PHAN_BO, record) => (
        <InputNumber
          defaultValue={0}
          value={DON_GIA_PHAN_BO}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          onChange={(value) => handleAutoCalc(value, "DON_GIA_PHAN_BO", record)}
          controls={false}
          readOnly={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
        />
      ),
    },
    {
      title: i18n.t(languageKeys.thanh_tien_phan_bo),
      dataIndex: "THANH_TIEN_PHAN_BO",
      width: 140,
      render: (THANH_TIEN_PHAN_BO) => formatCurrency2(THANH_TIEN_PHAN_BO, "", true),
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      dataIndex: "MIEN_GIAM_PHAN_TRAM",
      ellipsis: true,
      width: 145,
      render: (MGTD, record) => (
        <InputNumber
          defaultValue={0}
          value={MGTD ?? record.MIEN_GIAM}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          max={record.THANH_TIEN_PHAN_BO}
          onChange={(value) => handleAutoCalc(value, "MIEN_GIAM", record)}
          controls={false}
          readOnly={record?.isDV}
          addonAfter={(MGTD || record.MIEN_GIAM === 0) ? "%" : "đ"}
        />
      )
    },
    {
      title: i18n.t(languageKeys.tong_tien),
      dataIndex: "TONG_TIEN",
      width: 105,
      render: (TONG_TIEN) => formatCurrency2(TONG_TIEN, "", true)
    },
    {
      title: i18n.t(languageKeys.ty_le),
      dataIndex: "TY_LE",
      width: 60,
    },
    {
      title: <>{i18n.t(languageKeys.noi_thuc_hien)}<span style={{ color: 'red', marginLeft: '4px', fontSize: '16px' }}>*</span></>,
      dataIndex: "PHONG_THUC_HIEN_ID",
      width: 200,
      render: (PHONG_THUC_HIEN_ID, record) => !record.DV_KEM_THEO?.length && (
        <SelectPhongThHien
          value={PHONG_THUC_HIEN_ID}
          record={record}
          onSelect={handleSelectRoom}
          readOnly={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
        />
      ),
    },
    {
      title: i18n.t(languageKeys.nguoi_cap_nhat),
      key: "NGUOI_CAP_NHAT",
      width: 150,
    },
    {
      title: i18n.t(languageKeys.ngay_cap_nhat),
      dataIndex: "NGAY_CAP_NHAT",
      width: 150,
      render: (_, record) =>
        (record.NGAY_CAP_NHAT || record.GIO_CAP_NHAT)
          ? `${record.GIO_CAP_NHAT ? `${record.GIO_CAP_NHAT} -` : ""} ${moment(record.NGAY_CAP_NHAT).format('DD/MM/YYYY')}`
          : ''
    },
    {
      key: "action",
      fixed: "right",
      width: 35,
      render: (_, record) => (
        <ActionButton
          record={record}
          showEdit={false}
          onDelete={() => handleDelete(record)}
          deleteTip={i18n.t(languageKeys.vienphi_xoa_dich_vu)}
          deleteDisabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
          tooltipXoaTheoQuyen
        />
      ),
    },
  ];


  const handleClose = () => {
    setVisible(false);
    setSupplyList([{}]);
    dispatch(clearCommissionLocal());
  };

  // const onChangeLoaiDvCls = (event) => {
  //   if (!!event) {
  //     setSelectedLoaiDvCls(JSON.parse(event));
  //   } else {
  //     setSelectedLoaiDvCls(null);
  //   }
  // };

  // const onTreeKBSelect = (data) => {
  //   if (data.disabled) {
  //     return;
  //   }
  //   if (data.selectable) {
  //     if (!data.checked) {
  //       const GIA_DT = data.value.LIST_GIA_THEO_DICH_VU?.[0] || {}
  //       dispatch(addDatasourceTableService({
  //         data: {
  //           ...data,
  //           value: {
  //             ...data.value,
  //             [DataIndexOfServiceTable.DON_GIA]: GIA_DT.GIA_DICH_VU,
  //             [DataIndexOfServiceTable.THANH_TIEN]: GIA_DT.GIA_DICH_VU,
  //             LOAI_GIA: GIA_DT.DM_DOI_TUONG_ID,
  //             [DataIndexOfServiceTable.KHOA_PHONG_ID]: data.value.LIST_PHONG_ID?.[0],
  //           },
  //           DV_ID: data.key,
  //           NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
  //           NGAY_CAP_NHAT: moment().format('YYYYMMDD'),
  //           GIO_CAP_NHAT: moment().format('H:mm'),
  //           actionType
  //         }
  //       }))
  //       // setDsKhamBenhDaChon((currentArr) => [
  //       //   ...currentArr,
  //       //   {
  //       //     ...data.value,
  //       //   },
  //       // ]);
  //     } else {
  //       dispatch(removeDatasourceTableService(data.key))
  //       // setDsKhamBenhDaChon((currentArray) => currentArray.filter((item) => item.DV_ID !== data.value.DV_ID));
  //     }
  //   }
  // };


  // const onTreeClsSelect = (data, setExpandedKeys = () => {}) => {
  //   // HLog("select dv cls", data);
  //   // if (lichKhamInfo ? !quyen?.SUA_DICH_VU : !quyen?.THEM_MOI) return;
  //   if (data.disabled) {
  //     return;
  //   }
  //   if (data.selectable) {
  //     if (data.checked) {
  //       dispatch(removeDatasourceTableService(data.key))
  //       // setDsDvClsDaChon((currentArray) => currentArray.filter((item) => item.DV_ID !== data.value.DV_ID));
  //     } else {
  //       const GIA_DT = data.value.LIST_GIA_THEO_DICH_VU?.[0] || {}
  //       dispatch(
  //         addDatasourceTableService({
  //           data: {
  //             ...data,
  //             value: {
  //               ...data.value,
  //               [DataIndexOfServiceTable.DON_GIA]: GIA_DT.GIA_DICH_VU,
  //               [DataIndexOfServiceTable.THANH_TIEN]: GIA_DT.GIA_DICH_VU,
  //               LOAI_GIA: GIA_DT.DM_DOI_TUONG_ID,
  //               [DataIndexOfServiceTable.KHOA_PHONG_ID]: data.value.LIST_PHONG_ID?.[0],
  //             },
  //             DV_ID: data.key,
  //             NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
  //             actionType
  //           }
  //         })
  //       );
  //       const isServicePacket = data.value.PHAN_LOAI === "COMBO_DV" || data.value.PHAN_LOAI === "GOI_DICH_VU";

  //       if (isServicePacket) {
  //         dispatch(getChildrenService(data.key));
  //       }
  //       // dispatch(changeDatasourceTableService({ value: 1, key: data.key, inputChange: 'SL' }))
  //       // setDsDvClsDaChon((currentArr) => [
  //       //   ...currentArr,
  //       //   {
  //       //     ...data.value,
  //       //   },
  //       // ]);
  //     }
  //   } else {
  //     if (data.expanded) {
  //       setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.key));
  //     } else {
  //       setExpandedKeys((currentArr) => [...currentArr, data.key]);
  //     }
  //   }
  // };


  const handleAdd = async () => {
    dispatch(updateIsModalExemption(true));
    const isHasEmptyRoom = dataSourceTableService?.some((item) => {
      if (item?.DV_KEM_THEO?.length > 0) {
        return item?.DV_KEM_THEO.some((i) => typeof i.PHONG_THUC_HIEN_ID === "undefined" && typeof i.KHOA_PHONG_ID === "undefined");
      }
      return typeof item.KHOA_PHONG_ID === "undefined" && typeof item.PHONG_THUC_HIEN_ID === "undefined";
    });
    if (isHasEmptyRoom) return;
    const values = await form.validateFields();
    const exemptionValue = await formExemptionModal.validateFields();

    const formatExemptionValue = exemptionValue?.MIEN_GIAM?.map((item) => ({
      DOI_TUONG_NGUOI_DUNG: 1,
      NHOM_DOI_TUONG_ID: item.DM_NHOM_NGUON_KHACH_ID,
      MIEN_GIAM_DATA: item?.MIEN_GIAM_DATA?.map((i) => ({
        NGUON_KHACH_ID: i.id,
        MIEN_GIAM: i.exemption,
        DON_VI: i.exemption > 100 ? "VNĐ" : "%",
      })),
    }));

    let dataRequest = {
      ...values,
      [PacketServiceInfo.NGAY_TAO]: moment(values[PacketServiceInfo.NGAY_TAO], "DD/MM/YYYY").format("YYYYMMDD"),
      [PacketServiceInfo.NGAY_HET_HAN]: moment(values[PacketServiceInfo.NGAY_HET_HAN]).format("YYYYMMDD"),
      [PacketServiceInfo.NGAY_HIEU_LUC]: moment(values[PacketServiceInfo.NGAY_HIEU_LUC]).format("YYYYMMDD"),
      NHANSU_ID: userProfile?.NHANSU_ID,
      NGUOI_CAP_NHAT_ID: userProfile?.NHANSU_ID,
      NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
      SO_LUONG: calcTotal(dataSourceTableService, "SO_LUONG"),
      TONG_THANH_TIEN: calcTotal(dataSourceTableService, "THANH_TIEN"),
      TONG_TIEN_PHAN_BO: calcTotal(dataSourceTableService, "THANH_TIEN_PHAN_BO"),
      THUE: 0,
      TONG_TIEN: calcTotal(dataSourceTableService, "TONG_TIEN"),
      MIEN_GIAM: formatExemptionValue,
      DANH_SACH_VAT_TU: supplyList.filter((i) => i.SO_LUONG),
      DS_DV: dataSourceTableService.map(i => ({
        ...i,
        MA_DV: i.MA_DICHVU || i.MA_DV,
        TEN_DV: i.TEN_DICHVU || i.TEN_DV,
        NGUOI_CAP_NHAT: i.IS_CAP_NHAT ? `${userProfile?.HO} ${userProfile?.TEN}` : i.NGUOI_CAP_NHAT,
        NGUOI_CAP_NHAT_ID: i.IS_CAP_NHAT ? userProfile?.NHANSU_ID : i.NGUOI_CAP_NHAT_ID,
        GIO_CAP_NHAT: i.IS_CAP_NHAT ? moment().format('H:mm') : i.GIO_CAP_NHAT,
        NGAY_CAP_NHAT: i.IS_CAP_NHAT ? moment().format('YYYYMMDD') : i.NGAY_CAP_NHAT,
      }))
    };

    if (actionType === ActionType.create || actionType === ActionType.copy) {
      dispatch(
        addPacketService({
          dataRequest,
          callback: () => {
            setSupplyList([{}]);
            setVisible(false);
            dispatch(clearCommissionLocal());
          },
        })
      );
    } else if (actionType === ActionType.edit) {
      dataRequest = {
        ...dataRequest,
        [PacketServiceInfo.NGAY_CAP_NHAT]: moment(values[PacketServiceInfo.NGAY_CAP_NHAT], "DD/MM/YYYY").format("YYYYMMDD"),
        ID: packetServiceId,
      };
      dispatch(
        editPacketService({
          dataRequest,
          callback: () => {
            setSupplyList([{}]);
            setVisible(false);
            dispatch(clearCommissionLocal());
          },
        })
      );
    }
  };


  // const formatDataSourceTable = (datasource = []) => {
  //   return datasource.map((item) => {
  //     const { value } = item;

  //     if (value?.children) {
  //       return {
  //         ...value,
  //         key: value[DataIndexOfServiceTable.MA_DV],
  //         [DataIndexOfServiceTable.MA_DV]: value[DataIndexOfServiceTable.MA_DV],
  //         [DataIndexOfServiceTable.TEN_DV]: value[DataIndexOfServiceTable.TEN_DV],
  //         [DataIndexOfServiceTable.DON_GIA]: value[DataIndexOfServiceTable.DON_GIA],
  //         children: value?.children?.map((i) => ({
  //           ...i,
  //           [DataIndexOfServiceTable.MA_DV]: i.MA_DV,
  //           [DataIndexOfServiceTable.TEN_DV]: i.TEN_DICHVU,
  //           [DataIndexOfServiceTable.DON_GIA]: i.DON_GIA,
  //         })),
  //       };
  //     }

  //     if (value?.LIST_PHONG_ID) {
  //       return {
  //         ...value,
  //         key: value[DataIndexOfServiceTable.MA_DV],
  //         [DataIndexOfServiceTable.MA_DV]: value[DataIndexOfServiceTable.MA_DV],
  //         [DataIndexOfServiceTable.TEN_DV]: value[DataIndexOfServiceTable.TEN_DV],
  //         [DataIndexOfServiceTable.DON_GIA]: formatCurrency2(value[DataIndexOfServiceTable.DON_GIA], "", true),
  //       };
  //     } else {
  //       return {
  //         ...value,
  //         key: value[DataIndexOfServiceTable.MA_DV],
  //         [DataIndexOfServiceTable.MA_DV]: value[DataIndexOfServiceTable.MA_DV],
  //         [DataIndexOfServiceTable.TEN_DV]: value[DataIndexOfServiceTable.TEN_DV],
  //         [DataIndexOfServiceTable.DON_GIA]: formatCurrency2(value[DataIndexOfServiceTable.DON_GIA], "", true),
  //         LIST_PHONG_ID:
  //           value?.PHAN_LOAI === "KHAM_BENH"
  //             ? data.kham_benh.find((i) => i.DV_ID === value.DV_ID)?.LIST_PHONG_ID
  //             : data.cls.flatMap((i) => i.DICH_VU).find((i) => i.DV_ID === value.DV_ID)?.LIST_PHONG_ID,
  //       };
  //     }
  //   });
  // };

  // const dataSource = formatDataSourceTable(dataSourceTableService);


  // const handleChangeTarget = (value, record, disable, changeAll) => {
  //   //
  //   const GIA_DT = (obj) => obj.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === value)?.GIA_DICH_VU
  //   //
  //   if (changeAll) {
  //     dispatch(setDataSourceTableService(
  //       dataSourceTableService.map(dv => ({
  //         ...dv,
  //         value: {
  //           ...dv.value,
  //           [DataIndexOfServiceTable.DON_GIA]: GIA_DT(dv.value) ?? dv.value[DataIndexOfServiceTable.DON_GIA],
  //           [DataIndexOfServiceTable.THANH_TIEN]: GIA_DT(dv.value)
  //             ? GIA_DT(dv.value) * dv.value.SL
  //             : dv.value[DataIndexOfServiceTable.THANH_TIEN],
  //           LOAI_GIA: value
  //         }
  //       }))
  //     ))
  //     syncTargetRef.current?.setVisible(false)
  //   } else {
  //     if (disable) {
  //       dispatch(setDataSourceTableService(
  //         dataSourceTableService.map(item =>
  //           item.DV_ID === record.DV_ID
  //             ? {
  //               ...item,
  //               value: {
  //                 ...item.value,
  //                 [DataIndexOfServiceTable.DON_GIA]: GIA_DT(item.value) ?? item.value[DataIndexOfServiceTable.DON_GIA],
  //                 [DataIndexOfServiceTable.THANH_TIEN]: GIA_DT(item.value)
  //                   ? GIA_DT(item.value) * item.value.SL
  //                   : item.value[DataIndexOfServiceTable.THANH_TIEN],
  //                 LOAI_GIA: value
  //               }
  //             }
  //             : item
  //         )
  //       ))
  //     } else {
  //       syncTargetRef.current?.open({ value, record })
  //     }
  //   }
  // };


  // const handleSoLuong = (value, record) => {
  //   dispatch(
  //     changeDatasourceTableService({
  //       value,
  //       key: record.DV_ID,
  //       inputChange: "SL",
  //       NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
  //       NHANSU_ID: userProfile?.NHANSU_ID,
  //       actionType,
  //     })
  //   );
  // };


  // const handleDonGiaPhanBo = (value, record) => {
  //   dispatch(changeDatasourceTableService({
  //     value,
  //     key: record.isChildren ? record.DV_CHA_ID : record.DV_ID,
  //     childrenKey: record.DV_ID,
  //     inputChange: 'DON_GIA_PHAN_BO',
  //     NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
  //     NHANSU_ID: userProfile?.NHANSU_ID, actionType
  //   }))
  // }


  // const handleSelectRoom = (roomRecord, serviceRecord, isClicked) => {
  //   dispatch(changeDatasourceTableService({
  //     value: {
  //       roomName: roomRecord?.TEN_KHOA_PHONG,
  //       roomID: roomRecord?.ID,
  //       isClicked
  //     },
  //     childrenKey: serviceRecord?.DV_ID,
  //     key: serviceRecord?.isChildren ? serviceRecord.DV_CHA_ID : serviceRecord.DV_ID,
  //     inputChange: 'NOI_THUC_HIEN',
  //     NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
  //     NHANSU_ID: userProfile?.NHANSU_ID,
  //     OLD_NHANSU_ID: serviceRecord?.NGUOI_CAP_NHAT_ID,
  //     OLD_NGUOI_CAP_NHAT: serviceRecord?.NGUOI_CAP_NHAT,
  //     actionType,
  //     OLD_GIO_CAP_NHAT: serviceRecord?.GIO_CAP_NHAT,
  //     OLD_NGAY_CAP_NHAT: serviceRecord?.NGAY_CAP_NHAT
  //   }))
  // }


  const formatOptionsPacketService = (servicePacketList = []) => {
    return servicePacketList.map((i) => ({
      value: i.ID,
      label: i.TEN_GOI_DV,
    }));
  };


  const handleCopy = () => {
    if (idCopyServicePacket) {
      dispatch(
        getDetailPacketService({
          id: idCopyServicePacket,
          isCopy: true,
          actionType,
          currentDetail: packetServiceDetail,
          NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
        })
      );
    }
  };


  // const handleDistributePrice = (totalPackagePrice) => {
  //   try {
  //     const valueDataSource = dataSourceTableService.map(x => x.value);
  //     const newValueData = [];
  //     let reducePrice = +totalPackagePrice;
  //     for (let i = 1; i < valueDataSource.length; i++) {
  //       const value = { ...valueDataSource[i] };
  //       let price = +totalPackagePrice / valueDataSource.length;
  //       price = Math.round(+price);
  //       value.DON_GIA_PHAN_BO = price;
  //       value.THANH_TIEN_PHAN_BO = price * +value.SL;
  //       value.TY_LE = (((price / +totalPackagePrice) || 0) * 100).toFixed(1);
  //       reducePrice = reducePrice - price;
  //       newValueData.push(value);
  //     }

  //     const firstElement = {
  //       ...valueDataSource[0],
  //       DON_GIA_PHAN_BO: reducePrice,
  //       THANH_TIEN_PHAN_BO: reducePrice * +valueDataSource[0].SL,
  //       TY_LE: (((reducePrice / +totalPackagePrice) || 0) * 100).toFixed(1)
  //     }
  //     newValueData.unshift(firstElement);

  //     const newDataSource = dataSourceTableService.map((x, ind) => {
  //       return { ...x, value: newValueData[ind] }
  //     })
  //     dispatch(setDataSourceTableService([...newDataSource]));
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }


  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "s") {
      event.stopPropagation();
      event.preventDefault();
      handleAdd();
    }
  };
  useEventListener("keydown", keydownEvent, window.document, exemptionRef?.current?.isModalOpen);


  return (
    <Spin spinning={isModalLoading}>
      <div className={styles["packet_service_info"]}>
        <Collapse
          bordered={false}
          defaultActiveKey={["THONG_TIN", "CHI_DINH", "VAT_TU", "MIEN_GIAM"]}
          panels={[
            {
              key: "THONG_TIN",
              header: i18n.t(languageKeys.thong_tin_goi_dv),
              children: (
                <Form form={form} layout="vertical" className={styles["form"]} disabled={!persmission.SUA}>
                  <Row gutter={8}>
                    <Col style={{ width: "20%" }}>
                      <Form.Item name={PacketServiceInfo.MA_GOI} label={i18n.t(languageKeys.ma_goi)}>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.TEN_GOI}
                        label={i18n.t(languageKeys.ten_goi_lieu_trinh)}
                        rules={[{
                          required: true,
                          message: "",
                        }]}
                      >
                        <Input
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.LOAI_GOI}
                        label={i18n.t(languageKeys.loai_goi)}
                        rules={[{
                          required: true,
                          message: "",
                        }]}
                      >
                        <Select
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                          dataSource={servicePackTypeList.filter((i) => !i.KHOA)}
                          titleKey="label"
                          valueKey="value"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.NHOM_GOI}
                        label={i18n.t(languageKeys.nhom_goi)}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                          dataSource={
                            (userProfile.partner_code !== "0029sct" ? BundleGroup : BundleGroupSCT)
                              .map((item) => ({
                                label: (
                                  <Tooltip title={item.tooltip} placement="right">
                                    {item.label}
                                  </Tooltip>
                                ),
                                value: item.value,
                              }))
                          }
                          titleKey="label"
                          valueKey="value"
                        />
                      </Form.Item>
                    </Col>

                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.SO_LAN_TELEMEDICINE}
                        label={i18n.t(languageKeys.so_lan_su_dung_dich_vu)}
                      >
                        <InputNumber
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                          style={{ width: "100%" }}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.NGAY_HIEU_LUC}
                        label={
                          <Tooltip title={i18n.t(languageKeys.ngay_hieu_luc_ban_goi)}>
                            <label>{i18n.t(languageKeys.ngay_hieu_luc)}</label>
                          </Tooltip>
                        }
                        rules={[{ required: true, message: "" }]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          className={styles["datepicker"]}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                          format={"DD/MM/YYYY"}
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return current && current < moment(customDate, "YYYY-MM-DD");
                          }}
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.NGAY_HET_HAN}
                        label={
                          <Tooltip title={i18n.t(languageKeys.ngay_het_han_ban_goi)}>
                            <label>{i18n.t(languageKeys.ngay_het_han)}</label>
                          </Tooltip>
                        }
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue(PacketServiceInfo.NGAY_HIEU_LUC)?.isBefore(value, "day")) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(""));
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          className={styles["datepicker"]}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                          format={"DD/MM/YYYY"}
                          disabledDate={(current) => {
                            let curentDate = moment().format("YYYY-MM-DD");
                            if (actionType === ActionType.create || actionType === ActionType.copy)
                              return current && current < moment(curentDate, "YYYY-MM-DD");
                            else return current && current < moment(oldExpiredDate, "YYYY-MM-DD");
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.SO_NGAY_SU_DUNG}
                        label={
                          <Tooltip title={i18n.t(languageKeys.so_ngay_sd_goi_tinh_tu_ngay_kich_hoat)}>
                            <label>{i18n.t(languageKeys.thoi_han_sd_goi)}</label>
                          </Tooltip>
                        }
                        // rules={[{ required: true, message: "" }]}
                      >
                        <InputNumber
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                          min={1}
                          type="number"
                          style={{ width: "100%" }}
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                          addonAfter={i18n.t(languageKeys.common_ngay)}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item name={PacketServiceInfo.NGAY_TAO} label={i18n.t(languageKeys.ngay_tao)}>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "20%" }}>
                      <Form.Item name={PacketServiceInfo.NGUOI_TAO} label={i18n.t(languageKeys.nguoi_tao)}>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    {actionType === ActionType.edit && (
                      <Col style={{ width: "20%" }}>
                        <Form.Item name={PacketServiceInfo.NGAY_CAP_NHAT} label={i18n.t(languageKeys.ngay_cap_nhat)}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    )}
                    {actionType === ActionType.edit && (
                      <Col style={{ width: "20%" }}>
                        <Form.Item name={PacketServiceInfo.NGUOI_CAP_NHAT} label={i18n.t(languageKeys.nguoi_cap_nhat)}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    )}
                    <Col style={{ width: "20%" }}>
                      <Form.Item
                        name={PacketServiceInfo.GIA_GOI_DV}
                        label={i18n.t(languageKeys.gia_goi_dich_vu)}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          readOnly
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          parser={(value) => parseFloat(value.replace(/\./g, ""))}
                        // onBlur={(e) => handleDistributePrice(parseFloat(e.target.value.replace(/\./g, "")))}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: actionType === ActionType.create ? "40%" : "20%" }}>
                      <Form.Item name={PacketServiceInfo.MO_TA} label={i18n.t(languageKeys.mo_ta)}>
                        <Input
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: actionType === ActionType.create ? "40%" : "20%" }}>
                      <Form.Item name={PacketServiceInfo.GHI_CHU} label={i18n.t(languageKeys.field_Ghi_chu)}>
                        <Input
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              ),
            },
            {
              key: "CHI_DINH",
              header: i18n.t(languageKeys.chi_dinh_goi_dv),
              children: (
                <Layout>
                  <Layout.Sider className={styles["sider-form"]}>
                    <div className={styles["wrapper"]}>
                      <Segmented block options={tabs} className={styles["segmented"]} onChange={setCurrentTab} value={currentTab} />
                    </div>
                    <Input
                      prefix={<SearchOutlined className="blue-txt" />}
                      placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                      style={{ marginBottom: 10 }}
                      value={searchVal}
                      onChange={({ target }) => setSearchVal(target.value)}
                      allowClear
                    />

                    <div className={styles["options"]}>
                      <div style={currentTab !== TAB_KHAM_BENH ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
                        <ListDvKhamBenh
                          dsDvDaChon={dataSourceTableService.filter(i => i.PHAN_LOAI === "KHAM_BENH")}
                          dsDvKhamBenh={initData.kham_benh}
                          searchVal={searchVal}
                          onTreeKBSelect={onTreeSelect}
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                        />
                      </div>

                      <div style={currentTab !== TAB_DICH_VU ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
                        <ListDvCls
                          dsDvCls={
                            /*NHOM_GOI === "DICH_VU" ? initData.cls :*/ initData.cls.filter((i) => !i.DICH_VU?.some((x) => x.MA_NHOM_BHYT === "COMBO_DV"))
                          }
                          dsDvDaChon={dataSourceTableService.filter(i => i.PHAN_LOAI !== "KHAM_BENH")}
                          filterItem={selectedLoaiDvCls}
                          search_string={searchVal}
                          onTreeClsSelect={onTreeSelect}
                          disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                          showFavourite={false}
                        />
                      </div>
                    </div>
                  </Layout.Sider>

                  <Layout.Content style={{ height: "100%" }}>
                    <Table
                      className={styles["tableS"]}
                      scroll={{ y: "calc(100vh - 520px)" }}
                      columns={ColumnTablePacketService}
                      expandable={{
                        defaultExpandAllRows: true,
                        expandIcon: ({ expanded, onExpand, record }) =>
                          record.DV_KEM_THEO?.length
                            ? (
                              expanded
                                ? <ArrowDownCollapse onClick={(e) => onExpand(record, e)} />
                                : <ArrowRightCollapse onClick={(e) => onExpand(record, e)} />
                            )
                            : <></>,
                        childrenColumnName: "DV_KEM_THEO"
                      }}
                      dataSource={dataSourceTableService.map((item, index) => ({ ...item, index }))}
                      rowKey={'index'}
                    />

                    <div className={styles["footer-table"]}>
                      <div>
                        <p>{i18n.t(languageKeys.tong_SL)}:</p>
                        <p className={styles["text-color"]}>{calcTotal(dataSourceTableService, "SO_LUONG")}</p>
                      </div>
                      <div>
                        <p>{i18n.t(languageKeys.tong_thanh_tien)}:</p>
                        <p className={styles["text-color"]}>
                          {formatCurrency2(calcTotal(dataSourceTableService, "THANH_TIEN"), '', true)}
                        </p>
                      </div>
                      <div>
                        <p>{i18n.t(languageKeys.tong_tien_phan_bo)}:</p>
                        <p className={styles["text-color"]}>
                          {formatCurrency2(calcTotal(dataSourceTableService, "THANH_TIEN_PHAN_BO"), '', true)}
                        </p>
                      </div>
                      <div>
                        <p>{i18n.t(languageKeys.field_Mien_giam)}:</p>
                        <p className={styles["text-color"]}>
                          {formatCurrency2(calcTotal(dataSourceTableService, "MIEN_GIAM"), '', true)}
                        </p>
                      </div>
                      <div>
                        <p>{i18n.t(languageKeys.tong_tien)}:</p>
                        <InputAutoFillPrice
                          dataSource={dataSourceTableService}
                          setDataSource={(data) => dispatch(setDataSourceTableService(data))}
                          readOnly={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                        />
                      </div>
                    </div>
                  </Layout.Content >
                </Layout >
              ),
              extra: (
                <div className={styles["extra"]}>
                  <span>{i18n.t(languageKeys.goi_co_san)}</span>
                  <Select
                    className={styles["select"]}
                    dataSource={formatOptionsPacketService(optionAvailablePacket)}
                    valueKey="value"
                    titleKey="label"
                    onSearch={() => {}}
                    showSearch
                    value={idCopyServicePacket}
                    onSelect={(value) => setIdCopyServicePacket(value)}
                  />
                  <Button
                    className={styles["button_copy"]}
                    type="primary"
                    icon={<CopyIcon />}
                    onClick={handleCopy}
                    disabled={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                  >
                    {i18n.t(languageKeys.sao_chep)}
                  </Button>
                </div>
              ),
            },
            {
              key: "VAT_TU",
              header: i18n.t(languageKeys.chi_dinh_thuoc_vat_tu_trong_goi),
              children: (
                <ChiDinhVatTu
                  supplyList={supplyList}
                  setSupplyList={setSupplyList}
                  readOnly={!persmission.SUA || (actionType === ActionType.edit && !!packetServiceDetail?.BENH_NHAN_ID)}
                  // isCommon
                />
              ),
            },
            {
              key: "MIEN_GIAM",
              header: i18n.t(languageKeys.quan_ly_mien_giam),
              children: (
                <Form layout="vertical" form={formExemptionModal}>
                  <Form.List name={MIEN_GIAM_GOI}>
                    {(fields, { add, remove }) => (
                      <>
                        <Row gutter={[16, 16]}>
                          {fields.map((field) => (
                            <Exemption
                              formExemptionModal={formExemptionModal}
                              field={field}
                              remove={remove}
                              detail={packetServiceDetail}
                              visible={visible}
                              nhomDoiTuongNguoiDung={nhomDoiTuongNguoiDung}
                              actionType={actionType}
                              total={calcTotal(dataSourceTableService, "THANH_TIEN_PHAN_BO")}
                              ref={exemptionRef}
                            />
                          ))}
                          <Col span={24}>
                            <div className={styles["btn-add-user-group"]} onClick={add}>
                              <SmallPlus />
                              <span>{i18n.t(languageKeys.them_nhom_doi_tuong_nguoi_dung)}</span>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Form>
              ),
            },
          ]}
        />
        < div className={styles["packet_service_footer"]} >
          {/* <Checkbox>{i18n.t(languageKeys.tiepdon_nhap_lien_tuc)}</Checkbox> */}
          < div />
          <div className={styles["button_footer"]}>
            <Button type="primary" ghost onClick={handleClose}>
              {i18n.t(languageKeys.common_Thoat)} {" (ESC)"}
            </Button>
            <Tooltip
              title={
                (actionType === ActionType.edit ? persmission.SUA : persmission.THEM_MOI)
                  ? ""
                  : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)
              }
            >
              <Button
                disabled={actionType === ActionType.edit ? !persmission.SUA : !persmission.THEM_MOI}
                type="primary"
                onClick={handleAdd}
                loading={isLoadingButton}
              >
                {i18n.t(languageKeys.common_Luu)}
                {" (Ctrl + S)"}
              </Button>
            </Tooltip>
          </div>
        </div >
      </div >
    </Spin >
  );
};

export default FormGoiDichVu;
