import React, { useState, useImperativeHandle, useRef } from 'react'
import { Row, Col, Button, Modal, Upload } from "antd";
import style from './style.module.less';

import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor'
import i18n, { languageKeys } from '../../../../i18n';
import { HLog, base64ImageToBlob, compressionImage, resizeFile } from 'helpers';
import { useSelector } from 'react-redux';

const ModalEditImage = React.forwardRef(({
    onSubmitEditImage = () => { }
}, ref) => {
    const imagesEditorRef = useRef();
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState();
    const userProfile = useSelector((state) => state.auth.user);

    useImperativeHandle(ref, () => ({
        open: (image) => {
            setCurrentImage(image);
            setOpen(true);
        },
        close: handleClose,

    }));

    const handleClose = () => {
        setCurrentImage()
        setOpen(false);
        HLog(imagesEditorRef.current)
    
    }
    const handleSaveImage = async () => {
        const canvas = document.querySelector(".lower-canvas");
        const imageData = canvas.toDataURL("image/jpeg");
        HLog(`before imageData`, imageData);
        const blob = base64ImageToBlob(imageData);


        let checkThanhY = userProfile?.partner_code == "skty"
        
        const dataResize = await resizeFile(blob, "base64", checkThanhY);
        HLog(`after imageData`, dataResize);

        const newObjectImage = { ...currentImage, thumbnail: dataResize };
        setCurrentImage(newObjectImage);
        onSubmitEditImage(newObjectImage);
    }


    const renderFooter = () => {
        return (
            <Row justify={"center"} align="middle">
                <Button onClick={handleClose} danger ghost type='primary'>
                    {i18n.t(languageKeys.huy)}
                </Button>
                <Button onClick={handleSaveImage} type='primary'>
                    {i18n.t(languageKeys.luu)}
                </Button>

            </Row>
        )
    }

    return (
        <Modal
            open={open}
            centered
            title={i18n.t(languageKeys.chinh_sua_anh)}
            footer={renderFooter()}
            onCancel={handleClose}
            wrapClassName={style['edit-container']}
            width={"900px"}
        >
            <div style={{display : "flex", justifyContent:"center", alignItems : "center"}}>
              {currentImage ?  <ImageEditor
                    ref={imagesEditorRef}
                    key={currentImage.key}
                    includeUI={{
                        loadImage: {
                            path: currentImage.thumbnail,
                            name: currentImage.key,
                            key :  currentImage.key
                        },
                        uiSize: {
                            width: '850px',
                            height: '550px',
                        },
                        menuBarPosition: 'bottom',
                    }}
                    cssMaxHeight={550}
                    cssMaxWidth={550}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70,
                    }}
                    usageStatistics={true}
                /> : <div/>}
            </div>

        </Modal>
    )
})

export default ModalEditImage