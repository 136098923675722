import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Attachment } from "assets/svg";
import { Editor as CustomEditor } from "ckeditor5-custom-build/build/ckeditor";
import classNames from "classnames";
import { forwardRef, memo } from "react";
import "react-quill/dist/quill.snow.css";
import i18n, { languageKeys } from "../../../../i18n";
import style from "./textEditor.module.less";
import { base64ToBlob2, resizeFile } from "helpers";
import { useSelector } from "react-redux";

const editorConfiguration = {
  removePlugins: ["Title"],
  fontSize: {
    supportAllValues: false,
    options: [6, 7, 7.5, 8, 9, 10, 10.5, 11, 12, 13, 13.5, 14, 14.5, 15, 16, 17, 18, 20, 22, 24, 26, 27, 28, 29, 30, 32, 34, 36],
  },
  // header: false,
  image: {
    toolbar: ["imageTextAlternative", "toggleImageCaption", "imageStyle:inline", "imageStyle:block", "imageStyle:side", "linkImage", "resizeImage"],
  },
  menuBar: {
    isVisible: true,
  },
  sourceEditing: {
    allowCollaborationFeatures: true,
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
  lineHeight: {
    options: [ 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5 ]
  },
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      'lineHeight',
      "fontSize",
      "fontColor",
      "fontFamily",
      "|",
      "alignment",
      "bulletedList",
      "numberedList",
      "outdent",
      "indent",
      "|",
      "link",
      "imageInsert",
      "insertTable",
    ],
  },
};

let TextEditor = forwardRef(
  (
    {
      value = "",
      onChange,
      className,
      readonly = false,
      menubar = true,
      disabled = false,
      placeholder = i18n.t(languageKeys.placeholder_nhap_mo_ta),
      onClickButtonFile,
    },
    ref
  ) => {
    const handleOnChange = (e, editor) => {
      if(onChange) onChange(editor.getData());
    };

    const userProfile = useSelector((state) => state.auth.user);

    const fileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result); // Kết quả ở dạng Base64
        };
        reader.onerror = (error) => {
          reject(error); // Bắt lỗi nếu có
        };
        reader.readAsDataURL(file); // Đọc file
      });
    };

    return (
      <div className={classNames(style["container"], className ,userProfile?.partner_code == "skty" ? style["skty"] : "" )} style={{ position: "relative" }}>
        {onClickButtonFile && (
          <p className={classNames(style["file-dinh-kem"])} onClick={() => onClickButtonFile()}>
            <Attachment />
            {i18n.t(languageKeys.file_dinh_kem)}
          </p>
        )}
        <CKEditor
          editor={CustomEditor}
          onReady={(editor) => {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
              return {
                upload: async () => {
                  try {
                    const file = await loader.file;
                    let checkThanhY = userProfile?.partner_code == "skty"
                    const resizedBase64 = await resizeFile(file, "base64", checkThanhY); // Giảm dung lượng ảnh
                    return { default: resizedBase64 }; // Trả ảnh đã giảm kích thước
                  } catch (error) {
                    console.error("File conversion error:", error);
                    throw error;
                  }
                },
              };
            };
          }}
          data={value || ""}
          onChange={handleOnChange}
          disabled={disabled || readonly}
          config={{
            ...editorConfiguration,
            menuBar: {
              isVisible: menubar,
            },
            placeholder,
            language: "en",
          }}
        />
      </div>
    );
  }
);

TextEditor = memo(TextEditor);
export { TextEditor };
