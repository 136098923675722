import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import style from "./phieuThuDaTao.module.less";
import { Button, Form, Input, Space, Tooltip, notification } from "antd";
import { ConfirmModal, FormDrawer, Table } from "../../../../components";
import { common_post, convertDateToValue, formatCurrency } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { layDanhSachHoaDon, layDanhSachHoaDon2 } from "./apisPhieuThuDaTao";
import { useSelector } from "react-redux";
import { apis, keys } from "../../../../constants";
import ModalPDF from "../../../../components/Modal/ModalPDF";
import { PrinterOutlined } from "@ant-design/icons";
import { Trash } from "../../../../assets/svg";
import moment from "moment";

const PhieuThuDaTao = forwardRef(({
  isPrintAllowed = true,
  isCancelAllowed = true,
  onAfterCancel,
  setTabView,
}, ref) => {

  const pdfRef = useRef();
  const confirmRef = useRef()

  const [form] = Form.useForm();

  const userProfile = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalResult, setTotalResult] = useState(0);
  const [visible, setVisible] = useState(false);
  const [data, setDataUser] = useState({});


  const initData = async (page = currentPage + 1, dataUser = data) => {
    setLoading(true);
    let body = {
      partner_code: userProfile.partner_code,
      BENH_NHAN_ID: dataUser.BENH_NHAN_ID,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      LICH_KHAM_ID: dataUser.LICH_KHAM_ID,
      GOI_DV_ID: dataUser.ID,
      // NGAY: dataUser.NGAY,
      page,
      limit: keys.limit,
      PHAN_LOAI: "THANH_TOAN"
    }
    let data = dataUser.LICH_KHAM_ID ? await layDanhSachHoaDon(body) : await layDanhSachHoaDon2(body);
    if (data) {
      setCurrentPage(page);
      setTotalResult(data.total);
      let result = data.result.map((item, index) => {
        return { ...item, key: index };
      });
      setListUser(result);
      setTotalResult(data.total);
    }
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    open(data) {
      setVisible(true);

      // nếu có data => xem thông tin thôi, nếu không thì là thêm mới
      if (!!data) {
        setDataUser(data);
        initData(1, data);
      }
    },
    close() {
      handleClose();
    },
  }));

  //Lấy màu text tổng thanh toán theo loại
  const getTextColor = (type) => {
    switch (type) {
      case "HOAN_TIEN":
        return "red-txt";
      case "THANH_TOAN":
        return "green-txt";
      default:
        return "green-txt";
    }
  };

  const handlePrint = async ({ HOA_DON_ID, LICH_KHAM_ID, BENH_NHAN_ID }) => {
    const dataRequest = {
      HOA_DON_ID,
      partner_code: userProfile.partner_code,
      arr_HOA_DON_ID: [HOA_DON_ID],
      LICH_KHAM_ID,
      BENH_NHAN_ID
    };
    // const api = apis.in_hoa_don_thanh_toan;
    const api = LICH_KHAM_ID ? apis.in_cac_phieu_dich_vu_da_thanh_toan : apis.in_phieu_dtt_mua_goi_dv;
    pdfRef.current.openModal(dataRequest, api);
  };

  const columns = [
    {
      title: i18n.t(languageKeys.field_Stt),
      key: "key",
      dataIndex: "key",
      render: (key) => key + 1,
    },
    {
      title: i18n.t(languageKeys.field_So_phieu),
      key: "SO_PHIEU",
      dataIndex: "SO_PHIEU",
      render: (_, record) => record.SO_PHIEU,
    },
    {
      title: i18n.t(languageKeys.loai_phieu),
      key: "NHOM",
      dataIndex: "NHOM",
      render: (NHOM) => {
        if (NHOM === "THANH_TOAN") return <div className="green-txt">{i18n.t(languageKeys.phieu_thu)}</div>
        if (NHOM === "HOAN_TIEN") return <div className="red-txt">{i18n.t(languageKeys.vien_phi_Hoan_tien)}</div>
        if (NHOM === "HUY") return <div className="red-txt">{i18n.t(languageKeys.phieu_huy)}</div>
      }
    },
    {
      title: i18n.t(languageKeys.field_Ten_benh_nhan),
      key: "TEN",
      dataIndex: "TEN",
      width: 200,
      render: (TEN) => TEN,
    },
    {
      title: i18n.t(languageKeys.field_Ngay_tao),
      key: "NGAY",
      dataIndex: "NGAY",
      width: 200,
      render: (NGAY, record) =>
        record.GIO ? (
          <>
            {record.GIO} - {convertDateToValue(NGAY)}
          </>
        ) : (
          <>{convertDateToValue(NGAY)}</>
        ),
    },
    // {
    //   title: i18n.t(languageKeys.field_Nguoi_tao),
    //   key: "NGUOI_TAO",
    //   dataIndex: "NGUOI_TAO",
    //   render: (NGUOI_TAO) => NGUOI_TAO,
    // },
    {
      title: i18n.t(languageKeys.field_Tong_tien),
      key: "TONG_CHI_PHI",
      dataIndex: "TONG_CHI_PHI",
      render: (TONG_CHI_PHI, record) => record.NHOM !== "HUY" && formatCurrency(TONG_CHI_PHI, "đ", true),
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      key: "MIEN_GIAM",
      dataIndex: "MIEN_GIAM",
      render: (MIEN_GIAM, record) => record.NHOM !== "HUY" && formatCurrency(MIEN_GIAM, "đ", true),
    },
    {
      title: i18n.t(languageKeys.thanh_tien),
      key: "TONG_THANH_TOAN",
      dataIndex: "TONG_THANH_TOAN",
      render: (TONG_THANH_TOAN, record) => (record.NHOM !== "HUY" &&
        <span className={getTextColor(record.NHOM)}>
          {formatCurrency(TONG_THANH_TOAN, "đ", true)}
        </span>
      ),
    },
    {
      title: i18n.t(languageKeys.da_thanh_toan),
      key: "BENH_NHAN_DUA",
      dataIndex: "BENH_NHAN_DUA",
      render: (BENH_NHAN_DUA, record) => (record.NHOM !== "HUY" &&
        <span className={getTextColor(record.NHOM)}>
          {formatCurrency(BENH_NHAN_DUA, "đ", true)}
        </span>
      ),
    },
    // {
    //   title: i18n.t(languageKeys.field_Da_TT),
    //   key: "NHOM",
    //   dataIndex: "NHOM",
    //   render: (NHOM) => {
    //     if (NHOM === "THANH_TOAN") return <Tooltip title={i18n.t(languageKeys.status_Da_thanh_toan)}><Checked /></Tooltip>
    //     if (NHOM === "HOAN_TIEN") return <Tooltip title={i18n.t(languageKeys.vienphi_da_hoan_tien)}><CheckboxExclamation /></Tooltip>
    //     if (NHOM === "HUY") return <Tooltip title={i18n.t(languageKeys.status_Da_huy)}><CheckboxExclamation /></Tooltip>
    //   }
    // },
    {
      title: i18n.t(languageKeys.ten_hinh_thuc_thanh_toan),
      key: "HINH_THUC_THANH_TOAN",
      dataIndex: "HINH_THUC_THANH_TOAN",
      render: (HTTT, record) => (HTTT && record.NHOM !== "HUY")
        ? (
          Array.isArray(HTTT)
            ? (
              HTTT.length === 1
                ? (
                  typeof HTTT[0] === 'string'
                    ? HTTT[0]
                    : Object.keys(HTTT[0])[0]
                )
                : <Space><span>
                  {
                    typeof HTTT[0] === 'string' ? HTTT[0] : Object.keys(HTTT[0])[0]
                  }
                </span><div className={style["httt"]}>+ {HTTT.length - 1}</div></Space>
            ) : HTTT
        ) : '-'
    },
    {
      title: "",
      key: "action_btn",
      dataIndex: "action_btn",
      width: "60px",
      render: (_, record) => (
        record.NHOM !== "HUY"
          ? (
            <Space>
              <Tooltip
                title={isPrintAllowed ? i18n.t(languageKeys.vienphi_in_phieu_thu) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}
                destroyTooltipOnHide={{ keepParent: visible }}
                placement="topRight"
              >
                <Button
                  type="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrint(record);
                  }}
                  disabled={!isPrintAllowed}
                  style={{ border: "none", background: 'transparent', padding: 4 }}
                >
                  <PrinterOutlined
                    style={{ color: "#999", transform: "scale(1.2)", opacity: isPrintAllowed ? 1 : 0.6 }}
                  />
                </Button>
              </Tooltip>
              {(record.NHOM === "THANH_TOAN" && moment().isSame(moment(record.NGAY), 'day'))
                ? (
                  <Tooltip
                    title={isCancelAllowed ? (i18n.t(languageKeys.huy_phieu) + ` ${record.SO_PHIEU}`) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}
                    placement="topRight"
                  >
                    <Button
                      className={style["btn-cancel"]}
                      icon={<Trash />}
                      type="link"
                      size="small"
                      onClick={() => confirmRef.current?.open(record)}
                      disabled={!isCancelAllowed}
                    />
                  </Tooltip>
                ) : <></>
              }
            </Space>
          ) : <></>
      ),
    },
  ];

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCancelPaid = (info) => {
    form.validateFields()
      .then(async () => {
        confirmRef.current?.loading(true)
        try {
          const res = await common_post(apis.cancel_paid, {
            HOA_DON_ID: info.HOA_DON_ID,
            NGUOI_HUY: userProfile?.NHANSU_ID,
            LY_DO_HUY: form.getFieldValue('LY_DO_HUY')
          })
          if (!!res && res.status === 'OK') {
            confirmRef.current?.close()
            await initData(1, data);
            onAfterCancel("CHO_THANH_TOAN", -1)
            setTabView("CHO_THANH_TOAN")
            notification.success({ message: i18n.t(languageKeys.thao_tac_thanh_cong), placement: 'bottomLeft' })
          } else {
            notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: 'bottomLeft' })
          }
        } catch (err) {
          notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra), placement: 'bottomLeft' })
        } finally {
          confirmRef.current?.loading(false)
        }
      }
      )
  }

  return (
    <FormDrawer
      width={"80vw"}
      visible={visible}
      title={i18n.t(languageKeys.title_Phieu_thu_da_tao)}
      onCancel={handleClose}
      hiddenTopAction={true}
      maskClosable
    >

      <div className={style["table"]}>
        <Table
          dataSource={listUser}
          loading={loading}
          columns={columns}
          showPagination
          totalResult={totalResult}
          currentPage={currentPage}
          limit={keys.limit}
          onPrev={() => initData(currentPage - 1)}
          onNext={() => initData(currentPage + 1)}
          className={style["table-phieu"]}
        />
      </div>

      <ModalPDF ref={pdfRef} />

      <ConfirmModal
        ref={confirmRef}
        content={""}
        danger
        onOk={handleCancelPaid}
        onCancel={() => form.resetFields()}
        onPressCancelSuccess={() => form.resetFields()}
      >
        <div className={style.cancelRes}>
          <p>{i18n.t(languageKeys.confirm_cancel_ticket)}</p>
          <Form form={form} layout="vertical">
            <Form.Item
              label={i18n.t(languageKeys.ly_do_huy_phieu)}
              name="LY_DO_HUY"
              rules={[{ required: true, whitespace: true, message: "" }]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Form>
        </div>
      </ConfirmModal>

    </FormDrawer>
  );
});

export default PhieuThuDaTao;
