import { BarcodeOutlined, PlusCircleFilled, QrcodeOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, notification, Row, Spin, Button, Modal, Popover } from "antd";
import classNames from "classnames";
import moment from "moment";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ArrowDownBold, Robot } from "../../../../assets/svg";
import { danhSachGioiTinh, DrivingLicense } from "../../../../constants/data";
import { keysDanhMuc, loai_quan_he_gia_dinh } from "../../../../constants/keys";
import { HLog, convertGender, handleOnlyNumber, isValidBarcode } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import CaiDatDanhMuc from "../../../QuanLyHeThong/QuanLyDanhMuc/CaiDatDanhMuc";
import { fieldDantoc, fieldHuyen, fieldNgheNghiep, fieldQuocGia, fieldTinh, fieldXa } from "../../../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";
import { fieldTiepDon } from "../../fieldsTiepDon";
import SearchTHX from "../SearchTHX/SearchTHX";
import style from "./ttkb.module.less";
import Icon from "@ant-design/icons/lib/components/Icon";
import InputSearchPatient from "pages/QuanLyTiepDon/ModalTiepDon/components/InputSearchPatient";
import QRInput from "components_v2/QRInput";
import { QRLoadingState } from "ducks/slices/loadingSlice";
import { suggestQuestionSpecialist } from "features";
import useAsking from "features/ChatAi/hooks/useAsking";
import SelectTab from "components/SelectTab/SelectTab";
import Constants from "constants/Constants";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { useEventListener } from "hooks";
import { useCheckingRequiredByPartner } from "./thongTinKhamBenh.hook";
import DateFormItem from "components_v2/DateInput/DateFormItem";

import Barcode from 'react-jsbarcode'

const ds_loai_quan_he = Object.keys(loai_quan_he_gia_dinh).map((item) => {
  return {
    key: item,
    name: loai_quan_he_gia_dinh[item],
  };
});

const ThongTinKhamBenh = forwardRef(
  (
    {
      form,
      setSelectedNguonKhach,
      hidePatientInfo,
      readOnly = false,
      horizontal = false,
      isCreateNew = true,
      visible = false,
      dsNguonKhach = [],
      onHideParent = () => { },
      onGetListNguon = () => { },
      readOnlyInput = false,
      onSelectPatient,
      onClearPatient,
      onHidePatientInfo = () => { },
      triggerOnHidden = () => { },
      showHideDetail = false,
      disableBenhNhan,
      dividerProps = {},
      extraClassName,
      disableThemMoi,
      showAi,
      loadingNguonKhach = false
    },
    ref
  ) => {
    const loaiDichVu = useSelector(state => state.data.loaiDichVu);

    const [dataNhanSu, setDataNhanSu] = useState([]);
    const [dataNVTuVan, setDataNVTuVan] = useState([]);
    const [errorDate, setErrorDate] = useState(false);
    const [disabledEthnic, setDisabledEthnic] = useState(true);
    const [selectedThx, setSelectedThx] = useState();
    const [visiblePatientId, setVisiblePatientId] = useState(false);
    const [districtList, setDistrictList] = useState([]);
    const [wardList, setWardList] = useState([]);

    const patientNameRef = useRef();
    const nguonKhachRef = useRef();
    const nguonKhachSLRef = useRef();
    const dateRef = useRef();
    const dobRef = useRef();
    const thxRef = useRef();
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    const valueBarcodeKh = Form.useWatch("BARCODE_KHACH_HANG", form);
    const valueBarcode = useRef();

    const handleIconClick = (e) => {
      e.stopPropagation(); // Ngăn Popover đóng khi click icon
      valueBarcode.current = valueBarcodeKh || null; // Gán giá trị barcode
      setIsPopoverVisible(true); // Mở Popover
    };

    const { personnel: NGUOI_TIEP_DON } = useSelector(getSharedData);
    const data = useSelector((state) => state.data);
    const { user: userProfile, useAi } = useSelector((state) => state.auth);
    const loadingQR = useSelector(QRLoadingState);
    const { modeQR } = useSelector((state) => state.snapShot)

    const { makeAsk } = useAsking();
    const { ruleNguonKhach, ruleDiaChiChiTiet, listRequiredNgaySinh } = useCheckingRequiredByPartner(userProfile);

    const checkDongBoDonThuoc = useMemo(() => {
      try {
        return !!userProfile.config.DON_THUOC_CONG_DUOC
      } catch (error) {
        return false
      }
    }, [userProfile])

    const handleGlobalKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const focusableElements = document.querySelectorAll('input, select:not(:disabled)');
        const focusableArray = Array.from(focusableElements);
        const sortedElements = focusableArray.sort((a, b) => {
          return (a.tabIndex || 0) - (b.tabIndex || 0);
        });
        const currentElement = e.target;
        const currentIndex = sortedElements.indexOf(currentElement);
        let nextIndex = currentIndex + 1;

        while (nextIndex < sortedElements.length) {
          const nextElement = sortedElements[nextIndex];
          if (nextElement !== null && nextElement !== undefined && !nextElement.disabled) {
            nextElement.focus();
            break;
          }
          nextIndex++;
        }
      }
    };
    useEventListener("keydown", handleGlobalKeyPress, window.document, true)

    // useEffect(() => {
    //   document.addEventListener('keydown', handleGlobalKeyPress);
    //   return () => {
    //     document.removeEventListener('keydown', handleGlobalKeyPress);
    //   };
    // }, []);

    useImperativeHandle(ref, () => ({
      selectedThx,
      errorDate,
      setProvinceId: (id) => {
        if (!id) return console.log("id tinh thanh not found");
        setDistrictList(
          data?.huyen[id]?.map((item) => ({
            MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
            TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
          }))
        );
      },
      setDistrictId: (id) => {
        setWardList(
          data?.xa[id]?.map((item) => ({
            MA_PHUONG_XA: item.MA_PHUONG_XA,
            TEN_PHUONG_XA: item.TEN_PHUONG_XA,
            TEN_VIET_TAT: item.TEN_VIET_TAT,
          }))
        );
      },
      setDisabledEthnic,
      setSelectedThx,
      setVisiblePatientId,
      fillQuocGiaVaDanToc,
      reset() {
        setErrorDate(false);
        setDisabledEthnic(true);
        setDistrictList([]);
        setWardList([]);
        setVisiblePatientId(false);
        dobRef.current?.reset();
        thxRef.current?.reset();
      },
      init() {
        patientNameRef.current?.focus();
      },
      setDate: onSetDate,
    }));

    const onSetDate = (date, customFormat = 'YYYYMMDD') => {
      try {
        if (date.length === 4) {
          dateRef.current.setDate(["", "", date]);
        } else if (date.length === 8) {
          date = moment(date, customFormat).format("DD/MM/YYYY");
          dateRef.current.setDate(date.split("/"));
        }
      } catch (error) {
        HLog(error);
      }
    };

    const fillQuocGiaVaDanToc = useCallback(() => {
      form.setFields([
        {
          name: fieldTiepDon.quoc_gia,
          value: JSON.stringify(data.quoc_gia.filter((item) => item.TEN_VIET_TAT === "VN")[0]),
        },
        {
          name: fieldTiepDon.dan_toc,
          value: JSON.stringify(data.dan_toc.filter((item) => item.ID === 1)[0]),
        },
      ]);
    }, [data.dan_toc, data.quoc_gia, form]);

    useEffect(() => {
      HLog("ThongTinBenhNhan useEffect: ", NGUOI_TIEP_DON);
      setDataNhanSu(NGUOI_TIEP_DON);

      if (NGUOI_TIEP_DON && NGUOI_TIEP_DON?.length > 0) {
        const nv_tu_van = NGUOI_TIEP_DON.filter(obj => Constants.NHOM_NHAN_SU_TU_VAN.includes(obj.NHOM_NHAN_SU))
        setDataNVTuVan(nv_tu_van);
      }
    }, []);

    //================================================
    const formValueTinh = Form.useWatch(['TEN_TINH_THANH'], form);
    const formValueHuyen = Form.useWatch(['TEN_QUAN_HUYEN'], form);

    useEffect(() => {
      if (formValueTinh) {
        let val = {};
        let dataList = [];
        try {
          val = JSON.parse(formValueTinh);
          dataList = data.huyen[val[fieldTinh.id_tinh]]
        } catch (error) {
        }
        Array.isArray(dataList) && setDistrictList(
          dataList.map((item) => ({
            MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
            TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
          }))
        );
      }
    }, [formValueTinh])

    useEffect(() => {
      if (formValueHuyen) {
        let val = {};
        let dataList = [];
        try {
          val = JSON.parse(formValueHuyen);
          dataList = data.xa[val[fieldHuyen.id_huyen]]
        } catch (error) {
        }
        Array.isArray(dataList) && setWardList(
          dataList.map((item) => ({
            MA_PHUONG_XA: item.MA_PHUONG_XA,
            TEN_PHUONG_XA: item.TEN_PHUONG_XA,
            TEN_VIET_TAT: item.TEN_VIET_TAT,
          }))
        );
      }
    }, [formValueHuyen])

    // useEffect(() => {
    //   if (!batBuoc) {
    //     form.setFields([
    //       { name: fieldTiepDon.TEN_NGUOI_THAN, errors: null },
    //       { name: fieldTiepDon.SDT_NGUOI_THAN, errors: null },
    //     ]);
    //   }
    // }, [batBuoc]);

    useEffect(() => {
      if (isCreateNew && data.quoc_gia?.length > 0 && data.dan_toc?.length > 0 && visible) {
        HLog("[INFO] Init quoc gia Viet Nam & dan toc Kinh xxx");

        fillQuocGiaVaDanToc();

        setDisabledEthnic(false);
      }
    }, [fillQuocGiaVaDanToc, data.dan_toc, data.quoc_gia, isCreateNew, visible]);

    //================================================

    // Hàm xử lý viết hoa tên
    const handleUppercaseName = () => {
      if (!readOnly) {
        const name = form.getFieldValue(fieldTiepDon.ten_benh_nhan);
        form.setFields([
          {
            name: fieldTiepDon.ten_benh_nhan,
            value: name ? name.toUpperCase() : "",
          },
        ]);
      }
    };

    // Hàm xử lý sau khi chọn tỉnh thành
    const handleSelectProvince = (val) => {
      const parseVal = JSON.parse(val);
      const id_tinh = parseVal[fieldTinh.id_tinh];
      setDistrictList(
        data.huyen[id_tinh].map((item) => ({
          MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
          TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
        }))
      );
      setWardList([]);
      form.setFields([
        { name: fieldTiepDon.quan_huyen, value: null },
        { name: fieldTiepDon.xa_phuong, value: null },
        { name: fieldTiepDon.t_h_x, value: null },
      ]);
    };

    // Hàm xử lý sau khi chọn quận huyện
    const handleSelectDistrict = (val) => {
      const parseVal = JSON.parse(val);
      const id_huyen = parseVal[fieldHuyen.id_huyen];
      setWardList(
        data.xa[id_huyen].map((item) => ({
          MA_PHUONG_XA: item.MA_PHUONG_XA,
          TEN_PHUONG_XA: item.TEN_PHUONG_XA,
          TEN_VIET_TAT: item.TEN_VIET_TAT,
        }))
      );
      form.setFields([
        { name: fieldTiepDon.xa_phuong, value: null },
        { name: fieldTiepDon.t_h_x, value: null },
      ]);
    };

    // Hàm xử lý sau khi chọn xã phường
    const handleSelectWard = (val) => {
      const parseVal = JSON.parse(val);
      const t_h_x = parseVal[fieldXa.code_thx];
      setSelectedThx(parseVal);
      form.setFields([{ name: fieldTiepDon.t_h_x, value: t_h_x }]);
    };

    // Hàm xử lý sau khi chọn THX
    const handleSelectTHX = (val) => {

      const dataTinh = {
        [fieldTinh.id_tinh]: val[fieldTinh.id_tinh],
        [fieldTinh.ten_tinh]: val[fieldTinh.ten_tinh],
      };
      const dataHuyen = {
        [fieldHuyen.id_huyen]: val[fieldHuyen.id_huyen],
        [fieldHuyen.ten_huyen]: val[fieldHuyen.ten_huyen],
      };
      const dataXa = {
        [fieldXa.id_xa]: val[fieldXa.id_xa],
        [fieldXa.ten_xa]: val[fieldXa.ten_xa],
        [fieldXa.code_thx]: val[fieldXa.code_thx],
      };

      setDistrictList(
        data.huyen[val[fieldTinh.id_tinh]].map((item) => ({
          MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
          TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
        }))
      );
      setWardList(
        data.xa[val[fieldHuyen.id_huyen]].map((item) => ({
          MA_PHUONG_XA: item.MA_PHUONG_XA,
          TEN_PHUONG_XA: item.TEN_PHUONG_XA,
          TEN_VIET_TAT: item.TEN_VIET_TAT,
        }))
      );

      setSelectedThx(val);

      form.setFields([
        {
          name: fieldTiepDon.tinh_thanh,
          value: JSON.stringify(dataTinh),
          errors: [],
        },
        {
          name: fieldTiepDon.quan_huyen,
          value: JSON.stringify(dataHuyen),
          errors: [],
        },
        {
          name: fieldTiepDon.xa_phuong,
          value: JSON.stringify(dataXa),
          errors: [],
        },
      ]);
    };

    // Hàm xử lý chọn quốc gia
    const handleSelectNation = (val) => {
      const parseVal = JSON.parse(val);

      if (parseVal[fieldQuocGia.ten_goi_viet_tat] === "VN") {
        setDisabledEthnic(false);
      } else {
        setDisabledEthnic(true);
        form.setFields([{ name: fieldTiepDon.dan_toc, value: undefined }]);
      }
    };

    const filterOption = (input, option) => {
      return (
        option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option?.value?.toString().toLowerCase()?.indexOf(input.toLowerCase()) >= 0
      );
    };

    const onAfterAddNguonKhach = async (res) => {
      try {
        const list = await onGetListNguon();

        let fillForm = list.find((item) => item.MA_NGUON_KHACH == res.MA_NGUON_KHACH);

        if (!!fillForm) {
          fillForm = { ID: fillForm.ID, TEN_NGUON_KHACH: fillForm.TEN_NGUON_KHACH };
          setTimeout(() => {
            form.setFields([
              {
                name: fieldTiepDon.DM_NGUON_KHACH_ID,
                value: JSON.stringify(fillForm),
              },
            ]);
            setSelectedNguonKhach(() => JSON.stringify(fillForm));
          }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    };

    //hàm xử lí tìm kiếm tên nhân sự
    const timNhanSu = (value) => {
      if (value === "") {
        setDataNhanSu(NGUOI_TIEP_DON);
      }
      let arrData = NGUOI_TIEP_DON.filter((item) => {
        let name = item.HO + " " + item.TEN;
        if (name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          return item;
        }
      });
      setDataNhanSu(arrData);
    };

    const timNhanVienTuVan = (value) => {
      if (NGUOI_TIEP_DON && NGUOI_TIEP_DON?.length > 0) {
        if (value === "") {
          const nv_tu_van = NGUOI_TIEP_DON.filter(obj => Constants.NHOM_NHAN_SU_TU_VAN.includes(obj.NHOM_NHAN_SU))
          setDataNVTuVan(nv_tu_van);
        }
        let arrData = dataNVTuVan.filter((item) => {
          let name = item.HO + " " + item.TEN;
          if (name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
            return item;
          }
        });
        setDataNVTuVan(arrData);
      } else {
        setDataNVTuVan([]);
      }

    };

    const handleSelectPatient = (data) => {
      onSelectPatient(data)
      form.validateFields()
    }

    const ngaySinh = Form.useWatch(['NGAY_SINH'], form);
    const ruleDongBoDonThuoc = [{
      required: checkDongBoDonThuoc,
      message: ''
    }]


    const checkRuleNguoiThan = useMemo(() => {
      try {
        if (!checkDongBoDonThuoc) return [];
        // rule đơn thuốc cổng dược bắt buộc nếu là trẻ dưới 72 tháng tuổi
        // nếu nhập năm không cho trẻ dưới 72 tháng tuổi bắt buộc nhập ngày
        if (moment().diff(moment(ngaySinh, ngaySinh.length === 4 ? "YYYY" : "DDMMYYYY"), 'months') < 72) {
          return ruleDongBoDonThuoc
        } else {
          return []
        }
      } catch (error) {
        return [];
      }
    }, [checkDongBoDonThuoc, ngaySinh])

    return (
      <Spin
        spinning={loadingQR ? loadingQR : modeQR}
        indicator={(!loadingQR && modeQR) ? <QrcodeOutlined style={{ fontSize: 24 }} /> : undefined}
      >
        <Row gutter={[8, 2]} align="bottom" className={classNames(style["container"], horizontal ? style["hide-error-txt"] : "", extraClassName)}>
          {visiblePatientId && !horizontal && (
            <Col span={24}>
              <Form.Item label={i18n.t(languageKeys.field_Ma_benh_nhan)} name={fieldTiepDon.ma_benh_nhan}>
                <Input readOnly disabled={disableBenhNhan} />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Row gutter={[8, 5]} className={style.firstRow}>

              <Col span={horizontal ? 3 : 4}>
                <Form.Item
                  name={fieldTiepDon.so_dien_thoai}
                  label={i18n.t(languageKeys.field_So_dien_thoai)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                    {
                      pattern: /(84|0[3|5|7|8|9])+([0-9]{8,9})\b/,
                      message: i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase(),
                    },
                    {
                      min: 10,
                      message: i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase(),
                    },
                    {
                      max: 11,
                      message: i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase(),
                    },
                    {
                      validator: async (_, value) => {
                        if ((/^0+$/).test(value)) {
                          return Promise.reject(new Error(i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase()));
                        }
                      },
                    }

                    // {
                    //   max:11,
                    //   message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
                    // },
                    //asyncRule(validateName, 'check'),
                  ]}
                >
                  {!onSelectPatient ? (
                    <Input
                      className={style["icon-reload"]}
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                      tabIndex={5}
                      readOnly={readOnly || readOnlyInput}
                      disabled={disableBenhNhan}
                      onKeyDown={handleOnlyNumber}
                    // addonAfter={
                    //   <Tooltip placement="topLeft" title="Đổi số">
                    //     <Reload
                    //       onClick={(e) => {
                    //         e.preventDefault();
                    //         if (readOnly || readOnlyInput) return;
                    //         if (!readOnly) {
                    //           form.setFields([
                    //             {
                    //               name: fieldTiepDon.so_dien_thoai,
                    //               value: userProfile.benh_vien.SO_DIEN_THOAI,
                    //               errors: [],
                    //             },
                    //           ]);
                    //         }
                    //       }}
                    //     />
                    //   </Tooltip>
                    // }
                    />
                  ) : (
                    <InputSearchPatient.AutoCompleteSearch
                      keyFinder="BENH_NHAN_ID"
                      readOnly={readOnly || readOnlyInput}
                      onSelect={handleSelectPatient}
                      onBlur={handleUppercaseName}
                      tabIndex={1}
                      typeSearch="SDT"
                      subKeyLabel="TEN"
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                      onClear={onClearPatient}
                      width='500px'
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.ten_benh_nhan}
                  label={i18n.t(languageKeys.field_Ten_benh_nhan)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                  ]}
                >
                  {!onSelectPatient ? (
                    <Input
                      readOnly={readOnly || readOnlyInput}
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                      onBlur={handleUppercaseName}
                      ref={patientNameRef}
                      tabIndex={1}
                      disabled={disableBenhNhan}
                    />
                  ) : (
                    <InputSearchPatient.AutoCompleteSearch
                      keyFinder="BENH_NHAN_ID"
                      subKeyLabel="SO_DIEN_THOAI"
                      readOnly={readOnly || readOnlyInput}
                      onSelect={handleSelectPatient}
                      onBlur={handleUppercaseName}
                      tabIndex={1}
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                      onClear={onClearPatient}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={horizontal ? 2 : 10}>
                <Form.Item
                  name={fieldTiepDon.gioi_tinh}
                  label={i18n.t(languageKeys.field_Gioi_tinh)}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                    //asyncRule(validateName, 'check'),
                  ]}
                >
                  <SelectTab
                    dataSource={danhSachGioiTinh}
                    showSearch
                    titleKey="name"
                    valueKey="key"
                    filterOption={filterOption}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    tabIndex={2}
                  />
                </Form.Item>
              </Col>

              <Col span={3}>
                <DateFormItem
                  label={i18n.t(languageKeys.field_Ngay_sinh)}
                  name="NGAY_SINH"
                  tabIndex={[3, 4, 4]}
                  dateRef={dateRef}
                  form={form}
                  readOnly={readOnly || readOnlyInput}
                  disabled={disableBenhNhan}
                />
              </Col>
              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.DM_NGUON_KHACH_ID}
                  label={i18n.t(languageKeys.cate_nguon_khach)}
                  rules={ruleNguonKhach}
                >
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    titleKey="TEN_NGUON_KHACH"
                    // valueKey="ID"
                    dataSource={dsNguonKhach.map((item) => ({ ID: item.ID, TEN_NGUON_KHACH: item.TEN_NGUON_KHACH }))}
                    filterOption={filterOption}
                    showSearch
                    onAdd={() => {
                      nguonKhachRef.current.open()
                      nguonKhachSLRef?.current?.setOpenDropdown(false)
                    }}
                    ref={nguonKhachSLRef}
                    iconAdd={<PlusCircleFilled />}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    onSelect={setSelectedNguonKhach}
                    onDropdownVisibleChange={(visible) => {
                      nguonKhachSLRef?.current?.setOpenDropdown(visible)
                    }
                    }
                    loading={loadingNguonKhach}
                    tabIndex={5}
                  />
                </Form.Item>
              </Col>
              <Col span={horizontal ? 3 : 24}>
                <Popover
                  open={isPopoverVisible}
                  content={() => {
                    return  <>
                    {isValidBarcode(valueBarcode?.current) ? (
                      <Barcode value={valueBarcode?.current || ""} />
                    ) : (
                      <p style={{ color: "red" }}>Mã barcode không hợp lệ</p>
                    )}
                  </>
                  }}
                  title={false}
                  trigger="click"
                  onOpenChange={(visible) => setIsPopoverVisible(false)}
                  className={style["wrap-popover-input"]}
                >
                  <Form.Item
                    name={fieldTiepDon.BARCODE_KHACH_HANG}
                    label={i18n.t(languageKeys.field_barcode_kh)}
                    rules={[
                      {
                        pattern: /^[A-Za-z0-9\-_.]+$/,
                        message: "Chỉ cho phép chữ, số, dấu '-', '_' và '.'",
                      },
                    ]}

                  >
                    <Input tabIndex={6} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly || readOnlyInput} disabled={disableBenhNhan} suffix={<BarcodeOutlined onClick={(e) => {
                      if (valueBarcodeKh?.length > 0 && isValidBarcode(valueBarcodeKh)) {
                        handleIconClick(e);
                      }

                    }} style={{ cursor: "pointer" }} />} />
                  </Form.Item>
                </Popover>
              </Col>
              <Col span={horizontal ? 5 : 24}>
                <Form.Item
                  name={fieldTiepDon.dia_chi_chi_tiet}
                  label={i18n.t(languageKeys.field_Dia_chi_chi_tiet)}
                  rules={[...ruleDongBoDonThuoc, ...ruleDiaChiChiTiet]}
                >
                  <Input disabled={disableBenhNhan} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly || readOnlyInput} tabIndex={7} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ display: !hidePatientInfo ? "block" : "none" }}>
            <Row gutter={[8, 2]}>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item name={fieldTiepDon.quoc_gia} label={i18n.t(languageKeys.field_Quoc_gia)}>
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={data.quoc_gia.filter((item) => !!item.TEN_QUOC_GIA)}
                    showSearch
                    titleKey={fieldQuocGia.ten_quoc_gia}
                    onSelect={handleSelectNation}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    filterOption={filterOption}
                    tabIndex={8}
                  />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <SearchTHX tabIndex={9} disabled={disableBenhNhan} ref={thxRef} form={form} readOnly={readOnly || readOnlyInput} onSelect={handleSelectTHX} />
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.tinh_thanh}
                  label={i18n.t(languageKeys.field_Tinh_thanh)}
                  rules={[...ruleDongBoDonThuoc]}
                >
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={data.tinh}
                    showSearch
                    titleKey={fieldTinh.ten_tinh}
                    onSelect={handleSelectProvince}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    filterOption={filterOption}
                    tabIndex={10}
                  />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.quan_huyen}
                  label={i18n.t(languageKeys.field_Quan_huyen)}
                  rules={[...ruleDongBoDonThuoc]}
                >
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={districtList}
                    showSearch
                    titleKey={fieldHuyen.ten_huyen}
                    onSelect={handleSelectDistrict}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    filterOption={filterOption}
                    tabIndex={11}
                  />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.xa_phuong}
                  label={i18n.t(languageKeys.field_Xa_phuong)}
                  rules={[...ruleDongBoDonThuoc]}
                >
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={wardList}
                    showSearch
                    titleKey={fieldXa.ten_xa}
                    onSelect={handleSelectWard}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    filterOption={filterOption}
                    tabIndex={12}
                  />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item name={fieldTiepDon.nghe_nghiep} label={i18n.t(languageKeys.field_Nghe_nghiep)}>
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={data.nghe_nghiep}
                    showSearch
                    titleKey={fieldNgheNghiep.ten_nghe_nghiep}
                    filterOption={filterOption}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    tabIndex={13}
                  />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item name={fieldTiepDon.dan_toc} label={i18n.t(languageKeys.field_Dan_toc)}>
                  <SelectTab
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    dataSource={data.dan_toc}
                    showSearch
                    titleKey={fieldDantoc.ten_dan_toc}
                    filterOption={filterOption}
                    disabled={disableBenhNhan || disabledEthnic}
                    readOnly={readOnly || readOnlyInput}
                    tabIndex={14}
                  />
                </Form.Item>
              </Col>
              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.cccd}
                  label={i18n.t(languageKeys.field_CMND_CCCD)}
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
                    },
                  ]}
                >
                  <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly || readOnlyInput} disabled={disableBenhNhan} onKeyDown={handleOnlyNumber}
                    tabIndex={15} />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 24}>
                <Form.Item
                  name={fieldTiepDon.email}
                  label={i18n.t(languageKeys.field_Email)}
                  rules={[
                    {
                      pattern: /\S+@\S+\.\S+/,
                      message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
                    },
                  ]}
                >
                  <Input
                    tabIndex={16} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly || readOnlyInput} disabled={disableBenhNhan} />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 4 : 12}>
                <Form.Item name={fieldTiepDon.bang_lai_xe} label={i18n.t(languageKeys.hang_bang_lx)}>
                  <SelectTab
                    dataSource={DrivingLicense.map(i => ({ title: i, value: i }))}
                    valueKey={'value'}
                    titleKey={'title'}
                    disabled={disableBenhNhan}
                    tabIndex={17}
                  />
                </Form.Item>
              </Col>

              <Col span={horizontal ? 8 : 12}>
                <Form.Item name={fieldTiepDon.ly_do_kham} label={i18n.t(languageKeys.field_Ly_do_kham)}>
                  <Input
                    onBlur={() => form.setFields([{ name: fieldTiepDon.ly_do_kham, errors: null }])}
                    disabled={disableBenhNhan}
                    readOnly={readOnly || readOnlyInput}
                    placeholder={i18n.t(languageKeys.common_Nhap)}
                    tabIndex={18}
                    suffix={
                      useAi ? <Button
                        type="text"
                        size="small"
                        hidden={!showAi}
                        ghost
                        icon={<Icon component={Robot} style={{ fontSize: 18 }} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          const fieldName = form.getFieldValue(fieldTiepDon.ly_do_kham);
                          if (!fieldName) {
                            form.setFields([{ name: fieldTiepDon.ly_do_kham, errors: [i18n.t(languageKeys.vui_long_nhap)] }])
                            notification.warn({ message: i18n.t(languageKeys.vui_long_nhap), placement: 'bottomLeft' })
                            return
                          }

                          let data = form.getFieldsValue([fieldTiepDon.ten_benh_nhan, fieldTiepDon.ngay_sinh, fieldTiepDon.ly_do_kham, fieldTiepDon.gioi_tinh])

                          const tinhTuoi = () => {
                            try {
                              let age = '';
                              let birth = data?.[fieldTiepDon.ngay_sinh];
                              let year = birth?.slice(birth.length - 4, birth.length)
                              age = new Date().getFullYear() - +year;
                              if (age < 0 || age > 120) return '';
                              return `${age} tuổi`
                            } catch (error) {
                              return ''
                            }
                          }

                          let preMess = [
                            { message: `Tôi có 1 danh sách các loại chuyên khoa bao gồm: \n ` + loaiDichVu.filter(l => l.PHAN_LOAI === 'KHAM_BENH').map(l => `${l.TEN_LOAI}`).join(', ') },
                            // {message: `Chỉ trả lời các chuyên khoa dòng các chuyên khoa đó `},
                            { message: `Bỏ chữ Chuyên khoa trong danh sách dịch vụ đi ` },
                            { message: `Không dùng thẻ <br> ` }
                          ]
                          let patientDescriptions = (data?.[fieldTiepDon.ten_benh_nhan] || '') + ' ' + tinhTuoi() + (data?.[fieldTiepDon.gioi_tinh] ? ' ' + convertGender(data?.[fieldTiepDon.gioi_tinh]) : '');
                          patientDescriptions = patientDescriptions ? `${patientDescriptions}, ` : patientDescriptions;
                          let message = patientDescriptions + data?.[fieldTiepDon.ly_do_kham]?.toLowerCase();
                          makeAsk(message, suggestQuestionSpecialist, preMess);
                        }}
                      /> : false
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name={fieldTiepDon.TEN_NGUOI_THAN} label={i18n.t(languageKeys.ten_nguoi_than)} rules={checkRuleNguoiThan}>
                  <Input tabIndex={18} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnlyInput} disabled={disableBenhNhan} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  name={fieldTiepDon.SDT_NGUOI_THAN}
                  label={i18n.t(languageKeys.sdt_nguoi_than)}
                  rules={[
                    {
                      pattern: /(84|0[3|5|7|8|9])+([0-9]{8,9})\b/,
                      message: i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase(),
                    },
                    {
                      validator: async (_, value) => {
                        if (value && value?.length) {
                          if (value.length < 10 || value.length > 11)
                            return Promise.reject(new Error(i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase()));
                        }
                        if ((/^0+$/).test(value)) {
                          return Promise.reject(new Error(i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase()));
                        }
                      },
                    },
                    ...checkRuleNguoiThan,
                  ]}
                >
                  <Input tabIndex={19} className={style["icon-reload"]} readOnly={readOnly || readOnlyInput} disabled={disableBenhNhan} placeholder={i18n.t(languageKeys.common_Nhap)} onKeyDown={handleOnlyNumber} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name={fieldTiepDon.QUAN_HE_NGUOI_THAN} label={i18n.t(languageKeys.quan_he_gia_dinh)} rules={checkRuleNguoiThan}>
                  <SelectTab
                    dataSource={ds_loai_quan_he}
                    titleKey="name"
                    valueKey="name"
                    readOnly={readOnly || readOnlyInput}
                    disabled={disableBenhNhan}
                    allowClear
                    tabIndex={20}
                  />
                </Form.Item>
              </Col>
              <Col span={horizontal ? 4 : 12}>
                <Form.Item name={fieldTiepDon.DIA_CHI_NGUOI_THAN} label={i18n.t(languageKeys.dia_chi_nguoi_than)} rules={checkRuleNguoiThan}>
                  <Input tabIndex={21} placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={readOnly || readOnlyInput} disabled={disableBenhNhan} />
                </Form.Item>
              </Col>
              <Col span={horizontal ? 4 : 12}>
                <Form.Item
                  name={fieldTiepDon.NHAN_VIEN_TU_VAN_ID}
                  label={i18n.t(languageKeys.field_Nguoi_tu_van)}
                >
                  <SelectTab
                    dataSource={
                      dataNVTuVan
                        ? dataNVTuVan.map((nhan_su) => ({
                          TEN_NHAN_VIEN_TU_VAN: `${nhan_su.HO} ${nhan_su.TEN}`,
                          NHAN_VIEN_TU_VAN_ID: nhan_su.NHANSU_ID
                        }))
                        : []}
                    titleKey="TEN_NHAN_VIEN_TU_VAN"
                    tabIndex={22}
                    readOnly={readOnly || readOnlyInput}
                    disabled={disableBenhNhan}
                    allowClear
                    showSearch={true}
                    onSearch={timNhanVienTuVan}
                    onChange={(value, object) => {
                      form.setFieldsValue({
                        TEN_NHAN_VIEN_TU_VAN: object.TEN
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={horizontal ? 4 : 12}>
                <Form.Item
                  name={fieldTiepDon.NGUOI_HIEN_THI}
                  label={i18n.t(languageKeys.field_Nguoi_tiep_don)}
                  initialValue={userProfile?.HO + " " + userProfile?.TEN}
                >
                  <SelectTab
                    dataSource={
                      dataNhanSu
                        ? dataNhanSu.map((nhan_su) => ({
                          USERNAME: nhan_su.USERNAME,
                          NHANSU_ID: nhan_su.NHANSU_ID,
                          NGUOI_HIEN_THI: nhan_su.HO + " " + nhan_su.TEN,
                        }))
                        : []
                    }
                    tabIndex={23}
                    titleKey="NGUOI_HIEN_THI"
                    valueKey="NGUOI_HIEN_THI"
                    readOnly={readOnly || readOnlyInput}
                    disabled={disableBenhNhan}
                    allowClear
                    showSearch={true}
                    onSearch={timNhanSu}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>



          <CaiDatDanhMuc
            currentKey={keysDanhMuc.dm_nguon_khach}
            onHideParent={onHideParent}
            ref={nguonKhachRef}
            onAddSubmitSuccess={onAfterAddNguonKhach}
            disableThemMoi={disableThemMoi}
          />
        </Row>
        {showHideDetail && (
          <Divider
            plain
            style={{ margin: "0", cursor: "pointer" }}
            onClick={() => {
              onHidePatientInfo(!hidePatientInfo);
              !hidePatientInfo && triggerOnHidden();
            }}

            {...dividerProps}
          >
            <Icon component={ArrowDownBold} style={{ transform: !hidePatientInfo ? "rotate(180deg)" : "", marginLeft: 2, fontSize: 8 }} />
            <span style={{ marginInline: 8, color: "#6576FF" }}>{!hidePatientInfo ? i18n.t(languageKeys.thu_gon) : i18n.t(languageKeys.mo_rong)}</span>
          </Divider>
        )}

        <QRInput
          isCreateNew={isCreateNew}
          open={visible}
          form={form}
          dateRef={dateRef}
          handleFillForm={onSelectPatient}
          isRedux={false}
          setDistrictList={setDistrictList}
          setWardList={setWardList}
          setSelectedThx={setSelectedThx}
          handleClearPatient={onClearPatient}
        />

      </Spin>
    );
  }
);

function convertMillisecondToAge(millisecond, format) {
  // Dành cho trường hợp giá trị đầu vào là dd/mm/yyyy @.@
  if (typeof millisecond === "string" && millisecond.includes("/")) {
    const arrNoSlash = millisecond.split("/");

    if ([...arrNoSlash].length === 3) {
      //millisecond = moment(millisecond, "DD/MM/YYYY").valueOf();
      millisecond = moment(millisecond, format).valueOf();
    }
  }

  if (moment(millisecond, "YYYYMMDD", true).isValid()) {
    millisecond = moment(millisecond, "YYYYMMDD").valueOf();
  }

  millisecond = Number(millisecond); // đổi input thành number

  // nếu milli = NaN hoặc milli = 0 thì return "--"
  if (isNaN(millisecond) || millisecond === 0) {
    return "--";
  }

  // đổi input (milli) về gap giữa hiện tại và input
  let duration = Math.floor(moment.duration(moment().diff(moment(millisecond))).asYears());

  return duration;
}

export default memo(ThongTinKhamBenh);
