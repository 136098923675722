import React, { memo, useState } from "react";
import { InputNumber } from "antd";
import { calcTotal, currencyParser } from "../../../../helpers";

function DiscountInput({ init, data, onChangeDataSource = () => {}, className }) {

  const [currencyTxt, setCurrencyTxt] = useState(init ? init : 0);
  const [discountType, setDiscountType] = useState(init ? (init > 100) : false);

  const handleChangeDiscount = (value) => {
    if (value > 100) {
      !discountType && setDiscountType(true);
    } else {
      discountType && setDiscountType(false);
    }

    const onChangeData = (currentData) => {
      if (!Array.isArray(currentData)) return [];
      const totalCost = data.UNIQ_BUNDLE_ID
        ? data.TONG_TIEN - calcTotal(data.DS_DV, 'MIEN_GIAM')
        : +data.DON_GIA * +data.SO_LUONG;
      const discountValue = +(value > 100 ? value : (totalCost / 100) * value);
      if (discountValue > totalCost || discountValue < 0) {
        return currentData;
      }
      setCurrencyTxt(value);
      const index = currentData.findIndex((item) => item.ID === data.ID);
      currentData[index] = {
        ...data,
        MIEN_GIAM_DICH_VU: discountValue,
        GIAM_TIEN: value > 100 ? value : discountValue,
        TONG_TIEN_GIAM: discountValue,
        GIAM_PHAN_TRAM: value <= 100 ? value : 0,
      };
      return currentData;
    }

    onChangeDataSource(onChangeData);
  };

  return (
    <InputNumber
      onChange={(value) => handleChangeDiscount(value)}
      formatter={(value) => formatNumberToPrice2(value)}
      parser={(value) => currencyParser(value)}
      value={currencyTxt}
      addonAfter={discountType ? "đ" : "%"}
      controls={!!0}
      className={className}
      readOnly={data.IS_GOI_DV}
    />
  );
}

DiscountInput.propTypes = {};

export default memo(DiscountInput);

const formatNumberToPrice2 = (x) => {
  if (!x || x === "" || x === 0) {
    return 0;
  }
  if (x === 1) {
    return 1;
  }
  x = x.toString();
  x = x.replace(/ /g, "");
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1.$2");
  return x;
};
