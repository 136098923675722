import { Button, Input, Layout, Segmented, Space, Spin } from "antd";
import style from "./siderDvTiepDon.module.less";
import { forwardRef, memo, useImperativeHandle } from "react";
import i18n, { languageKeys } from "../../../../i18n";
import { useState } from "react";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import cn from "classnames";
import ListDvKhamBenh from "../../components/ListDvKhamBenh/ListDvKhamBenh";
import ListDvCls from "../../components/ListDvCls/ListDvCls";
import { Select } from "../../../../components";
import { rid } from "../../../../helpers";
import { keys } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { TAB_DICH_VU, TAB_KHAM_BENH } from "../ModalTiepDon";
import { useEventListener } from "../../../../hooks";
import { useMemo } from "react";
import { updateIndexedDbWithSaga } from "ducks/slices/dataSlice";
import { IndexedDbLoadingState } from "ducks/slices/loadingSlice";
import { getExemptionData } from "ducks/slices/ListData/Exemption.slice";

let SiderDvTiepDon = (
  {
    selectedDoiTuong = keys.VIEN_PHI,
    dsDvKhamBenhDaChon = [],
    setDsDvKhamBenhDaChon = () => {},
    dsDvClsDaChon = [],
    setDsDvClsDaChon = () => {},
    visible = false,
    currentTab,
    setCurrentTab,
    tabs = [],
    disabled = false,
    openTTBN,
    styleCustom,
    setExpandedRow,
    expandedRow
  },
  ref
) => {

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const data = useSelector((state) => state.data);
  const exp = useSelector(getExemptionData)
  const loadingSider = useSelector(IndexedDbLoadingState)

  const [searchValue, setSearchValue] = useState();
  const [selectedLoaiDvCls, setSelectedLoaiDvCls] = useState({});
  const [selectedNhomDv, setSelectedNhomDv] = useState({});

  const initData = useMemo(() => {
    try {
      let result = { cls: [], kham_benh: [], nhom_dv: [] };

      if (Array.isArray(data.cls)) result = { ...result, cls: data.cls };
      if (Array.isArray(data.kham_benh)) result = { ...result, kham_benh: data.kham_benh };
      if (Array.isArray(data.nhom_dv)) result = { ...result, nhom_dv: data.nhom_dv };

      return result;
    } catch (error) {
      return { cls: [], kham_benh: [], nhom_dv: [] };
    }
  }, [data])

  
  useImperativeHandle(ref, () => ({
    reset: handleReset,
    // onTreeClsSelect,
  }));


  const handleReset = () => {
    setSelectedLoaiDvCls({});
    setSearchValue();
    setCurrentTab(tabs[0]);
  };


  const onTreeKBSelect = async (data) => {
    if (disabled) return;

    // if (!selectedDoiTuong) {
    //   return notification.error({
    //     placement: "bottomLeft",
    //     message: i18n.t(languageKeys.error_Chua_chon_doi_tuong_benh_nhan),
    //   });
    // }

    if (data.disabled) {
      return;
    }
    if (data.selectable) {
      if (data.checked) {
        setDsDvKhamBenhDaChon((currentArray) => currentArray.filter((item) => item.DV_ID !== data.value.DV_ID));
      } else {
        const GIA_DT = data.value.LIST_GIA_THEO_DICH_VU?.[0] || {}
        // let { dsPrice, GIA_DICH_VU } = taoDanhSachGia(data.value, "KHAM_BENH");
        // delete data.value["ID"];
        // delete data.value["STT"];
        setDsDvKhamBenhDaChon((currentArr) => [
          ...currentArr,
          {
            ...data.value,
            // dsGia: dsPrice,
            _id: rid(7),
            key: rid(),
            GIA_DICH_VU: GIA_DT.GIA_DICH_VU,
            GIA_GOC_DICH_VU: GIA_DT.GIA_DICH_VU,
            PHAN_LOAI: "KHAM_BENH",
            NGUOI_TAO: userProfile,
            SO_LUONG: 1,
            // THANH_TIEN: data.value.GIA_THU_PHI,
            STT: undefined,
            ID: undefined,
            order: new Date().getTime(),
            DM_DOI_TUONG_ID: GIA_DT.DM_DOI_TUONG_ID,
            PHONG_THUC_HIEN_ID: data.value.LIST_PHONG_ID?.[0],
            MIEN_GIAM_TIEP_DON: exp.find(i => i.DV_ID === data.value.DV_ID)?.MIEN_GIAM || 0,
          },
        ]);
      }
    }
  };

  // const taoDanhSachGia = (value, type = "KHAM_BENH") => {
  //   let gia_bhyt = {
  //     name: i18n.t(languageKeys.field_Gia_bhyt),
  //     price: handleGetProperty(value, "GIA_BHYT", 0),
  //     key: "GIA_BHYT",
  //   };

  //   let gia_yc = {
  //     name: i18n.t(languageKeys.field_Gia_dich_vu),
  //     price: handleGetProperty(value, "GIA_DICHVU", 0),
  //     key: "GIA_DICHVU",
  //   };

  //   let gia_nc_ngoai = {
  //     name: i18n.t(languageKeys.field_Gia_nuoc_ngoai),
  //     price: handleGetProperty(value, "GIA_NUOC_NGOAI", 0),
  //     key: "GIA_NUOC_NGOAI",
  //   };

  //   let gia_thuphi = {
  //     name: i18n.t(languageKeys.field_Gia_thu_phi),
  //     price: handleGetProperty(value, "GIA_THU_PHI", 0),
  //     key: "GIA_THU_PHI",
  //   };

  //   let dsPrice;

  //   let dsPriceVienPhi = [gia_thuphi, gia_yc];

  //   const config = userProfile.config;

  //   if (
  //     !!config.DM_DICHVU_KYTHUAT &&
  //     !!config.DM_DICHVU_KYTHUAT.isCo &&
  //     Array.isArray(config.DM_DICHVU_KYTHUAT.TRUONG_BO) &&
  //     !!config.DM_DICHVU_KYTHUAT.TRUONG_BO.some((item) => item === "GIA_YEU_CAU")
  //   ) {
  //     dsPriceVienPhi.shift();
  //   }

  //   if (type === "KHAM_BENH") {
  //     switch (selectedDoiTuong) {
  //       case keys.BHYT:
  //         dsPrice = [gia_bhyt, gia_yc, gia_thuphi];
  //         break;
  //       case keys.VIEN_PHI:
  //         dsPrice = dsPriceVienPhi;
  //         break;
  //       case keys.YEU_CAU:
  //         dsPrice = dsPriceVienPhi;
  //         break;
  //       case keys.NUOC_NGOAI:
  //         dsPrice = [gia_nc_ngoai];
  //         break;
  //       default:
  //         dsPrice = [gia_bhyt, gia_yc, gia_thuphi];
  //         break;
  //     }
  //   } else {
  //     switch (selectedDoiTuong) {
  //       case keys.NUOC_NGOAI:
  //         dsPrice = [gia_nc_ngoai];
  //         break;
  //       default:
  //         dsPrice = [gia_thuphi];
  //         break;
  //     }
  //   }

  //   let GIA_DICH_VU = 0;
  //   if (type === "KHAM_BENH") {
  //     switch (selectedDoiTuong) {
  //       case keys.BHYT:
  //         GIA_DICH_VU =
  //           handleGetProperty(value, "GIA_BHYT", 0) === 0 ? handleGetProperty(value, "GIA_THU_PHI", 0) : handleGetProperty(value, "GIA_BHYT", 0);
  //         break;
  //       case keys.VIEN_PHI:
  //         GIA_DICH_VU =
  //           handleGetProperty(value, "GIA_THU_PHI", 0) === 0 ? handleGetProperty(value, "GIA_DICHVU", 0) : handleGetProperty(value, "GIA_THU_PHI", 0);
  //         break;
  //       case keys.YEU_CAU:
  //         GIA_DICH_VU =
  //           handleGetProperty(value, "GIA_DICHVU", 0) === 0 ? handleGetProperty(value, "GIA_THU_PHI", 0) : handleGetProperty(value, "GIA_DICHVU", 0);
  //         break;
  //       case keys.NUOC_NGOAI:
  //         GIA_DICH_VU =
  //           handleGetProperty(value, "GIA_NUOC_NGOAI", 0) === 0
  //             ? handleGetProperty(value, "GIA_THU_PHI", 0)
  //             : handleGetProperty(value, "GIA_NUOC_NGOAI", 0);
  //         break;
  //       default:
  //         GIA_DICH_VU = handleGetProperty(value, "GIA_DICHVU", 0);
  //         break;
  //     }
  //   } else {
  //     GIA_DICH_VU = handleGetProperty(value, "GIA_THU_PHI", 0);
  //   }
  //   return { dsPrice, GIA_DICH_VU };
  // };

  function onChangeLoaiDvCls(event) {
    if (!!event) {
      setSelectedLoaiDvCls(JSON.parse(event));
    } else {
      setSelectedLoaiDvCls({});
    }
  }

  function onChangeNhomDichVu(event) {
    if (!!event) {
      setSelectedNhomDv(JSON.parse(event));
    } else {
      setSelectedNhomDv({});
    }
  }

  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "k") {
      event.stopPropagation();
      event.preventDefault();
      setCurrentTab(TAB_KHAM_BENH);
    }
    if (event.ctrlKey && event.key === "e") {
      event.stopPropagation();
      event.preventDefault();
      setCurrentTab(TAB_DICH_VU);
    }
  };
  useEventListener("keydown", keydownEvent, window.document, visible);


  return (
    <Layout.Sider width={260} theme="light" className={style["container"]}>
      <div className={style["wrapper"]}>
        <Segmented block options={tabs} className={style["segmented"]} onChange={setCurrentTab} value={currentTab} />
      </div>

      <div className={style["wrapper"]}>
      {/* {currentTab === TAB_DICH_VU && ( */}
            <Select
              dataSource={initData?.nhom_dv || []}
              style={{ marginBottom: 10, width: "100%" }}
              placeholder={i18n.t(languageKeys.nhom_dv)}
              titleKey="TEN_NHOM"
              onSelect={onChangeNhomDichVu}
              allowClear
              onClear={onChangeNhomDichVu}
            />
          {/* )} */}

        {currentTab === TAB_DICH_VU && (
          <Select
            dataSource={initData?.cls || []}
            style={{ marginBottom: 10, width: "100%" }}
            placeholder={i18n.t(languageKeys.field_Loai_dich_vu)}
            titleKey="TEN_LOAI_DICH_VU"
            onSelect={onChangeLoaiDvCls}
            allowClear
            onClear={onChangeLoaiDvCls}
          />
        )}

       

        <Space>
          <Input
            prefix={<SearchOutlined className="blue-txt" />}
            placeholder={i18n.t(languageKeys.common_Tim_kiem)}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            allowClear
          />
          <Button icon={<ReloadOutlined />} onClick={() => dispatch(updateIndexedDbWithSaga())} type="primary" loading={loadingSider} />
        </Space>

      </div>

      <Spin spinning={loadingSider}>
        <div
          className={cn(
            style["wrapper"],
            style["list"],
            currentTab === TAB_KHAM_BENH ? style["tab-kham-benh"] : style["tab-dich-vu"],
            openTTBN && style["open-ttbn"],
            styleCustom && styleCustom
          )}
        >
          <div style={currentTab !== TAB_KHAM_BENH ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
            <ListDvKhamBenh
              dsDvDaChon={dsDvKhamBenhDaChon}
              dsDvKhamBenh={initData?.kham_benh || []}
              searchVal={searchValue}
              onTreeKBSelect={onTreeKBSelect}
              disabled={disabled}
              filterItemNhomDv={selectedNhomDv}
            />
          </div>

          <div style={currentTab !== TAB_DICH_VU ? { visibility: "hidden", opacity: 0, position: "fixed" } : {}}>
            <ListDvCls
              dsDvCls={initData?.cls || []}
              dsDvDaChon={dsDvClsDaChon}
              filterItem={selectedLoaiDvCls}
              filterItemNhomDv={selectedNhomDv}
              search_string={searchValue}
              // onTreeClsSelect={onTreeClsSelect}
              disabled={disabled}
              //
              setDsDvClsDaChon={setDsDvClsDaChon}
              dsDvKhBenhDaChon={dsDvKhamBenhDaChon}
              setDsDvKhBenhDaChon={setDsDvKhamBenhDaChon}
              setExpandedRowDsChon={setExpandedRow}
              expandedRowDsChon={expandedRow}
            />
          </div>
        </div>
      </Spin>

    </Layout.Sider>
  );
};

SiderDvTiepDon = forwardRef(SiderDvTiepDon);
export default memo(SiderDvTiepDon);
