/* eslint-disable react/jsx-pascal-case */
import { memo, useMemo } from "react";
import style from "./table.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import { Button, Dropdown, Input, Space, Table, Typography, InputNumber } from "antd";
import cn from "classnames";
import { formatNumberToPrice, getFullName, hasDuplicateValues, locdau, rid } from "../../../../../helpers";
import { useState, useEffect, useRef } from "react";
import { CaretDownOutlined, CaretRightOutlined, DownOutlined } from "@ant-design/icons";
import { ActionButton, Select, Table as TableLocal } from "../../../../../components";
import moment from "moment";
import { TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI } from "../../../../../constants/keys";
import { Check_box, Uncheckbox } from "../../../../../assets/svg";
import { useSelector } from "react-redux";
import { tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import SelectChangeTarget from "components_v2/SelectChangeTarget/SelectChangeTarget";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import DrawerHuyPhieu from "../DrawerHuyPhieu";
import SelectPhongThHien from "pages/QuanLyGoiDichVu/GoiLieuTrinh/ChiDinhDvGoi/SelectPhongThHien";


const TableGoiDichVu = ({
  openTTBN,
  dataSource = [],
  setDataSource = () => {},
  readOnly = false,
  isCreateNew = true,
  onPrint = () => {},
  scheduleInfo,
  disableDichVu,
  disableIn,
  //
  expandedRow,
  setExpandedRow,
  //
  bundleReceipts,
  setBundleReceipts,
  setLichKhamInfo = () => {},
  cancelRef,
  checkPermisson,
  hightlighter = false,
  arrServiceExamTabs = [],
}) => {

  const syncTargetRef = useRef()
  // const cancelRef = useRef()

  const forceRender = useState();

  const { dsGoiDvBenhNhan, chiTietDsGoiDv } = useSelector(tiepDonState);
  const { todayDoctors, personnel } = useSelector(getSharedData);


  useEffect(() => {
    if (chiTietDsGoiDv && dsGoiDvBenhNhan) {
      let expandedRowKeys = []
      enhanceDataSource(chiTietDsGoiDv).forEach(item => {
        expandedRowKeys.push(item._id)
        item.children?.forEach(dv => {
          if (dv.DV_KEM_THEO) expandedRowKeys.push(dv._id)
        })
      })
      setExpandedRow(expandedRowKeys)
    }
  }, [chiTietDsGoiDv, dsGoiDvBenhNhan])


  const enhanceDataSource = (_dataSource = []) => {
    let arr = []
    if (_dataSource.length > 0) {
      dsGoiDvBenhNhan.forEach(goi => {
        let DsDv = []
        goi?.DS_DV?.forEach(dv => {
          if (dv?.DV_KEM_THEO) {
            let foundCombo = _dataSource.filter(i => dv?.DV_KEM_THEO?.flatMap(dvkt => dvkt?.ID).includes(i?.ID))
            foundCombo.length && DsDv.push({ ...dv, _id: dv?.ID, children: foundCombo })
          } else {
            let foundService = _dataSource.find(i => i?.ID === dv?.ID || i?.ID_DV_IN_GOI === dv?.ID)
            foundService && DsDv.push(foundService)
          }
        })
        DsDv.length && arr.push({
          key: "TEN_GOI_DV",
          _id: goi?.GOI_DV_ID,
          title: goi?.TEN_GOI_DV,
          children: DsDv
        })
      })
    }
    return arr
  };


  const checkPay = (data, record = {}) => {
    try {
      let isPay;
      if (record && Array.isArray(record.DV_KEM_THEO)) {
        let isComboThanhToan = record.DV_KEM_THEO?.some(i => i.THANH_TOAN);
        isPay = isComboThanhToan;
      } else {
        isPay = data;
      }
      return isPay;
    } catch (error) {
      console.log(error);
    }
  }


  const showDeleteBtn = (dichvu) => {
    if (dichvu.THANH_TOAN === 1) return false;
    // if (dichvu.THANH_TOAN === 1 && dichvu.THANH_TIEN === 0) return false;

    if (
      // dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.CHO_KHAM ||
      dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
      dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
      dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH ||
      dichvu.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN"
    ) {
      return false;
    }
  };


  const findEarliestDate = (data, record) => {
    try {

      let dsGioDaCo = record.children.some(i => !!i.GIO);

      if (dsGioDaCo) {
        let listDate = record.children.map(i => moment(`${i.GIO} ${i.NGAY}`, 'hh:mm YYYYMMDD'));
        let minDate = moment.min(listDate);
        return !!minDate && scheduleInfo ? (moment(minDate).isValid() ? moment(minDate).format("HH:mm DD/MM/YYYY") : moment().format("HH:mm DD/MM/YYYY")) : moment().format("HH:mm DD/MM/YYYY");
      } else {
        return moment().format("HH:mm DD/MM/YYYY")
      }
    } catch (error) {
      console.log(error)
      return (!!data && scheduleInfo ? moment(data).format("HH:mm DD/MM/YYYY") : moment().format("HH:mm DD/MM/YYYY"))
    }
  }


  const handleCreateReceipt = (_id) => {
    setBundleReceipts(bundleReceipts.concat(dataSource.filter(i => i.GOI_ID === _id && !i.THANH_TOAN)))
  }

  const handleCancelReceipt = (_id, temp) => {
    if (temp) {
      setBundleReceipts(bundleReceipts.filter(i => i.GOI_ID !== _id))
    } else {
      cancelRef.current?.open([_id], scheduleInfo?.LICH_KHAM_ID)
    }
  }


  // Columns
  const columns = [
    {
      title: i18n.t(languageKeys.ma_dich_vu),
      dataIndex: "MA_DV",
      width: 150,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? (isCreateNew ? 11 : 13) : 1 }),
      render: (MA_DV, record) => {
        if (record.key === "TEN_GOI_DV") {
          return <label>{record.title}</label>
        } else {
          return MA_DV || record.MA_DICHVU
        }
      }
    },
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      dataIndex: "TEN_DICHVU",
      width: 300,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (TEN_DICHVU, record) => TEN_DICHVU || record.TEN_DV,
    },
    {
      title: i18n.t(languageKeys.field_don_gia) + " (đ)",
      dataIndex: "DON_GIA_PHAN_BO",
      width: 100,
      render: (val, record) => (record && !record.DV_KEM_THEO) ? formatNumberToPrice(val) : "",
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
    },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "SO_LUONG",
      render: (data, record) => record && !record.DV_KEM_THEO && (
        <InputNumber
          value={data}
          style={{ width: 65 }}
          min={1}
          max={record.old ? record.DINH_MUC : record.CON_LAI}
          readOnly={disableDichVu || (record.old && readOnly)}
          size="middle"
          onChange={(value) => handleSoLuong(value, record)}
          onBlur={(e) => !e.target.value && handleSoLuong(1, record)}
        />
      ),
      // ...fitCenterCol,
      width: 75,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
    },
    // {
    //   title: "",
    //   ellipsis: true,
    //   dataIndex: "CON_LAI",
    //   width: 45,
    //   render: (CON_LAI, record) => record.children
    //     ? ""
    //     : record.old
    //       ? `/${record.DINH_MUC < 10 ? `0${record.DINH_MUC}` : record.DINH_MUC}`
    //       : `/${CON_LAI < 10 ? `0${CON_LAI}` : CON_LAI}`,
    //   onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
    // },
    {
      title: i18n.t(languageKeys.tong_tien) + " (đ)",
      dataIndex: "THANH_TIEN_PHAN_BO",
      ellipsis: true,
      width: 125,
      render: (_, record) => (record && !record.DV_KEM_THEO)
        ? formatNumberToPrice(record.DON_GIA_PHAN_BO * record.SO_LUONG)
        : "",
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
    },
    {
      title: i18n.t(languageKeys.cate_doi_tuong),
      dataIndex: "DM_DOI_TUONG_ID",
      width: 160,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (id, record) => (
        (record && !record.DV_KEM_THEO)
          ? <SelectChangeTarget
            id={id}
            record={record}
            syncTargetRef={syncTargetRef}
            handleChangeTarget={handleChangeTarget}
            dataSource={dataSource}
            readOnly={(record.children ? record.children.some(i => daKham(i.TRANG_THAI)) : daKham(record.TRANG_THAI)) || record.THANH_TOAN === 1}
          />
          : <></>
      ),
    },
    {
      title: i18n.t(languageKeys.field_Phong_thuc_hien),
      dataIndex: "PHONG_THUC_HIEN_ID",
      ellipsis: true,
      width: 250,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (value, record) => {
        if (record && !record.DV_KEM_THEO) {
          return (
            <SelectPhongThHien
              value={value}
              record={record}
              onSelect={handleSelectPhong}
              readOnly={disableDichVu || (record.old && readOnly)}
            />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: i18n.t(languageKeys.field_Bac_si_kham),
      dataIndex: "BAC_SI",
      width: 150,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => {
        if (record && !record.DV_KEM_THEO) {
          return !!data && Object.keys(data).length === 2 ? (
            <Input value={data?.TEN_BAC_SI} readOnly size="small" />
          ) : (
            <Input value={getFullName(data)} readOnly size="small" />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: i18n.t(languageKeys.noi_thuc_hien),
      dataIndex: "NOI_THUC_HIEN_XN",
      ellipsis: true,
      width: 175,
      render: (data, record) => (
        record?.LIST_NOI_THUC_HIEN_XN?.length > 0
          ? <NoiThucHienXn
            noiThucHienXn={data}
            listNoiThucHienXn={record?.LIST_NOI_THUC_HIEN_XN}
            dichvu={record}
            handleSelectNoiThXn={handleSelectNoiThXn}
            readOnly={disableDichVu}
            scheduleInfo={scheduleInfo}
          />
          : <></>
      ),
    },
    {
      title: i18n.t(languageKeys.nguoi_tao),
      dataIndex: "NGUOI_TAO",
      width: 180,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => !record.old && !record.DV_KEM_THEO
        ? (
          <Select
            value={record.TIEP_TAN_ID || data?.NHANSU_ID}
            dataSource={personnel.map(i => ({ ...i, NAME: getFullName(i) }))}
            titleKey="NAME"
            valueKey="NHANSU_ID"
            filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
            readOnly={disableDichVu}
            style={{ width: '100%' }}
            onChange={(val) => handleNguoiTao(val, record)}
          />
        ) : (
          <Typography.Text ellipsis={{ tooltip: typeof data === "string" ? data : data?.USERNAME }} className="navy-txt" >
            {typeof data === "string" ? data : getFullName(data)}
          </Typography.Text >
        )
    },
    {
      title: i18n.t(languageKeys.field_Ngay_tao),
      dataIndex: "THOI_GIAN_TAO",
      width: 130,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => {
        if (record.DV_KEM_THEO) return <></>
        if (record.children) {
          const findMinDate = findEarliestDate(data, record);
          return findMinDate ? findMinDate : moment().format("HH:mm DD/MM/YYYY");
        } else {
          return (!!data && scheduleInfo ? !record.GIO ? moment().format("HH:mm DD/MM/YYYY") : `${moment(data).format("HH:mm DD/MM/YYYY")}` : moment().format("HH:mm DD/MM/YYYY"))
        }
      },
    },
    {
      title: i18n.t(languageKeys.tiepdon_nguoi_sua),
      dataIndex: "TIEP_TAN_ID",
      width: 180,
      showAtNewScreen: false,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => record.old && !record.DV_KEM_THEO
        ? (
          <Select
            value={data}
            dataSource={personnel.map(i => ({ ...i, NAME: getFullName(i) }))}
            titleKey="NAME"
            valueKey="NHANSU_ID"
            filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
            readOnly={disableDichVu}
            style={{ width: '100%' }}
            onChange={(val) => handleNguoiTao(val, record)}
          />
        )
        : <></>,
    },
    {
      title: i18n.t(languageKeys.tiepdon_ngay_sua),
      dataIndex: "THOI_GIAN_SUA",
      width: "130px",
      showAtNewScreen: false,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => record.DV_KEM_THEO ? "" : (!!data && moment(data).format("HH:mm DD/MM/YYYY")),
    },
    {
      title: i18n.t(languageKeys.status_Da_thanh_toan),
      dataIndex: "THANH_TOAN",
      width: 85,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => {
        if (record.DV_KEM_THEO) return <></>
        return (
          <div className={style["test"]}>
            <div className={style["test"]}>{!!checkPay(data, record) ? <Check_box /> : <Uncheckbox />}</div>
          </div>
        );
      },
      align: "center",
    },
    {
      title: i18n.t(languageKeys.dang_TH),
      dataIndex: "TRANG_THAI",
      width: 90,
      ellipsis: true,
      nearFix: true,
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
      render: (data, record) => {
        if (record.DV_KEM_THEO) return <></>
        if (record.children) {
          return <div className={style["test"]}>{record.children.some(i => dangKham(i.TRANG_THAI)) ? <Check_box /> : <Uncheckbox />}</div>
        } else {
          return <div className={style["test"]}>{!!dangKham(data) ? <Check_box /> : <Uncheckbox />}</div>
        }
      },
      align: "center",
    },
    {
      title: i18n.t(languageKeys.da_KQ),
      dataIndex: "TRANG_THAI_KET_QUA",
      width: 85,
      ellipsis: true,
      nearFix: true,
      render: (data, record) => {
        if (record.DV_KEM_THEO) return <></>
        return (
          <div className={style["test"]}>
            {["DA_TRA_KET_QUA", "HOAN_THANH"].includes(data) ? <Check_box /> : <Uncheckbox />}
          </div>
        )
      },
      align: "center",
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 0 : 1 }),
    },
    {
      title: "",
      width: 60,
      fixed: "right",
      onCell: (record) => ({ colSpan: (record.key === "TEN_GOI_DV") ? 4 : 1 }),
      render: (_, record) => {
        if (record.key === "TEN_GOI_DV") {
          return (
            <Space onClick={(e) => e.stopPropagation()}>
              {bundleReceipts.filter(i => i.GOI_ID === record._id).length === 0 && dataSource.some(i => !i.THANH_TOAN && i.GOI_ID === record._id) && (
                <Button
                  type="primary"
                  onClick={() => handleCreateReceipt(record._id)}
                  disabled={!checkPermisson?.TAO_PHIEU_THU}
                >
                  {i18n.t(languageKeys.tao_phieu_thu)}
                </Button>
              )}
              {bundleReceipts.filter(i => i.GOI_ID === record._id).length > 0 && (
                <Button
                  type="primary" ghost
                  onClick={() => handleCancelReceipt(record._id, true)}
                  disabled={!checkPermisson?.TAO_PHIEU_THU}
                >
                  {i18n.t(languageKeys.huy_phieu_thu)}
                </Button>
              )}
              {dataSource.some(i => i.THANH_TOAN && i.GOI_ID === record._id) && (
                <Button
                  type="primary" ghost danger
                  onClick={() => handleCancelReceipt(record._id, false)}
                  disabled={!checkPermisson?.HUY_PHIEU_THU}
                >
                  {i18n.t(languageKeys.huy_phieu_thu_da_tao)}
                </Button>
              )}
            </Space>
          )
        } else {
          if (!record.children) {
            return (
              <ActionButton
                deleteTip={i18n.t(languageKeys.vienphi_xoa_dich_vu)}
                printTip={i18n.t(languageKeys.tiepdon_in_dich_vu)}
                record={record}
                showEdit={false}
                showDelete={!!showDeleteBtn(record) && record.old && readOnly && record.CON_LAI > 0}
                onDelete={handleDelete}
                showPrint={readOnly}
                onPrint={() => onPrint(undefined, [record.DICH_VU_ID || record._id])}
                disabledPrint={disableIn || !record.old}
                deleteDisabled={disableDichVu}
                tooltipInTheoQuyen={!disableIn}
                tooltipXoaTheoQuyen={!disableDichVu}
                style={{ justifyContent: 'center' }}
              />
            );
          }
        }
      },
    },
  ];


  const daKham = (trang_thai) => {
    return trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG || trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH;
  };
  const dangKham = (trang_thai) => {
    return trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG || trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH || trang_thai === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM;
  };

  // Hanlde change quantity
  const handleSoLuong = (value, record) => {
    const index = dataSource.findIndex((item) => item._id === record._id);
    const newObj = {
      ...record,
      SO_LUONG: value,
    }
    setTimeout(() => {
      setDataSource((currentArr) => {
        currentArr[index] = newObj;
        return currentArr;
      });
      forceRender[1](rid());
    }, 300)
  };

  //
  const handleSelectPhong = (record, dichvu) => {
    const index = dataSource.findIndex((item) => item._id === dichvu._id);

    let newService = {
      ...dichvu,
      PHONG_THUC_HIEN: record,
      BAC_SI: todayDoctors?.find(i => i.PHONG_ID === record.ID)
    }
    setTimeout(() => {
      setDataSource((currentArr) => {
        currentArr[index] = newService;
        return currentArr;
      });
      forceRender[1](rid());
    }, 300)

  };

  //
  const handleSelectNoiThXn = (record, dichvu) => {

    const index = dataSource.findIndex((item) => item._id === dichvu._id);

    let newService = {
      ...dichvu,
      NOI_THUC_HIEN_XN: record,
    }
    setTimeout(() => {
      setDataSource((currentArr) => {
        currentArr[index] = newService;
        return currentArr;
      });
      forceRender[1](rid());
    }, 300)
  };


  // Hanlde change target
  const handleChangeTarget = (value, record, disable, changeAll = false) => {
    if (changeAll) {
      setDataSource(dataSource.map(dv => ({ ...dv, DM_DOI_TUONG_ID: dv.THANH_TOAN ? dv.DM_DOI_TUONG_ID : value })))
      syncTargetRef.current?.setVisible(false)
    } else {
      if (disable) {
        const indexKhB = dataSource.findIndex((item) => item._id === record._id);
        const newObj = { ...record, DM_DOI_TUONG_ID: value }
        if (indexKhB >= 0) {
          setDataSource((currentArr) => {
            let newArr = [...currentArr]
            newArr[indexKhB] = newObj;
            return newArr;
          })
        }
        forceRender[1](rid());
      } else {
        syncTargetRef.current?.open({ value, record })
      }
    }
  };


  //
  const handleNguoiTao = (value, record) => {
    const index = dataSource.findIndex((item) => item._id === record._id);
    const newObj = {
      ...record,
      TIEP_TAN_ID: value,
    }
    setTimeout(() => {
      setDataSource((currentArr) => {
        currentArr[index] = newObj;
        return currentArr;
      });
      forceRender[1](rid());
    }, 300)
  };


  const tableWidth = isCreateNew
    ? columns.filter(i => !i.hasOwnProperty('showAtNewScreen')).reduce((sum, item) => sum + item.width, 0)
    : columns.reduce((sum, item) => sum + item.width, 0);


  const handleDelete = (record) => {
    if (record.children) {
      let arr_id = record.children.map(i => i._id);
      setDataSource((currentArr) => currentArr.filter((item) => !arr_id.includes(item._id)));
    } else {
      setDataSource((currentArr) => currentArr.filter((item) => item._id !== record._id));
    }
    setBundleReceipts(bundleReceipts.filter((item) => item._id !== record._id));
  };


  const Summary = (propSummary) => {
    const total = dataSource.reduce((sum, item) => sum + +(!item.DV_KEM_THEO ? item.DON_GIA_PHAN_BO * item.SO_LUONG : 0), 0);
    const count = dataSource.reduce((sum, item) => sum + +(!item.DV_KEM_THEO ? item.SO_LUONG : 0), 0);
    const renderTitle = (title) => {
      switch (title) {
        case 'DON_GIA_PHAN_BO':
          return <span className="blue-txt">{i18n.t(languageKeys.tong) + ": "}</span>;
        case 'SO_LUONG':
          return <span className="blue-txt" style={{ marginLeft: 10 }}>{count}</span>
        case 'THANH_TIEN_PHAN_BO':
          return <span className="blue-txt">{formatNumberToPrice(+(total))}</span>
        default:
          return <></>;
      }
    }
    return (
      <Table.Summary.Row>
        {columns.map((col, index) => (
          <Table.Summary.Cell key={col.dataIndex || rid()} index={index} colSpan={1}>
            {renderTitle(col.dataIndex)}
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    )
  }


  const renderExpandIcon = ({ expanded, onExpand, record }) => {
    if (expanded && (record.DV_KEM_THEO || record.children)) {
      return (
        <CaretDownOutlined
          className={cn(style["icon"], "ant-table-row-expand-icon ant-table-row-expand-icon-expanded")}
        />
      );
    }
    if (!expanded && (record.DV_KEM_THEO || record.children)) {
      return (
        <CaretRightOutlined
          className={cn(style["icon"], "ant-table-row-expand-icon ant-table-row-expand-icon-expanded")}
        />
      );
    }
  };

  const checkDuplicateService = (listDv) => {
    try {
      const getFlat = () => {
        let rs = [];
        for (const item of listDv) {
          rs.push(item);
          if (item.children?.[0]) {
            let childrens = item.children.map((i) => ({ ...i, DV_ID: i.DV_KEM_THEO_ID }));
            rs.push(...childrens);
          }
        }

        return rs;
      };
      let cp_list = getFlat();

      let check1 = hasDuplicateValues(cp_list, "DV_ID");
      return check1.duplicatedItems;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  const arrCheck = useMemo(() => {
    try {
      return checkDuplicateService([...arrServiceExamTabs, ...dataSource]).map((i) => i?.DV_ID);
    } catch (error) {
      return [];
    }
  }, [arrServiceExamTabs]);

  const renderClassName = (record) => {
    try {
      if (hightlighter) {
        return arrCheck.includes(record.DV_ID) || arrCheck.includes(record.DV_KEM_THEO_ID) ? style["red-row"] : "";
      }
    } catch (error) {
      return "";
    }
  };

  return (
    <div className={style["table-overflow"]}>

      <TableLocal
        columns={isCreateNew ? columns.filter(i => !i.hasOwnProperty('showAtNewScreen')) : columns}
        expandable={{
          expandIcon: renderExpandIcon,
          defaultExpandAllRows: true,
          expandRowByClick: true,
          expandedRowKeys: expandedRow,
          onExpand: (expanded, record) => setExpandedRow(!expanded ? expandedRow.filter(i => i !== record._id) : [...expandedRow, record._id])
        }}
        className={style['table-add']}
        rowKey={"_id"}
        classRow
        classnameRow={renderClassName}
        scroll={{ x: tableWidth, y: 'calc(100vh - 461px)' }}
        dataSource={enhanceDataSource(dataSource)}
        rowPropsConfig={(record) => ({
          disabled: record.IS_USE || record.THANH_TOAN,
        })}
        summary={
          (pageData) => (dataSource.length ? <Table.Summary fixed>
            <Summary pageData={pageData} />
          </Table.Summary> : <></>)
        }
      />

      <DrawerHuyPhieu
        ref={cancelRef}
        renderExpandIcon={renderExpandIcon}
        setLichKhamInfo={setLichKhamInfo}
        setDS_DV_GOI={setDataSource}
        setBundleReceipts={setBundleReceipts}
      />

    </div>
  );
}

export default memo(TableGoiDichVu);


// Render select phòng thực hiện
// const PhongThucHien = ({ dichvu = {}, phong = {}, onSelectPhong = () => {}, setBacSi = () => {}, readOnly = false, scheduleInfo }) => {
//   const { dsPhong, layDsPhongTheoDichVu, loadingDsPhong, layBacSiThucHienHomNay, layDsPhongTheoDichVuLocal } = usePhong();
//   const [visible, setVisible] = useState(false);

//   useEffect(() => {
//     // layDsPhongTheoDichVu(dichvu, handleClickPhong);
//     layDsPhongTheoDichVuLocal(dichvu)
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dichvu._id]);

//   // * Auto chọn phòng đầu tiên đang hoạt động, nếu không có thì không chọn
//   useEffect(() => {
//     if (!dichvu.PHONG_ID) {
//       for (let i = 0; i < dsPhong.length; i++) {
//         const element = dsPhong[i];
//         // if (isRoomWorking(element)) {
//         //   handleClickPhong(element);
//         //   return;
//         // }
//         handleClickPhong(element);
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dsPhong]);

//   const columns = [
//     {
//       title: i18n.t(languageKeys.field_Ten_phong),
//       dataIndex: "TEN_KHOA_PHONG",
//     },
//     {
//       title: i18n.t(languageKeys.field_So_phong),
//       dataIndex: "MA_PHONG",
//     },
//     // {
//     //   title: i18n.t(languageKeys.field_Benh_nhan_cho_kham),
//     //   dataIndex: "SO_CHO_KHAM",
//     //   render: (data, record) => data + "/" + record.TONG_SO_BENH_NHAN,
//     // },
//     {
//       title: i18n.t(languageKeys.label_Thoi_gian),
//       render: (_, record) => `${!!record?.GIO_BAT_DAU ? record?.GIO_BAT_DAU + " " : ""}-${!!record?.GIO_KET_THUC ? " " + record?.GIO_KET_THUC : ""}`,
//     },
//     {
//       title: i18n.t(languageKeys.field_Trang_thai),
//       render: (_, record) => {
//         if (!isRoomWorking(record)) {
//           return <div className="red-txt">{i18n.t(languageKeys.da_nghi)}</div>;
//         }

//         return <div className="green-txt">{i18n.t(languageKeys.dang_hoat_dong)}</div>;
//       },
//     },
//   ];

//   const handleClickPhong = (record) => {
//     // if (!isRoomWorking(record)) return console.log("[WARNING] this room is not working now!", record);
//     onSelectPhong(record, dichvu);
//     setVisible(false);
//     // handleLayBacSi(record);
//   };

//   /**
//    *
//    * @param {*} readOnlyProps trạng thái từ comp cha
//    * @param {*} appointment cuộc hẹn được chọn
//    * @param {*} dichvu dịch vụ được chọn trên bảng
//    * @returns  kiểm tra khả năng thực hiện chuyển phòng
//    */
//   const checkStatus = (readOnlyProps, appointment, dichvu) => {
//     //HLog(" kiểm tra khả năng dổi phòng , lượt tiếp đón :::",appointment,dichvu)
//     let check = readOnlyProps;
//     if (!appointment || !(dichvu && dichvu.ID)) return check; // kiểm tra điều kiện chọn phòng nếu là tạo mới.

//     // kiểm tra chọn phòng điều kiện trạng thái dịch vụ.
//     if (!!dichvu && dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.CHO_KHAM) return check;

//     // kiểm tra chọn phòng điều kiện trạng thái cuộc hẹn.
//     if (
//       appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
//       appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
//       appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH
//     ) {
//       check = true;
//     }
//     return check;
//   };
//   const renderTable = () => {
//     return (
//       <div style={{ backgroundColor: "white", boxShadow: "0 0 10px #00000015" }}>
//         <Table
//           dataSource={dsPhong}
//           loading={loadingDsPhong}
//           columns={columns}
//           rowKey="ID"
//           pagination={false}
//           onRow={(data) => ({
//             onClick: () => handleClickPhong(data)
//           })}
//         />
//       </div>
//     );
//   };

//   if (checkStatus(readOnly, scheduleInfo, dichvu)) {
//     return (
//       <Input
//         readOnly
//         value={phong?.TEN_KHOA_PHONG}
//         size="small"
//         style={{ height: "32px" }}
//       />
//     );
//   }

//   return (
//     <Dropdown overlay={renderTable()} trigger="click" visible={visible} onVisibleChange={(bool) => setVisible(bool)}>
//       <Input
//         readOnly
//         suffix={<DownOutlined style={{ color: "#999" }} />}
//         value={phong?.TEN_KHOA_PHONG}
//         size="small"
//         style={!isRoomWorking(phong) ? { borderColor: "red", height: "32px" } : { height: "32px" }}
//       />
//     </Dropdown>
//   );
// };


const NoiThucHienXn = ({ dichvu = {}, noiThucHienXn = {}, listNoiThucHienXn = [], handleSelectNoiThXn = () => {}, readOnly = false, scheduleInfo }) => {
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      title: i18n.t(languageKeys.noi_thuc_hien),
      dataIndex: "TEN_NOI_TH",
    },
  ];

  const checkStatus = (readOnlyProps, appointment, dichvu) => {

    let check = readOnlyProps;
    if (!appointment || !(dichvu && dichvu?.old)) return check;


    if (!!dichvu && dichvu.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.CHO_KHAM) return check;


    if (
      appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
      appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
      appointment.TRANG_THAI_HIEN_TAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH
    ) {
      check = true;
    }
    return check;
  };

  const handleClick = (record) => {
    handleSelectNoiThXn(record, dichvu);
    setVisible(false);
  };

  const renderTable = () => {
    return (
      <div style={{ backgroundColor: "white", boxShadow: "0 0 10px #00000015" }}>
        <Table
          showHeader={false}
          dataSource={listNoiThucHienXn}
          columns={columns}
          rowKey="DV_XN_NOI_THUC_HIEN_ID"
          pagination={false}
          onRow={(data) => ({
            onClick: () => {
              handleClick(data, dichvu)
            },
          })}
        />
      </div>
    );
  };

  if (checkStatus(readOnly, scheduleInfo, dichvu)) {
    return <Input readOnly value={noiThucHienXn?.TEN_NOI_TH} size="small" style={{ height: "32px" }} />
  }

  return (
    <Dropdown overlay={renderTable()} trigger="click" visible={visible} onVisibleChange={(bool) => setVisible(bool)}>
      <Input
        readOnly
        suffix={<DownOutlined style={{ color: "#999" }} />}
        value={noiThucHienXn?.TEN_NOI_TH}
        size="small"
        style={{ height: "32px" }}
      />
    </Dropdown>
  );
};

