import { calcTotal } from "helpers"
import { formatRateNumber } from "./helpers"
import { useDispatch, useSelector } from "react-redux";
import { getServicePackages, setDataSourceTableService } from "ducks/slices/GoiDichVu/DSGoiDichVu/DSGoiDichVuSlice";
import moment from "moment";

export const usePacketService = ({ syncTargetRef }) => {

    const dispatch = useDispatch()
    const { dataSourceTableService: dataSource } = useSelector(getServicePackages);
    const userProfile = useSelector((state) => state.auth.user);

    const setDataSource = (data) => {
        dispatch(setDataSourceTableService(data))
    }


    const getNewRateArr = (arr = []) => {
        if (arr[0]) {
            let newData = arr.map(item => ({
                ...item,
                TY_LE: formatRateNumber(item.TONG_TIEN / calcTotal(arr, "TONG_TIEN") * 100),
                DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
                    ...dvkt,
                    TY_LE: formatRateNumber(dvkt.TONG_TIEN / calcTotal(arr, "TONG_TIEN") * 100),
                }))
            }))
            const currRate = calcTotal(newData, "TY_LE")
            if (currRate !== 100) {
                newData[0] = {
                    ...newData[0],
                    TY_LE: (Number(newData[0].TY_LE) + 100 - currRate).toFixed(2),
                }
            }
            return newData;
        } else {
            return arr;
        }
    }


    const onTreeSelect = (data, setExpandedKeys = () => {}) => {
        if (data.disabled) {
            return;
        }
        if (data.selectable) {
            //
            if (data.checked) {
                setDataSource(() => {
                    let newArr = dataSource.filter((item) => item.DV_ID !== data.value.DV_ID)
                    return getNewRateArr(newArr)
                })
            }
            else {
                const GIA_DT = data.value?.LIST_GIA_THEO_DICH_VU?.[0] || {}
                //
                // Check chọn dv trùng dv có trong combo
                if (dataSource.some(s => s.DV_KEM_THEO?.some(i => i.DV_KEM_THEO_ID === data.value.DV_ID))) {
                    return;
                }
                // Check chọn combo có dv trùng
                let newArr = dataSource.filter(s => !data.value?.DV_KEM_THEO?.some(i => i.DV_KEM_THEO_ID === s.DV_ID))
                //
                newArr = [
                    ...newArr,
                    {
                        ...data.value,
                        //
                        SO_LUONG: 1,
                        LOAI_GIA: GIA_DT.DM_DOI_TUONG_ID,
                        DON_GIA: GIA_DT.GIA_DICH_VU,
                        THANH_TIEN: GIA_DT.GIA_DICH_VU,
                        DON_GIA_PHAN_BO: GIA_DT.GIA_DICH_VU,
                        THANH_TIEN_PHAN_BO: GIA_DT.GIA_DICH_VU,
                        MIEN_GIAM: 0,
                        MIEN_GIAM_PHAN_TRAM: null,
                        TONG_TIEN: data.value.GIA_DICH_VU,
                        TY_LE: "0.0",
                        PHONG_THUC_HIEN_ID: data.value.LIST_PHONG_ID?.[0],
                        //
                        HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
                        NGAY_TAO: moment().format('YYYYMMDD'),
                        GIO_TAO: moment().format('H:mm'),
                        //
                        DV_KEM_THEO: data.value?.DV_KEM_THEO?.map(dvkt => ({
                            ...dvkt,
                            SO_LUONG: 1,
                            LOAI_GIA: dvkt?.LIST_GIA_THEO_DICH_VU?.[0]?.DM_DOI_TUONG_ID,
                            DON_GIA: dvkt?.LIST_GIA_THEO_DICH_VU?.[0]?.GIA_DICH_VU,
                            THANH_TIEN: dvkt?.LIST_GIA_THEO_DICH_VU?.[0]?.GIA_DICH_VU,
                            DON_GIA_PHAN_BO: dvkt?.LIST_GIA_THEO_DICH_VU?.[0]?.GIA_DICH_VU,
                            THANH_TIEN_PHAN_BO: dvkt?.LIST_GIA_THEO_DICH_VU?.[0]?.GIA_DICH_VU,
                            MIEN_GIAM: 0,
                            MIEN_GIAM_PHAN_TRAM: null,
                            TONG_TIEN: dvkt?.LIST_GIA_THEO_DICH_VU?.[0]?.GIA_DICH_VU,
                            TY_LE: "0.0",
                        })),
                        //
                        IS_CAP_NHAT: false,
                    },
                ]
                // Tính lại tỷ lệ
                newArr = getNewRateArr(newArr)
                // Set new datasource
                setDataSource(newArr);
            }
        } else {
            if (data.expanded) {
                setExpandedKeys((currentArray) => currentArray.filter((item) => item !== data.key));
            } else {
                setExpandedKeys((currentArr) => [...currentArr, data.key]);
            }
        }
    }


    const handleChangeTarget = (value, record, disable, changeAll) => {
        //
        const GIA_DT = (obj) => {
            const data = obj.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === value)
            return data
                ? data.GIA_DICH_VU
                : obj.LIST_GIA_THEO_DICH_VU?.find(i => !!i.DOI_TUONG_MAC_DINH)?.GIA_DICH_VU
        }
        const THANH_TIEN = (obj) => GIA_DT(obj)
            ? GIA_DT(obj) * obj.SO_LUONG
            : obj.THANH_TIEN
        //
        if (changeAll) {
            setDataSource(
                getNewRateArr(
                    dataSource.map(dv => ({
                        ...dv,
                        DON_GIA: GIA_DT(dv) ?? (dv.GIA_DICH_VU || dv.DON_GIA),
                        THANH_TIEN: THANH_TIEN(dv),
                        DON_GIA_PHAN_BO: GIA_DT(dv) ?? (dv.GIA_DICH_VU || dv.DON_GIA),
                        THANH_TIEN_PHAN_BO: THANH_TIEN(dv),
                        MIEN_GIAM: 0,
                        MIEN_GIAM_PHAN_TRAM: null,
                        TONG_TIEN: THANH_TIEN(dv),
                        LOAI_GIA: value,
                        IS_CAP_NHAT: true
                    }))
                )
            )
            syncTargetRef.current?.setVisible(false)
        } else {
            if (disable) {
                setDataSource(
                    getNewRateArr(
                        dataSource.map(item =>
                            item.DV_ID === record.DV_ID
                                ? {
                                    ...item,
                                    DON_GIA: GIA_DT(item) ?? (item.GIA_DICH_VU || item.DON_GIA),
                                    THANH_TIEN: THANH_TIEN(item),
                                    DON_GIA_PHAN_BO: GIA_DT(item) ?? (item.GIA_DICH_VU || item.DON_GIA),
                                    THANH_TIEN_PHAN_BO: THANH_TIEN(item),
                                    MIEN_GIAM: 0,
                                    MIEN_GIAM_PHAN_TRAM: null,
                                    TONG_TIEN: THANH_TIEN(item),
                                    LOAI_GIA: value,
                                    IS_CAP_NHAT: true
                                }
                                : item
                        )
                    )
                )
            } else {
                syncTargetRef.current?.open({ value, record })
            }
        }
    };


    const handleSelectRoom = (data, record) => {
        setDataSource(dataSource.map((item) => {
            if (item.DV_ID === record.DV_ID) {
                if (!record.DV_KEM_THEO_ID) {
                    return {
                        ...item,
                        PHONG_THUC_HIEN_ID: data.ID,
                        IS_CAP_NHAT: true
                    }
                } else {
                    return {
                        ...item,
                        DV_KEM_THEO: item.DV_KEM_THEO.map(dvkt =>
                            dvkt.DV_KEM_THEO_ID === record.DV_KEM_THEO_ID
                                ? { ...dvkt, PHONG_THUC_HIEN_ID: data.ID, IS_CAP_NHAT: true }
                                : dvkt
                        )
                    }
                }
            } else { return item; }
        }))
    }


    const handleAutoCalc = (value, keyVal, record) => {
        //
        setDataSource(() => {
            let newArr = []
            newArr = dataSource.map((item) => {
                //
                if (item.DV_ID === record.DV_ID) {
                    //
                    if (keyVal === "SO_LUONG") {
                        //
                        const calcEx = (i) => i.MIEN_GIAM_PHAN_TRAM
                            ? value * i.DON_GIA_PHAN_BO / 100 * i.MIEN_GIAM_PHAN_TRAM
                            : i.MIEN_GIAM
                        //
                        return {
                            ...item,
                            SO_LUONG: value,
                            THANH_TIEN: value * item.DON_GIA,
                            THANH_TIEN_PHAN_BO: value * item.DON_GIA_PHAN_BO,
                            TONG_TIEN: value * item.DON_GIA_PHAN_BO - calcEx(item),
                            DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
                                ...dvkt,
                                SO_LUONG: value,
                                THANH_TIEN_PHAN_BO: value * dvkt.DON_GIA_PHAN_BO,
                                THANH_TIEN: value * dvkt.DON_GIA - calcEx(dvkt),
                            }))
                        }
                    }
                    //
                    if (keyVal === "DON_GIA_PHAN_BO") {
                        //
                        const calcEx = (i) => i.MIEN_GIAM_PHAN_TRAM
                            ? value * item.SO_LUONG / 100 * i.MIEN_GIAM_PHAN_TRAM
                            : i.MIEN_GIAM
                        //
                        if (record.DV_KEM_THEO?.[0]) {
                            return {
                                ...item,
                                DON_GIA_PHAN_BO: value,
                                THANH_TIEN_PHAN_BO: value * item.SO_LUONG,
                                TONG_TIEN: value * item.SO_LUONG - calcEx(item),
                                DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
                                    ...dvkt,
                                    DON_GIA_PHAN_BO: value / item.DV_KEM_THEO.length,
                                    THANH_TIEN_PHAN_BO: value / item.DV_KEM_THEO.length * item.SO_LUONG,
                                    TONG_TIEN: value / item.DV_KEM_THEO.length * item.SO_LUONG - calcEx(dvkt),
                                }))
                            }
                        }
                        //
                        if (!record.DV_KEM_THEO_ID) {
                            return {
                                ...item,
                                DON_GIA_PHAN_BO: value,
                                THANH_TIEN_PHAN_BO: value * item.SO_LUONG,
                                TONG_TIEN: value * item.SO_LUONG - calcEx(item),
                            }
                            //
                        } else {
                            let newDvkt = item.DV_KEM_THEO?.map(dvkt =>
                                dvkt.DV_KEM_THEO_ID === record.DV_KEM_THEO_ID
                                    ? {
                                        ...dvkt,
                                        DON_GIA_PHAN_BO: value,
                                        THANH_TIEN_PHAN_BO: value * dvkt.SO_LUONG,
                                        TONG_TIEN: value * dvkt.SO_LUONG - calcEx(dvkt),
                                    }
                                    : dvkt
                            )
                            return {
                                ...item,
                                DON_GIA_PHAN_BO: calcTotal(newDvkt, "DON_GIA_PHAN_BO"),
                                THANH_TIEN_PHAN_BO: calcTotal(newDvkt, "THANH_TIEN_PHAN_BO"),
                                TONG_TIEN: calcTotal(newDvkt, "TONG_TIEN"),
                                DV_KEM_THEO: newDvkt
                            }
                        }
                    }
                    //
                    if (keyVal === "MIEN_GIAM") {
                        //
                        const calcEX = value > 100 ? value : (record.THANH_TIEN_PHAN_BO / 100 * value)
                        //
                        if (record.DV_KEM_THEO?.[0]) {
                            return {
                                ...item,
                                MIEN_GIAM: calcEX,
                                MIEN_GIAM_PHAN_TRAM: value > 100 ? null : value,
                                TONG_TIEN: item.THANH_TIEN_PHAN_BO - calcEX,
                                DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
                                    ...dvkt,
                                    TONG_TIEN: dvkt.THANH_TIEN_PHAN_BO - calcEX / item.DV_KEM_THEO.length,
                                }))
                            }
                        }
                        //
                        if (!record.DV_KEM_THEO_ID) {
                            return {
                                ...item,
                                MIEN_GIAM: calcEX,
                                MIEN_GIAM_PHAN_TRAM: value > 100 ? null : value,
                                TONG_TIEN: item.THANH_TIEN_PHAN_BO - calcEX,
                            }
                            //
                        } else {
                            let newDvkt = item.DV_KEM_THEO?.map(dvkt =>
                                dvkt.DV_KEM_THEO_ID === record.DV_KEM_THEO_ID
                                    ? {
                                        ...dvkt,
                                        MIEN_GIAM: calcEX,
                                        MIEN_GIAM_PHAN_TRAM: value > 100 ? null : value,
                                        TONG_TIEN: dvkt.THANH_TIEN_PHAN_BO - calcEX,
                                    }
                                    : dvkt
                            )
                            return {
                                ...item,
                                MIEN_GIAM: calcTotal(newDvkt, "MIEN_GIAM"),
                                MIEN_GIAM_PHAN_TRAM: null,
                                TONG_TIEN: calcTotal(newDvkt, "TONG_TIEN"),
                                DV_KEM_THEO: newDvkt
                            }
                        }
                    }
                    //
                    return { ...item, [keyVal]: value }
                } else {
                    return item
                }
            })
            //
            newArr = newArr.map(item => ({
                ...item,
                TY_LE: formatRateNumber(item.TONG_TIEN / calcTotal(newArr, "TONG_TIEN") * 100),
                DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
                    ...dvkt,
                    TY_LE: formatRateNumber(dvkt.TONG_TIEN / calcTotal(newArr, "TONG_TIEN") * 100),
                })),
                IS_CAP_NHAT: true,
            }))
            const currRate = calcTotal(newArr, "TY_LE")
            if (currRate !== 100) {
                newArr[0] = {
                    ...newArr[0],
                    TY_LE: (Number(newArr[0].TY_LE) + 100 - currRate).toFixed(2),
                }
            }
            return newArr;
        });
    }


    const handleDelete = (record) => {
        setDataSource(() => {
            const newArr = dataSource.filter((_, index) => record.index !== index)
            return getNewRateArr(newArr)
        })
    }


    return {
        onTreeSelect,
        handleChangeTarget,
        handleSelectRoom,
        handleAutoCalc,
        handleDelete,
    }
}