import { CheckAllModal, FormDrawer, ModalPDF, Select } from "components";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import i18n, { languageKeys } from "../../../../i18n";
import { Button, Col, Collapse, Form, Input, Radio, Row, Space, Spin, Switch, notification, Tabs, InputNumber } from "antd";
import styles from "../vattu.module.less";
import InputSearchPatient from "pages/QuanLyTiepDon/ModalTiepDon/components/InputSearchPatient";
import { CaretRightOutlined, LoadingOutlined, QrcodeOutlined } from "@ant-design/icons";
import { danhSachGioiTinh } from "constants/data";
import { calcTotal, common_post, handleCheckPermissions, locdau } from "helpers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SearchTHX from "pages/QuanLyTiepDon/components/SearchTHX/SearchTHX";
import PhacDoDeXuat from "./PhacDoDeXuat";
import PhacDoKhoiTao from "./PhacDoKhoiTao";
import KeHoachSuDung from "./KeHoachSuDung";
import PhacDoSuDung from "./PhacDoSuDung";
import { getKeHoachSuDung, getLieuTrinhData, getPhacDoDieuTri, resetGoiVatTuSlice, saveKeHoachSuDung, savePhacDoLieuTrinh, setLtrLoading } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { omit, orderBy, pick } from "lodash";
import ModalKichHoat from "pages/QuanLyGoiDichVu/QuanLySuDungGoiDichVu/components/ModalKichHoat";
import { keyFieldGoi, keyFieldKh } from "..";
import { getUser } from "ducks/slices/authSlice";
import { useEventListener } from "hooks";
import { useHistory } from "react-router-dom";
import { apis, paths } from "../../../../constants";
import { featureKeys } from "constants/keys";
import { snapshotActions } from "ducks/slices/snapshotSlice";
import QRInput from "components_v2/QRInput";
import { QRLoadingState } from "ducks/slices/loadingSlice";
import { getServicePacketTypeList } from "ducks/slices/GoiDichVu/LoaiGoiDvSlice";
import { doFetchSourceClient, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import DateFormItem from "components_v2/DateInput/DateFormItem";


const DkyGoiLieuTrinh = forwardRef(({ applyFilter, resetFilter, isFast }, ref) => {

  const dateRef = useRef()
  const activeRef = useRef()
  const pdfRef = useRef()
  const pickDateRef = useRef()

  const history = useHistory();

  const dispatch = useDispatch()
  const userProfile = useSelector(getUser)

  const { tinh, huyen, xa } = useSelector((state) => state.data);
  const { servicePackTypeList } = useSelector((state) => state.servicePacket.ServicePackType);
  const { sourceClient } = useSelector(getSharedData);

  const loadingQR = useSelector(QRLoadingState);
  const { modeQR } = useSelector((state) => state.snapShot)

  const {
    dsDeXuat,
    initID, serviceInitList, supplyInitList,
    planID, planData,
    usageID, serviceUsageList, supplyUsageList,
    loading, doneFetchInit, doneFetchPlan, doneFetchUsage,
    tempCustomerData
  } = useSelector(getLieuTrinhData);

  // State
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("")
  const [active, setActive] = useState(true)
  const [isCreateNew, setIsCreateNew] = useState(true)
  const [bundleData, setBundleData] = useState({})
  const [SBSD, setSBSD] = useState()

  // Form
  const [formKH] = Form.useForm()
  const [formInitBundle] = Form.useForm()
  const [formUsageBundle] = Form.useForm()
  const MA_BENH_NHAN = Form.useWatch("MA_BENH_NHAN", formKH)
  const MA_TINH_THANH = Form.useWatch("MA_TINH_THANH", formKH)
  const MA_QUAN_HUYEN = Form.useWatch("MA_QUAN_HUYEN", formKH)

  // SearchParam
  const query = useMemo(() => new URLSearchParams(window.location.search), [window.location.search])
  const codeURL = query.get("code")

  // Check permission
  const checkRole = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dk_goi_dv);
  }, [userProfile]);
  const checkCanPaid = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dich_vu_can_thanh_toan).THANH_TOAN;
  }, [userProfile]);
  //
  const disableSaveBundle = isCreateNew
    ? !checkRole?.THEM_MOI_LIEU_TRINH
    : (selectedTab === "PHAC_DO_KHOI_TAO" && !!bundleData.IS_ACTIVE)
      ? true
      : (selectedTab === "PHAC_DO_KHOI_TAO" || selectedTab === "PHAC_DO_SU_DUNG")
        ? !checkRole?.SUA_LIEU_TRINH
        : false
  //
  const disableSavePlan = isCreateNew
    ? !checkRole?.THEM_MOI_LIEU_TRINH
    : selectedTab === "KE_HOACH_SD" ? !checkRole?.SUA_KE_HOACH_SD : false


  // Lấy data kh từ tiếp đón khi đk nhanh
  useEffect(() => {
    if (!isFast) {
      codeURL && codeURL !== "GOI_LT"
        ? handleAutoFillCustomer()
        : setTimeout(() => handleSelectCustomer(tempCustomerData, false, false), 200)
    }
    return () => dispatch(resetGoiVatTuSlice())
  }, [])


  useImperativeHandle(ref, () => ({
    open: handleOpen,
    openFast: (code) => {
      handleOpen()
      handleAutoFillCustomer(code)
    },
  }));

  // Run khi mở form
  const handleOpen = (record) => {
    setOpen(true)
    !servicePackTypeList[0] && dispatch(getServicePacketTypeList());
    !sourceClient[0] && dispatch(doFetchSourceClient());
    if (record) {
      setTimeout(() => handleSelectCustomer(record, true), 100)
      setActive(!!record.IS_ACTIVE)
      setIsCreateNew(false)
      setBundleData(record)
    } else {
      setActive(checkRole?.ACTIVE)
      setSelectedTab("PHAC_DO_KHOI_TAO")
    }
  }


  // Run khi đóng form
  const handleClose = () => {
    setOpen(false)
    setSelectedTab("")
    formKH.resetFields()
    formInitBundle.resetFields()
    formUsageBundle.resetFields()
    setIsCreateNew(true)
    setActive(true)
    dispatch(resetGoiVatTuSlice())
    setBundleData({})
    setSBSD()
  }


  // Tự chọn kh
  const handleAutoFillCustomer = async (code) => {
    dispatch(setLtrLoading(true))
    try {
      const resKH = await common_post(apis.tiep_don_ds_benh_nhan, {
        search_string: code ?? codeURL,
        limit: 1,
        from_partner: false,
      })
      if (resKH.status === "OK") {
        resKH.result.length === 1 && handleSelectCustomer(resKH.result[0])
      }
    } catch (e) {
      console.log(e)
    } finally {
      !code && window.history.replaceState(null, "", paths.quan_ly_dk_goi_dv)
      dispatch(setLtrLoading(false))
    }
  }


  // Xử lý chọn kh
  const handleSelectCustomer = (record, autoTab, doFetch = true) => {
    formKH.setFields([
      ...Object.keys(keyFieldKh).map((item) => {
        if (item === "NGAY_SINH") {
          if (record?.NGAY_SINH?.length === 4) {
            dateRef.current?.setDate(["", "", record.NGAY_SINH]);
          } else if (record?.NGAY_SINH?.length === 8) {
            let date = moment(record.NGAY_SINH, "YYYYMMDD").format("DD/MM/YYYY");
            dateRef.current?.setDate(date.split("/"));
          }
          return {
            name: "NGAY_SINH",
            value: moment(record.NGAY_SINH).format(record?.NGAY_SINH?.length === 4 ? "YYYY" : "DDMMYYYY")
          }
        } else {
          return { name: item, value: record[item] }
        }
      })
    ]);
    dispatch(resetGoiVatTuSlice())
    if (doFetch) {
      setTimeout(() => {
        dispatch(getPhacDoDieuTri({
          data: { BENH_NHAN_ID: record.BENH_NHAN_ID, PHAN_LOAI: 'PHAC_DO_DE_XUAT' },
          afterSuccess: (data) => {
            autoTab
              ? handleChangeTab('KE_HOACH_SD', record)
              : data
                ? setSelectedTab('PHAC_DO_DE_XUAT')
                : handleChangeTab('PHAC_DO_KHOI_TAO')
          }
        }))
      }, 100)
    } else {
      window.history.replaceState(null, "", paths.quan_ly_dk_goi_dv)
    }
    formKH.validateFields()
    isCreateNew && formInitBundle.setFields([
      { name: "TEN_GOI_DV", value: record.TEN },
      { name: "LOAI_GOI_DV_ID", value: servicePackTypeList?.filter(i => !i.KHOA)?.[0]?.ID }
    ])
  }


  // Fill dữ liệu gói
  const handleFillBundleInfo = (form, record) => {
    form.setFields([
      ...Object.keys(keyFieldGoi).map((item) => {
        if (item === "NGAY_HET_HAN") {
          return {
            name: item,
            value: moment(record[item], "YYYYMMDD"),
          }
        } else if (item === "NGAY_HIEU_LUC" || item === "NGAY_TAO") {
          return {
            name: item,
            value: moment(record[item], "YYYYMMDD").format("DD/MM/YYYY"),
          }
        } else {
          return { name: item, value: record[item] }
        }
      })
    ]);
    setSBSD(record.SO_BUOI_SU_DUNG)
  }


  // Xử lý chọn đổi tab
  const handleChangeTab = (key, data = bundleData) => {
    setSelectedTab(key)
    if (key === "PHAC_DO_KHOI_TAO" && !doneFetchInit && !isCreateNew) {
      dispatch(getPhacDoDieuTri({
        data: {
          BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID"),
          PHAN_LOAI: 'PHAC_DO_KHOI_TAO',
          GOI_DV_ID: data.GOI_DV_ID,
        },
        afterSuccess: (record) => handleFillBundleInfo(formInitBundle, record)
      }))
    }
    if (key === "KE_HOACH_SD" && !doneFetchPlan) {
      dispatch(getKeHoachSuDung({
        payload: {
          BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID"),
          GOI_DV_ID: data.GOI_DV_ID,
          RLS_BENHNHAN_DANGKY_GOI_ID: data.ID,
        },
        afterSuccess: (record) => handleFillBundleInfo(!!data.IS_ACTIVE ? formUsageBundle : formInitBundle, record),
        PHAN_LOAI: !!data.IS_ACTIVE ? 'PHAC_DO_SU_DUNG' : 'PHAC_DO_KHOI_TAO',
      }))
    }
    if (key === "PHAC_DO_SU_DUNG" && !doneFetchUsage) {
      dispatch(getPhacDoDieuTri({
        data: {
          BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID"),
          PHAN_LOAI: 'PHAC_DO_SU_DUNG',
          GOI_DV_ID: data.GOI_DV_ID,
        },
        afterSuccess: (record) => handleFillBundleInfo(formUsageBundle, record)
      }))
    }
  }


  // Kiểm tra dữ liệu trước khi lưu
  const handleValidate = async (print, paid) => {
    await formKH.validateFields()
    //
    if (selectedTab === "PHAC_DO_KHOI_TAO") {
      await formInitBundle.validateFields()
      if (isCreateNew || !bundleData.IS_ACTIVE) {
        if (!serviceInitList[0]) {
          return notification.warning({
            message: i18n.t(languageKeys.noti_Chua_chon_dich_vu_kham_benh_hoac_cdcls),
            placement: 'bottomLeft'
          })
        } else {
          if (active && !bundleData.IS_ACTIVE) {
            activeRef.current?.open({ ...formInitBundle.getFieldValue(), print, paid }, true)
          } else {
            handleSubmit(formInitBundle.getFieldValue(), print, paid)
          }
        }
      }
    }
    if (selectedTab === "KE_HOACH_SD") {
      if (planData.some(i => !i.NGAY_DAT_HEN || !i.DANH_SACH_DICH_VU[0])) {
        return notification.warning({
          message: i18n.t(languageKeys.noti_vui_long_nhap_day_du_cac_truong_bat_buoc),
          placement: 'bottomLeft'
        })
      } else {
        handleSubmit({}, print, paid)
      }
    }
    if (selectedTab === "PHAC_DO_SU_DUNG") {
      if (!serviceUsageList[0]) {
        return notification.warning({
          message: i18n.t(languageKeys.noti_Chua_chon_dich_vu_kham_benh_hoac_cdcls),
          placement: 'bottomLeft'
        })
      } else {
        handleSubmit(formUsageBundle.getFieldValue(), print, paid)
      }
    }
  }


  // LƯU
  const handleSubmit = (bundleInfo, print, paid) => {

    // Tab kế hoạch sd
    if (selectedTab === "KE_HOACH_SD") {
      dispatch(saveKeHoachSuDung({
        data: {
          BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID"),
          GOI_DV_ID: bundleData.GOI_DV_ID,
          KE_HOACH: planData.map((item, index) => ({
            KE_HOACH_NGAY: `NGAY_${index + 1}`,
            DS_DV_GOI: item.DANH_SACH_DICH_VU.map(dv => ({
              //
              DICH_VU_ID: dv.DV_ID || dv.DICH_VU_ID,
              SO_LUONG: dv.SO_LUONG,
              DON_GIA: dv.DON_GIA,
              DON_GIA_PHAN_BO: dv.DON_GIA_PHAN_BO,
              PHAN_LOAI: dv.PHAN_LOAI,
              PHONG_THUC_HIEN_ID: dv.PHONG_THUC_HIEN_ID,
              PDT_ID: dv.PDT_ID,
              DICH_VU_GOC: dv.DICH_VU_GOC,
              //
              RLS_BN_GOI_DV_ID: dv.RLS_BN_GOI_DV_ID,
              RLS_BN_GOI_ID: dv.RLS_BN_GOI_ID,
              RLS_BN_GOI_DV_DVCON_ID: dv.RLS_BN_GOI_DV_DVCON_ID,
              GOI_ID: bundleInfo.ID,
              TIEP_TAN_ID: userProfile.NHANSU_ID,
              //
              NGAY: item.NGAY_DAT_HEN,
              GIO_BAT_DAU: item.GIO_DAT_HEN?.[0] ? moment(item.GIO_DAT_HEN[0]).format("HH:mm") : null,
              GIO_KET_THUC: item.GIO_DAT_HEN?.[1] ? moment(item.GIO_DAT_HEN[1]).format("HH:mm") : null,
              DM_DOI_TUONG_ID: item.DM_DOI_TUONG_ID,
              //
              // MIEN_GIAM: dv.MIEN_GIAM,
              // MIEN_GIAM_PHAN_TRAM: dv.MIEN_GIAM_PHAN_TRAM,
              // TONG_TIEN: dv.TONG_TIEN,
            })),
            DANH_SACH_VAT_TU: item.DANH_SACH_VAT_TU.filter(i => i.SO_LUONG),
            LICH_KHAM_ID: item.LICH_KHAM_ID,
            GHI_CHU: item.GHI_CHU,
          }))
        },
        afterSuccess: async () => {
          print && handlePrintPlan({ GOI_DV_ID: bundleData.GOI_DV_ID, BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID") })
          planID ? applyFilter() : resetFilter()
          handleClose()
        },
        isNew: !planID
      }))
    }

    // Tab phác đồ khởi tạo + sd
    else {
      //
      let ID = !!bundleData.IS_ACTIVE ? usageID : initID
      let serviceList = !!bundleData.IS_ACTIVE ? serviceUsageList : serviceInitList
      let supplyList = !!bundleData.IS_ACTIVE ? supplyUsageList : supplyInitList
      //
      dispatch(savePhacDoLieuTrinh({
        data: {
          // ID,
          GOI_DV_ID: bundleData.GOI_DV_ID,
          PHAC_DO_ID: usageID,
          BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID"),
          PHAN_LOAI: selectedTab,
          TONG_THANH_TIEN: calcTotal(serviceList, "THANH_TIEN"),
          TONG_TIEN_PHAN_BO: calcTotal(serviceList, "THANH_TIEN_PHAN_BO"),
          THUE_VAT: 0,
          TONG_TIEN: calcTotal(serviceList, "TONG_TIEN"),
          SO_BUOI_SU_DUNG: bundleInfo.SO_BUOI_SU_DUNG,
          GHI_CHU: bundleInfo.GHI_CHU_GOI,
          //
          DANH_SACH_DICH_VU: serviceList.map(item => ({
            ...pick(item, [
              "ID",
              "DICH_VU_ID",
              "DON_GIA_PHAN_BO",
              "PHONG_THUC_HIEN_ID",
              "SO_LUONG",
              "THANH_TIEN_PHAN_BO",
              "TY_LE",
              "DON_GIA",
              "THANH_TIEN",
              "BAC_SI_DE_XUAT_ID",
              "PHAN_LOAI",
              // "DM_DOI_TUONG_ID",
              "MIEN_GIAM",
              "MIEN_GIAM_PHAN_TRAM",
              "TONG_TIEN",
            ]),
            MA_DV: item.MA_DV || item.MA_DICHVU,
            TEN_DV: item.TEN_DV || item.TEN_DICHVU,
            DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
              ...dvkt,
              MA_DV: dvkt.MA_DV || dvkt.MA_DICHVU,
              DV_ID: dvkt.DV_ID || dvkt.DICH_VU_GOC,
              DV_KEM_THEO_ID: dvkt.DV_KEM_THEO_ID || dvkt.DICH_VU_ID,
              TEN_DV: dvkt.TEN_DV || dvkt.TEN_DICH_VU_KEM_THEO || dvkt.TEN_DICHVU,
            }))
          })),
          //
          DANH_SACH_VAT_TU: supplyList.filter(i => i.SO_LUONG).map(item => pick(item, [
            "ID",
            // "VAT_TU_TRONG_KHO_ID",
            "THUOC_ID",
            "VAT_TU_ID",
            "SO_LUONG",
            "DON_GIA",
            "SO_LUONG",
            "DUONG_DUNG",
            "HUONG_DAN_SU_DUNG",
            "GHI_CHU",
            "VAT_TU_NGOAI_GOI",
            "TOC_DO_TRUYEN",
          ])),
          THONG_TIN_GOI: {
            ...omit(bundleInfo, ["SO_BUOI_SU_DUNG", "GHI_CHU_GOI"]),
            GIA_GOI_DV: calcTotal(serviceList, "THANH_TIEN_PHAN_BO"),
            TONG_THANH_TIEN: calcTotal(serviceList, "THANH_TIEN"),
            TONG_TIEN_PHAN_BO: calcTotal(serviceList, "THANH_TIEN_PHAN_BO"),
            TONG_TIEN: calcTotal(serviceList, "THANH_TIEN"),
            NHOM_GOI: "LIEU_TRINH",
            SO_LUONG: 1,
            NGAY_HET_HAN: moment(bundleInfo?.NGAY_HET_HAN).format('YYYYMMDD'),
            NGAY_KET_THUC: moment(bundleInfo?.NGAY_HET_HAN).format('YYYYMMDD'),
            THUE: 0,
            MIEN_GIAM: 0,
            IS_ACTIVE: active,
            NGUON_KHACH_ID: formKH.getFieldValue("NGUON_KHACH_ID"),
          },
        },
        afterSuccess: (newBundle, customerID, customerCode, bundleId) => {
          if (paid) {
            history.push(`${paths.quan_ly_dich_vu_can_thanh_toan}?code=${customerCode || formKH.getFieldValue("MA_BENH_NHAN")}`)
          } else {
            print && pdfRef.current.openModal(
              {
                BENH_NHAN_ID: customerID,
                GOI_DV_ID: bundleId,
                PHAN_LOAI: "PHAC_DO_KHOI_TAO"
              },
              apis.layDataPhacDo
            )
            if (newBundle && isCreateNew) {
              handleOpen(newBundle)
            } else {
              ID ? applyFilter() : resetFilter()
              handleClose()
            }
          }
        },
        isNew: !ID,
        newCustomerData: formKH.getFieldValue(undefined),
        isFast,
      }))
    }
  }


  // Xử lý in
  const handlePrint = () => {
    const payload = {
      GOI_DV_ID: bundleData.GOI_DV_ID,
      BENH_NHAN_ID: formKH.getFieldValue("BENH_NHAN_ID")
    }
    if (selectedTab === "KE_HOACH_SD") {
      handlePrintPlan(payload)
    } else {
      pdfRef.current.openModal({ ...payload, PHAN_LOAI: "PHAC_DO_KHOI_TAO" }, apis.layDataPhacDo)
    }
  }


  // Xử lý in kế hoạch sd
  const handlePrintPlan = async (payload) => {
    notification.open({
      key: "noti_new_tab_key",
      message: i18n.t(languageKeys.noti_Dang_tai_du_lieu),
      placement: "bottomLeft",
      icon: <LoadingOutlined />,
      duration: 0,
    });
    try {
      const res = await common_post(apis.layDataKeHoachSuDung, payload)
      if (res.status === 'OK') {
        pickDateRef.current.open(
          {
            ...(res.data[0] || []),
            so_buoi_su_dung: SBSD || formInitBundle.getFieldValue('SO_BUOI_SU_DUNG'),
          },
          orderBy(res.data[0].ke_hoach_su_dung, ["KE_HOACH_NGAY"])
            .map((i, idx) => ({
              value: i.KE_HOACH_NGAY,
              label: `${i18n.t(languageKeys.data_Ngay)} ${idx + 1}: ${moment(i.NGAY, "YYYYMMDD").format("DD/MM/YYYY")}`
            }))
        )
        notification.destroy("noti_new_tab_key")
      }
    } catch (e) {
      notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra) })
    }
  }


  const renderTabContent = () => {
    switch (selectedTab) {
      case "PHAC_DO_DE_XUAT":
        return <PhacDoDeXuat />
      case "PHAC_DO_KHOI_TAO":
        return <PhacDoKhoiTao form={formInitBundle} readOnly={!!bundleData.IS_ACTIVE || !checkRole?.SUA_LIEU_TRINH} />;
      // case "KE_HOACH_SD":
      //   return <KeHoachSuDung IS_ACTIVE={IS_ACTIVE} />;
      // case "PHAC_DO_SU_DUNG":
      //   return <PhacDoSuDung form={formUsageBundle} />;
      default:
        return <></>;
    }
  }


  const keydownEvent = (event) => {
    if (selectedTab !== "PHAC_DO_DE_XUAT" && MA_BENH_NHAN) {
      const preventDefault = () => {
        event.stopPropagation();
        event.preventDefault();
      }
      if (event.ctrlKey && event.key === "b" && !(disableSaveBundle || disableSavePlan) && checkCanPaid) {
        preventDefault()
        handleValidate(false, true)
      }
      if (event.ctrlKey && event.key === "p" && checkRole.IN_FILE && !isCreateNew) {
        preventDefault()
        handlePrint()
      }
      if (event.ctrlKey && event.key === "l" && !(disableSaveBundle || disableSavePlan || !checkRole.IN_FILE)) {
        preventDefault()
        handleValidate(true)
      }
      if (event.ctrlKey && event.key === "s" && !(disableSaveBundle || disableSavePlan)) {
        preventDefault()
        handleValidate()
      }
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);


  const FormFooter = (
    <Space className={styles["spaceDiv"]}>
      {(selectedTab === "PHAC_DO_KHOI_TAO" || selectedTab === "PHAC_DO_SU_DUNG")
        ? (
          <Space>
            <span>{i18n.t(languageKeys.kich_hoat_goi_dv)}</span>
            <Switch checked={active} onChange={setActive} disabled={!!bundleData.IS_ACTIVE || !checkRole.ACTIVE} />
          </Space>
        )
        : <div />
      }
      <Space>
        <Button type="primary" ghost loading={loading} onClick={handleClose}>
          {i18n.t(languageKeys.common_Thoat)} (ESC)
        </Button>

        {!isCreateNew && selectedTab !== "PHAC_DO_DE_XUAT" && (
          <Button type="primary" loading={loading} onClick={handlePrint} disabled={!checkRole.IN_FILE}>
            {i18n.t(languageKeys.common_In)} (Ctrl+P)
          </Button>
        )}

        {selectedTab !== "PHAC_DO_DE_XUAT" && bundleData.TRANG_THAI_HIEN_TAI !== "HOAN_THANH" && bundleData.TRANG_THAI_HIEN_TAI !== "HET_HAN" && (
          <>
            {!bundleData.THANH_TOAN && selectedTab !== "KE_HOACH_SD" && !(selectedTab === "PHAC_DO_KHOI_TAO" && !!bundleData.IS_ACTIVE) && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => handleValidate(false, true)}
                disabled={disableSaveBundle || disableSavePlan || !checkCanPaid}
              >
                {i18n.t(languageKeys.thanh_toan)} (Ctrl+B)
              </Button>
            )}
            {!(selectedTab === "PHAC_DO_KHOI_TAO" && !!bundleData.IS_ACTIVE) && (
              <>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => handleValidate(true)}
                  disabled={disableSaveBundle || disableSavePlan || !checkRole.IN_FILE}
                >
                  {i18n.t(languageKeys.luu_va_in)} (Ctrl+L)
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => handleValidate()}
                  disabled={disableSaveBundle || disableSavePlan}
                >
                  {i18n.t(languageKeys.common_Luu)} (Ctrl+S)
                </Button>
              </>
            )}
          </>
        )}
      </Space>
    </Space>
  )

  return (
    <FormDrawer
      className={styles["drawer"]}
      open={open}
      width={'calc(100vw - 60px)'}
      title={i18n.t(languageKeys.dky_lieu_trinh)}
      onCancel={handleClose}
      back
      hiddenTopAction={true}
      extra={isCreateNew && (
        <Space>
          <InputSearchPatient isRecep={false} onSelect={handleSelectCustomer} />
          <Button
            icon={<QrcodeOutlined />}
            type="primary"
            onClick={() => {
              dispatch(snapshotActions.setModeQR(true))
              document.getElementById('QR').focus()
            }}
          >QR Code</Button>
        </Space>
      )}
      footer={FormFooter}
    >
      <Spin spinning={loading}>
        <Form form={formKH} layout="vertical">
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            defaultActiveKey={["TTKH", "TTPTU", "LSGD"]}
          >
            <Collapse.Panel
              header={i18n.t(languageKeys.thong_tin_khach_hang)}
              key="TTKH"
              extra={(
                <Space>
                  <span>{i18n.t(languageKeys.ma_kh)}:</span>
                  <Form.Item name={"MA_BENH_NHAN"} style={{ marginBottom: 0 }}>
                    <Input readOnly style={{ background: '#fff' }} />
                  </Form.Item>
                </Space>
              )}
            >
              <div className={styles["wrapper"]}>
                <Spin
                  spinning={loadingQR ? loadingQR : modeQR}
                  indicator={(!loadingQR && modeQR) ? <QrcodeOutlined style={{ fontSize: 24 }} /> : undefined}
                >
                  <Row gutter={[10, 0]}>

                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.sdt)}
                        name={"SO_DIEN_THOAI"}
                        rules={[
                          {
                            required: true,
                            message: i18n.t(languageKeys.vui_long_nhap),
                          },
                          {
                            pattern: /(84|0[3|5|7|8|9])+([0-9]{8,9})\b/,
                            min: 10,
                            max: 11,
                            message: i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase(),
                          },
                        ]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.ho_va_ten)}
                        name={"TEN"}
                        rules={[{
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        }]}
                      >
                        <Input
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                          readOnly={!isCreateNew}
                          onBlur={(e) => {
                            formKH.setFieldValue("TEN", e.target.value.toUpperCase())
                            isCreateNew && formInitBundle.setFields([
                              { name: "TEN_GOI_DV", value: e.target.value.toUpperCase() },
                              { name: "LOAI_GOI_DV_ID", value: servicePackTypeList?.filter(i => !i.KHOA)?.[0]?.ID }
                            ])
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.field_Gioi_tinh)}
                        name={"GIOI_TINH"}
                        rules={[{
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        }]}
                      >
                        <Select
                          dataSource={danhSachGioiTinh}
                          valueKey='key'
                          titleKey='name'
                          readOnly={!isCreateNew}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <DateFormItem
                        label={i18n.t(languageKeys.field_Ngay_sinh)}
                        name="NGAY_SINH"
                        tabIndex={[4, 4, 3]}
                        dateRef={dateRef}
                        form={formKH}
                        readOnly={!isCreateNew}
                      />
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.CCCD_Passport)}
                        name={"CCCD"}
                        rules={[{
                          pattern: /^[0-9]*$/,
                          message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
                        }]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      {/* <Form.Item label={i18n.t(languageKeys.field_Quoc_gia)} name={"MA_QUOC_TICH"}>
                        <Select
                          dataSource={quoc_gia}
                          valueKey='MA_QUOC_GIA'
                          titleKey='TEN_QUOC_GIA'
                          showSearch
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          readOnly={!isCreateNew}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                          onChange={(_, { fullValue }) => formKH.setFieldValue("QUOC_TICH", fullValue.TEN_QUOC_GIA)}
                        />
                      </Form.Item> */}
                      <Form.Item label={i18n.t(languageKeys.nguon_khach)} name={"NGUON_KHACH_ID"}>
                        <Select
                          dataSource={sourceClient}
                          valueKey='ID'
                          titleKey='TEN_NGUON_KHACH'
                          showSearch
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          readOnly={!isCreateNew}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <SearchTHX
                        form={formKH}
                        onSelect={(record) => formKH.setFields([
                          { name: "MA_TINH_THANH", value: record.MA_TINH_THANH },
                          { name: "MA_QUAN_HUYEN", value: record.MA_QUAN_HUYEN },
                          { name: "MA_PHUONG_XA", value: record.MA_PHUONG_XA },
                          { name: "TEN_TINH_THANH", value: record.TEN_TINH_THANH },
                          { name: "TEN_QUAN_HUYEN", value: record.TEN_QUAN_HUYEN },
                          { name: "TEN_PHUONG_XA", value: record.TEN_PHUONG_XA },
                        ])}
                        readOnly={!isCreateNew}
                      />
                    </Col>

                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Tinh_thanh)} name={"MA_TINH_THANH"}>
                        <Select
                          dataSource={tinh}
                          valueKey='MA_TINH_THANH'
                          titleKey='TEN_TINH_THANH'
                          showSearch
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          allowClear
                          onChange={(_, { fullValue }) => {
                            formKH.setFieldValue("TEN_TINH_THANH", fullValue.TEN_TINH_THANH)
                            formKH.resetFields(["MA_PHUONG_XA", "MA_QUAN_HUYEN", "MA_KHU_VUC"])
                          }}
                          readOnly={!isCreateNew}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Quan_huyen)} name={"MA_QUAN_HUYEN"}>
                        <Select
                          dataSource={huyen[MA_TINH_THANH]}
                          showSearch
                          valueKey='MA_QUAN_HUYEN'
                          titleKey='TEN_QUAN_HUYEN'
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          allowClear
                          onChange={(_, { fullValue }) => {
                            formKH.setFieldValue("TEN_QUAN_HUYEN", fullValue.TEN_QUAN_HUYEN)
                            formKH.resetFields(["MA_PHUONG_XA", "MA_KHU_VUC"])
                          }}
                          readOnly={!isCreateNew}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Xa_phuong)} name={"MA_PHUONG_XA"}>
                        <Select
                          dataSource={xa[MA_QUAN_HUYEN]}
                          valueKey='MA_PHUONG_XA'
                          titleKey='TEN_PHUONG_XA'
                          showSearch
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          allowClear
                          readOnly={!isCreateNew}
                          onChange={(_, { fullValue }) => formKH.setFields([
                            { name: "TEN_PHUONG_XA", value: fullValue?.TEN_PHUONG_XA },
                            { name: "MA_KHU_VUC", value: fullValue?.TEN_VIET_TAT },
                          ])}
                          placeholder={i18n.t(languageKeys.common_Chon)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label={i18n.t(languageKeys.field_Dia_chi_chi_tiet)} name={"DIA_CHI_CHI_TIET"}>
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>

                    <QRInput
                      isCreateNew={isCreateNew}
                      open={open}
                      form={formKH}
                      dateRef={dateRef}
                      handleFillForm={handleSelectCustomer}
                      isRedux
                    />

                  </Row>
                </Spin>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Form>

        <Radio.Group
          className={styles['custom_radio']}
          defaultValue={'PHAC_DO_DE_XUAT'}
          optionType="button"
          buttonStyle="solid"
          onChange={({ target: { value } }) => handleChangeTab(value === 'TIEN_TRINH_SD' ? 'KE_HOACH_SD' : value)}
          value={
            selectedTab === 'KE_HOACH_SD' || selectedTab === 'PHAC_DO_SU_DUNG'
              ? 'TIEN_TRINH_SD'
              : selectedTab
          }
        >
          <Radio value={'PHAC_DO_DE_XUAT'} disabled={!dsDeXuat[0] || !MA_BENH_NHAN}>
            {i18n.t(languageKeys.lieu_trinh_duoc_de_xuat)}
          </Radio>
          <Radio value={'PHAC_DO_KHOI_TAO'}>
            {i18n.t(languageKeys.dky_lieu_trinh)}
          </Radio>
          {/* <Radio value={'KE_HOACH_SD'} disabled={!(MA_BENH_NHAN && !isCreateNew)}>
              Kế hoạch sử dụng
            </Radio> */}
          {/* <Radio value={'PHAC_DO_SU_DUNG'} disabled={!(MA_BENH_NHAN && IS_ACTIVE)}>
              Tiến trình sử dụng
            </Radio> */}
          {MA_BENH_NHAN && !isCreateNew && (
            <Radio value={'TIEN_TRINH_SD'} disabled={!(MA_BENH_NHAN && !isCreateNew)}>
              {i18n.t(languageKeys.quan_ly_su_dung)}
            </Radio>
          )}
        </Radio.Group>

        {renderTabContent()}
        {(selectedTab === 'KE_HOACH_SD' || selectedTab === 'PHAC_DO_SU_DUNG') && (
          <Tabs
            activeKey={selectedTab}
            onChange={(value) => handleChangeTab(value)}
            className={styles.tabs}
            tabBarExtraContent={
              <Space>
                <label style={{ color: '#2c3782' }}>{i18n.t(languageKeys.so_buoi_sd)}</label>
                <InputNumber value={SBSD} readOnly style={{ background: '#fff' }} />
              </Space>
            }
          >
            <Tabs.TabPane key={'KE_HOACH_SD'} tab={i18n.t(languageKeys.ke_hoach_sd)} disabled={!(MA_BENH_NHAN && !isCreateNew)}>
              <KeHoachSuDung
                readOnly={!checkRole?.SUA_KE_HOACH_SD}
                allowPrint={checkRole?.IN_FILE}
                bundleName={formInitBundle.getFieldValue("TEN_GOI_DV")}
              />
            </Tabs.TabPane>
            {MA_BENH_NHAN && !!bundleData.IS_ACTIVE && (
              <Tabs.TabPane key={'PHAC_DO_SU_DUNG'} tab={i18n.t(languageKeys.qly_goi_lieu_trinh)} disabled={!(MA_BENH_NHAN && !!bundleData.IS_ACTIVE)}>
                <PhacDoSuDung
                  form={formUsageBundle}
                  readOnly={!checkRole?.SUA_LIEU_TRINH}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
        )}

      </Spin>

      <ModalKichHoat
        ref={activeRef}
        onOk={(data) => handleSubmit(omit(data, ["print", "paid"]), data.print, data.paid)}
        onCancel={() => activeRef?.current.close()}
      />

      <ModalPDF ref={pdfRef} />

      <CheckAllModal
        ref={pickDateRef}
        title={i18n.t(languageKeys.ke_hoach_sd)}
        desc={i18n.t(languageKeys.noti_vui_long_chon_ngay_muon_in_ke_hoach)}
        onOk={(data, list) => {
          pdfRef.current?.openModalWithData(
            {
              ...data,
              ke_hoach_su_dung: data.ke_hoach_su_dung.filter(i => list.includes(i.KE_HOACH_NGAY)),
              template: "keHoachSD"
            },
            "keHoachSD",
          )
          pickDateRef.current.close()
        }}
      />

    </FormDrawer>
  )
})

export default DkyGoiLieuTrinh;