import moment from "moment"

export const NamePhongKhamMat = "mắt"
export const NamePhongTai = "tai"
export const NamePhongTMH = "T-M-H"
export const NamePhongRHM = 'răng'
export const NamePhongNoiTongHop = 'nội tổng hợp'
export const NamePhongNoi = 'khám nội'

export const arrayIDHideTabByID = [NamePhongKhamMat, NamePhongTMH, NamePhongRHM, NamePhongTai] // ẩn: tai mũi họng, khám răng, khám mắt


export const tinhTuoiThai = (date) => {
  // date: dự kiến sinh
  if (date) {
    const tongNgayTuoi = Math.max(0, 279 + moment().diff(date, 'days'))
    const tuanTuoi = Math.floor(tongNgayTuoi / 7)
    const ngayTuoi = tongNgayTuoi % 7
    return `${tuanTuoi} tuần` + (ngayTuoi > 0 ? `, ${ngayTuoi} ngày` : '')
  } else {
    return undefined
  }
}


export const fieldKhamBenh = {
  MACH: "MACH",
  NHIET_DO: "NHIET_DO",
  SP_O2: "SP_O2",
  NHIP_THO: "NHIP_THO",
  TAM_THU: "TAM_THU",
  TAM_TRUONG: "TAM_TRUONG",
  HUYET_AP: "HUYET_AP",
  CAN_NANG: "CAN_NANG",
  CHIEU_CAO: "CHIEU_CAO",
  TEN_DICHVU: "TEN_DICHVU",
  BMI: "BMI",
  VONG_CO: "VONG_CO",
  VONG_BUNG: "VONG_BUNG",
  VONG_HONG_CHAU: "VONG_HONG_CHAU",
  TUOI_MAN_KINH: "TUOI_MAN_KINH",

  // hỏi bệnh
  LY_DO_VAO_VIEN: "LY_DO_VAO_VIEN",
  QUA_TRINH_BENH_LY: "QUA_TRINH_BENH_LY",
  TIEN_SU_BENH: "TIEN_SU_BENH",
  BENH_SU: "BENH_SU",
  KHAM_BENH: "KHAM_BENH",
  TIEN_SU_SAN_KHOA: "TIEN_SU_SAN_KHOA",
  TIEN_SU_GIA_DINH: "TIEN_SU_GIA_DINH",
  TIEN_SU_DI_UNG: "TIEN_SU_DI_UNG",
  TRIEU_CHUNG_LAM_SANG: "TRIEU_CHUNG_LAM_SANG",
  TAI_NAN_THUONG_TICH: "TAI_NAN_THUONG_TICH",
  TIEN_SU_BENH_KHAC: "TIEN_SU_BENH_KHAC",
  CHE_DO_AN_UONG: "CHE_DO_AN_UONG",
  CHE_DO_LUYEN_TAP: "CHE_DO_LUYEN_TAP",

  // khám tổng quát
  TOAN_THAN: "TOAN_THAN",
  CAC_BO_PHAN: "CAC_BO_PHAN",
  TUAN_HOAN: "TUAN_HOAN",
  HO_HAP: "HO_HAP",
  TIEU_HOA: "TIEU_HOA",
  THAN_TIET_NIEU: "THAN_TIET_NIEU",
  NOI_TIET: "NOI_TIET",
  CO_XUONG_KHOP: "CO_XUONG_KHOP",
  THAN_KINH: "THAN_KINH",
  TAM_THAN: "TAM_THAN",

  KHAM_HONG: "KHAM_HONG",
  DIEM_MALLAMPATI: "DIEM_MALLAMPATI",
  TONG_DIEM_BUON_NGU: "TONG_DIEM_BUON_NGU",
  // TONG_DIEM_BUON_NGU_SHOW:"TONG_DIEM_BUON_NGU_SHOW",
  DIEM_STOP_BANG: "DIEM_STOP_BANG",
  // DIEM_STOP_BANG_SHOW:"DIEM_STOP_BANG_SHOW",
  BO_PHAN_KHAC: "CAC_BO_PHAN_KHAC",
  KHAM_NOI_GIAC_NGU: "KHAM_NOI_GIAC_NGU",
  THANG_DIEM_STOP_BANG: "THANG_DIEM_STOP_BANG",
  LICH_KHAM_ID: "LICH_KHAM_ID",

  // TIEN_SU_GIA_DINH: "TIEN_SU_GIA_DINH",
  KHAM_LAM_SANG: "KHAM_LAM_SANG",
  CHAN_DOAN_LAM_SANG: "CHAN_DOAN_LAM_SANG",
  CHAN_DOAN_XAC_DINH: "CHAN_DOAN_XAC_DINH",
  CACH_GIAI_QUYET: "CACH_GIAI_QUYET",
  GHI_CHU: "GHI_CHU",
  KQ_DIEU_TRI: "KQ_DIEU_TRI",
  XU_TRI: "XU_TRI",
  TAI_KHAM: "TAI_KHAM",
  TAI_KHAM_NGAY: "TAI_KHAM_NGAY",
  TAI_KHAM_LOAI: "TAI_KHAM_LOAI",
  TAI_KHAM_SAU: "TAI_KHAM_SAU",
  data_benh: "data_benh",
  benh_chinh: "benh_chinh",
  benh_phu: "benh_phu",
  KHOA_NOI_TRU: "KHOA_NOI_TRU",
  LOI_DAN: "LOI_DAN",
  data_benh_yhct: "data_benh_yhct",
  benh_yhct_chinh: "benh_yhct_chinh",
  benh_yhct_phu: "benh_yhct_phu",
  BAC_SI_KHAM: "BAC_SI_KHAM",

  MAT_TRAI_CO_KINH: 'MAT_TRAI_CO_KINH',
  MAT_TRAI_K_KINH: 'MAT_TRAI_K_KINH',
  MAT_PHAI_CO_KINH: 'MAT_PHAI_CO_KINH',
  MAT_PHAI_K_KINH: 'MAT_PHAI_K_KINH',

  THI_THAM_TAI_TRAI: 'NOI_THAM_TRAI',
  THI_THAM_TAI_PHAI: 'NOI_THAM_PHAI',
  THUONG_TAI_TRAI: 'NOI_THUONG_TRAI',
  THUONG_TAI_PHAI: 'NOI_THUONG_PHAI',
  RANG_TON_THUONG: 'RANG_TON_THUONG',
  SO_DO_RANG: 'SO_DO_RANG',

  HAM_TREN_SO_BO: 'HAM_TREN_SO_BO',
  HAM_DUOI_SO_BO: 'HAM_DUOI_SO_BO',
  HAM_TREN_XAC_DINH: 'HAM_TREN_XAC_DINH',
  HAM_DUOI_XAC_DINH: 'HAM_DUOI_XAC_DINH',

  BAC_SI_ID: "BAC_SI_ID",
  GHI_CHU_TAI_KHAM: "GHI_CHU_TAI_KHAM",
  DON_DINH_DUONG: "DON_DINH_DUONG",
  DON_TAP_LUYEN: "DON_TAP_LUYEN",
  MUC_DO_CAM_KET: "MUC_DO_CAM_KET",

  // Sản phụ
  TUOI_CO_KINH_NGUYET: "TUOI_CO_KINH_NGUYET",
  KINH_NGUYET_DEU: "KINH_NGUYET_DEU",
  CHU_KY_KINH: "CHU_KY_KINH",
  LUONG_KINH: "LUONG_KINH",
  DAU_BUNG_KINH: "DAU_BUNG_KINH",
  DA_LAP_GIA_DINH: "DA_LAP_GIA_DINH",
  PARA: "PARA",
  MO_SAN_PHU_KHOA: "MO_SAN_PHU_KHOA",
  DESC_MO_SAN_PHU_KHOA: "DESC_MO_SAN_PHU_KHOA",
  BIEN_PHAP_TRANH_THAI: "BIEN_PHAP_TRANH_THAI",
  DESC_BIEN_PHAP_TRANH_THAI: "DESC_BIEN_PHAP_TRANH_THAI",
  NGAY_KINH_CUOI: "NGAY_KINH_CUOI",
  DU_KIEN_SINH: "DU_KIEN_SINH",
  TUOI_THAI_HIEN_TAI: "TUOI_THAI_HIEN_TAI",
  BENH_HUYET_AP: "BENH_HUYET_AP",
  BENH_TUYEN_GIAP: "BENH_TUYEN_GIAP",
  DAI_THAO_DUONG: "DAI_THAO_DUONG",
  TINH_THAN: "TINH_THAN",
  PHU: "PHU",
  DAU_VET_MO: "DAU_VET_MO",
  KHUNG_CHAU: "KHUNG_CHAU",
  CHIEU_CAO_TU_CUNG: "CHIEU_CAO_TU_CUNG",
  NGOI_THAI: "NGOI_THAI",
  DESC_NGOI_THAI: "DESC_NGOI_THAI",
  CON_CO_TU_CUNG: "CON_CO_TU_CUNG",
  TAN_SO_CON_CO_TU_CUNG: "TAN_SO_CON_CO_TU_CUNG",
  TIM_THAI: "TIM_THAI",
  NHIP_TIM_THAI: "NHIP_TIM_THAI",
  CO_TU_CUNG: "CO_TU_CUNG",
  CO_TU_CUNG_MO: "CO_TU_CUNG_MO",
  DAU_OI: "DAU_OI",
  DAU_OI_TIME: "DAU_OI_TIME",
  NUOC_OI: "NUOC_OI",
  NHAN_XET: "NHAN_XET",
};