import { Form, InputNumber, Layout } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { ActionButton, Select, Table } from "components";
import styles from "../vattu.module.less";
import { calcTotal, formatCurrency2 } from "helpers";
import { useSelector } from "react-redux";
import { formatRateNumber } from "pages/QuanLyGoiDichVu/DanhSachGoiDichVu/helpers";
import moment from "moment";
import { ArrowDownCollapse, ArrowRightCollapse, Check_box as CheckBox, UnCheck } from "assets/svg";
import SelectPhongThHien from "./SelectPhongThHien";
import { getLieuTrinhData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import InputAutoFillPrice from "./InputAutoFillPrice";
// import SelectChangeTarget from "components_v2/SelectChangeTarget/SelectChangeTarget";

const TableDeXuat = ({ dataSource, setDataSource, readOnly, planMode, selectedTab, index, formBundle, hideHeader }) => {

  // const syncTargetRef = useRef()

  const { planData, servicePlanList, usedList } = useSelector(getLieuTrinhData);

  const [expandedRow, setExpandedRow] = useState(["DVCD"])

  const preventChange = (record) => (
    record.old &&
    selectedTab === "PHAC_DO_SU_DUNG" &&
    usedList.some(item => item.DANH_SACH_DICH_VU?.some(i => i.DICH_VU_ID === record.DICH_VU_ID)) &&
    record.MIEN_GIAM > 0
  )

  useEffect(() => {
    usedList[0] && setExpandedRow([...usedList.flatMap(i => i.KE_HOACH_NGAY), "DVCD"])
  }, [usedList])


  const calcPlanData = (arr, key) => {
    return arr
      .filter((_, num) => num !== index)
      .flatMap(i => i.DANH_SACH_DICH_VU).reduce((acc, cur) => {
        const found = acc.find(val => val[key] === cur[key])
        if (found) {
          found.SO_LUONG += Number(cur?.SO_LUONG)
        }
        else {
          acc.push({ ...cur, SO_LUONG: Number(cur?.SO_LUONG) })
        }
        return acc
      }, [])
  }


  const ColumnsTable = [
    {
      title: i18n.t(languageKeys.da_TH),
      dataIndex: 'isDone',
      // align: 'center',
      width: 85,
      onCell: (record) => ({ colSpan: record.title ? 15 : 1 }),
      render: (isDone, record) =>
        record.title
          ? <label>{record.title}</label>
          : (isDone === "KHAM_XONG" || isDone === "HOAN_THANH")
            ? <CheckBox style={{ marginBottom: -6, marginLeft: record.DV_KEM_THEO?.[0] && 7 }} />
            : <UnCheck style={{ marginBottom: -6, marginLeft: record.DV_KEM_THEO?.[0] && 7 }} />
    },
    {
      title: i18n.t(languageKeys.field_Ma_dich_vu),
      dataIndex: 'MA_DICHVU',
      width: 100,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (_, record) => record.MA_DICHVU || record.MA_DV
    },
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      dataIndex: 'TEN_DICHVU',
      width: 200,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (_, record) => record.TEN_DICHVU || record.TEN_DICH_VU_KEM_THEO || record.TEN_DV
    },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "SO_LUONG",
      width: 75,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (SO_LUONG, record) =>
        record.isDone
          ? SO_LUONG
          : !(planMode && record.DV_KEM_THEO?.length) && (
            <InputNumber
              value={SO_LUONG}
              min={calcPlanData(usedList, 'DICH_VU_ID').find(x => record.DV_ID === x.DICH_VU_ID) ? 0 : 1}
              max={
                planMode
                  ? (
                    servicePlanList
                      ?.find(i => i.DV_ID === (record.DV_ID ?? record.DICH_VU_GOC))?.SO_LUONG
                    - (calcPlanData(planData, '_id')?.find(i => i.DV_ID === record.DV_ID)?.SO_LUONG ?? 0)
                  ) : undefined
              }
              onChange={(value) => handleAutoCalc(value, "SO_LUONG", record)}
              readOnly={
                record.DV_KEM_THEO_ID ||
                readOnly ||
                (selectedTab === "PHAC_DO_SU_DUNG" && (record.DICH_VU_GOC || record.DV_KEM_THEO?.[0])) ||
                preventChange(record)
              }
            />
          ),
    },
    {
      title: i18n.t(languageKeys.loai_gia),
      dataIndex: "DM_DOI_TUONG_ID",
      width: 140,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (id, record) =>
        record.isDone
          ? i18n.t(languageKeys.title_Gia_dich_vu)
          : !(planMode && record.DV_KEM_THEO?.length) && (
            // <SelectChangeTarget
            //   id={id}
            //   record={record}
            //   syncTargetRef={syncTargetRef}
            //   handleChangeTarget={handleChangeTarget}
            //   dataSource={dataSource}
            //   readOnly={readOnly}
            // />
            <Select
              dataSource={[{ label: i18n.t(languageKeys.title_Gia_dich_vu), value: null }]}
              titleKey='label'
              valueKey='value'
              value={null}
              style={{ width: "100%" }}
              readOnly={readOnly}
            />
          ),
    },
    {
      title: i18n.t(languageKeys.field_don_gia),
      dataIndex: "DON_GIA",
      width: 105,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (DON_GIA, record) => formatCurrency2(planMode ? record.DON_GIA_PHAN_BO : DON_GIA, '', true)
    },
    {
      title: i18n.t(languageKeys.thanh_tien),
      dataIndex: "THANH_TIEN",
      width: 105,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (THANH_TIEN, record) => formatCurrency2(planMode ? record.THANH_TIEN_PHAN_BO : THANH_TIEN, '', true)
    },
    {
      title: i18n.t(languageKeys.don_gia_phan_bo),
      dataIndex: "DON_GIA_PHAN_BO",
      ellipsis: true,
      width: 145,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (DON_GIA_PHAN_BO, record) =>
        record.isDone
          ? formatCurrency2(DON_GIA_PHAN_BO, '', true)
          : (
            <InputNumber
              defaultValue={0}
              value={DON_GIA_PHAN_BO}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              min={0}
              onChange={(value) => handleAutoCalc(value, "DON_GIA_PHAN_BO", record)}
              controls={false}
              readOnly={readOnly || preventChange(record)}
            />
          ),
    },
    {
      title: i18n.t(languageKeys.thanh_tien_phan_bo),
      dataIndex: "THANH_TIEN_PHAN_BO",
      width: 140,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (THANH_TIEN_PHAN_BO) => formatCurrency2(THANH_TIEN_PHAN_BO, "", true),
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      dataIndex: "MIEN_GIAM_PHAN_TRAM",
      ellipsis: true,
      width: 145,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (MGTD, record) =>
        record.isDone
          ? /*(MGTD > 0 ? `${MGTD}%` : formatCurrency2(record.MIEN_GIAM, '', true))*/'-'
          : (
            <InputNumber
              defaultValue={0}
              value={MGTD ?? record.MIEN_GIAM}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              min={0}
              max={
                preventChange(record)
                  ? undefined
                  : record.THANH_TIEN_PHAN_BO
              }
              onChange={(value) => handleAutoCalc(value, "MIEN_GIAM", record)}
              controls={false}
              readOnly={readOnly || preventChange(record)}
              addonAfter={(MGTD || record.MIEN_GIAM === 0) ? "%" : "đ"}
            />
          ),
    },
    {
      title: i18n.t(languageKeys.tong_tien),
      dataIndex: "TONG_TIEN",
      width: 105,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (TONG_TIEN, record) => record.isDone ? '-' : formatCurrency2(TONG_TIEN, "", true),
    },
    {
      title: i18n.t(languageKeys.ty_le),
      dataIndex: "TY_LE",
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      width: 60,
    },
    {
      title: <>{i18n.t(languageKeys.noi_thuc_hien)}<span style={{ color: 'red', marginLeft: '4px', fontSize: '16px' }}>*</span></>,
      dataIndex: "PHONG_THUC_HIEN_ID",
      width: 200,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (PHONG_THUC_HIEN_ID, record) =>
        record.isDone
          ? record.TEN_PHONG_THUC_HIEN
          : !record.DV_KEM_THEO?.length && (
            <SelectPhongThHien
              value={PHONG_THUC_HIEN_ID}
              record={record}
              onSelect={handleSelectRoom}
              readOnly={readOnly}
            />
          ),
    },
    {
      title: i18n.t(languageKeys.nguoi_de_xuat),
      dataIndex: "BAC_SI_DE_XUAT",
      width: 150,
    },
    {
      title: i18n.t(languageKeys.nguoi_tao),
      dataIndex: "HO_TEN_NGUOI_TAO",
      width: 150,
    },
    {
      title: i18n.t(languageKeys.ngay_tao),
      dataIndex: "NGAY_TAO",
      width: 150,
      render: (_, record) =>
        (record.NGAY_TAO || record.GIO_TAO)
          ? `${record.GIO_TAO ? `${record.GIO_TAO} -` : ""} ${moment(record.NGAY_TAO).format('DD/MM/YYYY')}`
          : ''
    },
    {
      title: i18n.t(languageKeys.nguoi_cap_nhat),
      dataIndex: "HO_TEN_NGUOI_CAP_NHAT",
      width: 150,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
    },
    {
      title: i18n.t(languageKeys.ngay_cap_nhat),
      dataIndex: "NGAY_CAP_NHAT",
      width: 150,
      onCell: (record) => ({ colSpan: record.title ? 0 : 1 }),
      render: (_, record) =>
        (record.NGAY_CAP_NHAT || record.GIO_CAP_NHAT)
          ? `${record.GIO_CAP_NHAT ? `${record.GIO_CAP_NHAT} -` : ""} ${moment(record.NGAY_CAP_NHAT).format('DD/MM/YYYY')}`
          : planMode
            ? record.THOI_GIAN_SUA ? moment(record.THOI_GIAN_SUA).format('HH:mm - DD/MM/YYYY') : ''
            : ''
    },
    {
      key: "action",
      fixed: "right",
      width: 35,
      render: (_, record) =>
        !(record.DV_KEM_THEO_ID || (!planMode && record.DICH_VU_GOC) || record.title || record.isDone) &&
        !readOnly && (
          <ActionButton
            record={record}
            showEdit={false}
            onDelete={() => handleDelete(record)}
            tooltipXoaTheoQuyen
          />
        ),
    },
  ];


  const enhanceDataSource = (_dataSource = []) => {
    let DsDv = []
    //
    if (selectedTab === "KE_HOACH_SD") {
      if (_dataSource.length > 0) {
        servicePlanList?.forEach(dv => {
          if (dv?.DV_KEM_THEO?.[0]) {
            let foundCombo = _dataSource.filter(i => dv?.DV_KEM_THEO?.some(dvkt => dvkt?.DV_ID === i?.DV_ID))
            foundCombo.length && DsDv.push({ ...dv, _id: dv?.ID, DV_KEM_THEO: foundCombo })
          } else {
            let foundService = _dataSource.find(i => i?.DV_ID === dv?.DV_ID)
            foundService && DsDv.push(foundService)
          }
        })
      }
    }
    //
    if (selectedTab === "PHAC_DO_SU_DUNG") {
      DsDv = orderBy(usedList, ["KE_HOACH_NGAY"]).map(item => ({
        index: item.KE_HOACH_NGAY,
        title: moment(item.NGAY, "YYYYMMDD").format("DD/MM/YYYY"),
        DV_KEM_THEO: item.DANH_SACH_DICH_VU.map(i => ({
          ...i,
          isDone: i.TRANG_THAI,
          THANH_TIEN: i.SO_LUONG * i.DON_GIA,
          THANH_TIEN_PHAN_BO: i.SO_LUONG * i.DON_GIA_PHAN_BO,
          HO_TEN_NGUOI_CAP_NHAT: i.HO_TEN_NGUOI_SUA || i.HO_TEN_NGUOI_TAO,
        }))
      }))
      DsDv = [...DsDv, {
        index: "DVCD",
        title: i18n.t(languageKeys.dv_chua_dung).toUpperCase(),
        DV_KEM_THEO: _dataSource.map((i, index) => {
          const sl = (item) => item.SO_LUONG - (
            i.old
              ? (calcPlanData(usedList, 'DICH_VU_ID').find(x => i.DICH_VU_ID === x.DICH_VU_ID)?.SO_LUONG ?? 0)
              : 0
          )
          return {
            ...i,
            SO_LUONG: sl(i),
            THANH_TIEN: sl(i) * i.DON_GIA,
            THANH_TIEN_PHAN_BO: sl(i) * i.DON_GIA_PHAN_BO,
            TONG_TIEN: preventChange(i)
              ? i.TONG_TIEN / i.SO_LUONG * sl(i)
              : i.TONG_TIEN,
            // DV_KEM_THEO: i.DV_KEM_THEO?.map(dvkt => ({
            //   ...dvkt,
            //   SO_LUONG: sl(dvkt),
            //   THANH_TIEN: sl(dvkt) * i.DON_GIA,
            //   THANH_TIEN_PHAN_BO: sl(dvkt) * i.DON_GIA_PHAN_BO,
            // })),
            index,
          }
        })
      }]
    }
    //
    return DsDv;
  };


  const handleSelectRoom = (data, record) => {
    setDataSource(dataSource.map((item) => {
      if (item.DV_ID === record.DV_ID) {
        if (!record.DV_KEM_THEO_ID) {
          return { ...item, PHONG_THUC_HIEN_ID: data.ID }
        } else {
          return {
            ...item,
            DV_KEM_THEO: item.DV_KEM_THEO.map(dvkt =>
              dvkt.DV_KEM_THEO_ID === record.DV_KEM_THEO_ID
                ? { ...dvkt, PHONG_THUC_HIEN_ID: data.ID }
                : dvkt
            )
          }
        }
      } else { return item; }
    }))
  }


  // const handleChangeTarget = (value, record, disable, changeAll) => {
  //   //
  //   const GIA_DT = (obj) => {
  //     const data = obj.LIST_GIA_THEO_DICH_VU?.find(i => i.DM_DOI_TUONG_ID === value)
  //     return data
  //         ? data.GIA_DICH_VU
  //         : obj.LIST_GIA_THEO_DICH_VU?.find(i => !!i.DOI_TUONG_MAC_DINH)?.GIA_DICH_VU
  // }
  //   if (changeAll) {
  //     setDataSource(dataSource.map(dv => ({
  //       ...dv,
  //       DON_GIA: GIA_DT(dv) ?? dv.DON_GIA,
  //       THANH_TIEN: GIA_DT(dv) ? (GIA_DT(dv) * dv.SO_LUONG) : dv.THANH_TIEN,
  //       DM_DOI_TUONG_ID: value,
  //     })))
  //     syncTargetRef.current?.setVisible(false)
  //   } else {
  //     if (disable) {
  //       setDataSource(dataSource.map(item =>
  //         item.DV_ID === record.DV_ID
  //           ? {
  //             ...item,
  //             DON_GIA: GIA_DT(item) ?? item.DON_GIA,
  //             THANH_TIEN: GIA_DT(item) ? (GIA_DT(item) * item.SO_LUONG) : item.THANH_TIEN,
  //             DM_DOI_TUONG_ID: value,
  //           }
  //           : item
  //       ))
  //     } else {
  //       syncTargetRef.current?.open({ value, record })
  //     }
  //   }
  // };


  const getNewRateArr = (arr = []) => {
    if (arr[0]) {
      let newData = arr.map(item => ({
        ...item,
        TY_LE: formatRateNumber(item.TONG_TIEN / calcTotal(arr, "TONG_TIEN") * 100),
      }))
      const currRate = calcTotal(newData, "TY_LE")
      if (currRate !== 100) {
        newData[0] = {
          ...newData[0],
          TY_LE: (Number(newData[0].TY_LE) + 100 - currRate).toFixed(2),
        }
      }
      return newData;
    } else {
      return arr;
    }
  }


  const handleAutoCalc = (value, keyVal, record) => {
    //
    setDataSource(() => {
      let newArr = []
      newArr = dataSource.map((item) => {
        if (planMode
          ? item._id === record._id
          : item.DV_ID === record.DV_ID
        ) {
          //
          if (keyVal === "SO_LUONG") {
            //
            const sl = value + (
              record.old
                ? calcPlanData(usedList, 'DICH_VU_ID').find(x => record.DV_ID === x.DICH_VU_ID)?.SO_LUONG ?? 0
                : 0
            )
            const MIEN_GIAM = item.MIEN_GIAM_PHAN_TRAM
              ? sl * item.DON_GIA_PHAN_BO / 100 * item.MIEN_GIAM_PHAN_TRAM
              : item.MIEN_GIAM
            //
            return {
              ...item,
              SO_LUONG: sl,
              THANH_TIEN: sl * item.DON_GIA,
              THANH_TIEN_PHAN_BO: sl * item.DON_GIA_PHAN_BO,
              TONG_TIEN: planMode
                ? (item.DON_GIA_PHAN_BO - MIEN_GIAM) * sl
                : sl * item.DON_GIA_PHAN_BO - MIEN_GIAM,
              // DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
              //   ...dvkt,
              //   SO_LUONG: sl,
              //   THANH_TIEN_PHAN_BO: sl * dvkt.DON_GIA_PHAN_BO,
              //   THANH_TIEN: sl * dvkt.DON_GIA - dvkt.MIEN_GIAM,
              // }))
            }
          }
          //
          if (keyVal === "DON_GIA_PHAN_BO") {
            //
            // if (record.DV_KEM_THEO?.[0]) {
            //   // const totalRate = item.DV_KEM_THEO.reduce((acc, obj) => acc + (obj.DON_GIA / item.DON_GIA), 0)
            //   // const calcRate = (totalRate - 1) / item.DV_KEM_THEO.length
            //   // return {
            //   //   ...item,
            //   //   DON_GIA_PHAN_BO: value,
            //   //   THANH_TIEN_PHAN_BO: value * item.SO_LUONG,
            //   //   DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
            //   //     ...dvkt,
            //   //     DON_GIA_PHAN_BO: Math.round(value * (dvkt.DON_GIA / item.DON_GIA - calcRate)),
            //   //     THANH_TIEN_PHAN_BO: value * (dvkt.DON_GIA / item.DON_GIA - calcRate) * item.SO_LUONG,
            //   //   }))
            //   // }
            //   return {
            //     ...item,
            //     DON_GIA_PHAN_BO: value,
            //     THANH_TIEN_PHAN_BO: value * item.SO_LUONG,
            //     TONG_TIEN: value * item.SO_LUONG - item.MIEN_GIAM,
            //     DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
            //       ...dvkt,
            //       DON_GIA_PHAN_BO: value / item.DV_KEM_THEO.length,
            //       THANH_TIEN_PHAN_BO: value / item.DV_KEM_THEO.length * item.SO_LUONG,
            //       TONG_TIEN: value / item.DV_KEM_THEO.length * item.SO_LUONG - dvkt.MIEN_GIAM,
            //     }))
            //   }
            // }
            //
            if (!record.DV_KEM_THEO_ID) {
              return {
                ...item,
                DON_GIA_PHAN_BO: value,
                THANH_TIEN_PHAN_BO: value * item.SO_LUONG,
                TONG_TIEN: value * item.SO_LUONG - (
                  item.MIEN_GIAM_PHAN_TRAM
                    ? value * item.SO_LUONG / 100 * item.MIEN_GIAM_PHAN_TRAM
                    : item.MIEN_GIAM
                ),
              }
              //
            } else {
              // let newDvkt = item.DV_KEM_THEO?.map(dvkt =>
              //   dvkt.DV_KEM_THEO_ID === record.DV_KEM_THEO_ID
              //     ? {
              //       ...dvkt,
              //       DON_GIA_PHAN_BO: value,
              //       THANH_TIEN_PHAN_BO: value * dvkt.SO_LUONG,
              //       TONG_TIEN: value * dvkt.SO_LUONG - dvkt.MIEN_GIAM,
              //     }
              //     : dvkt
              // )
              // return {
              //   ...item,
              //   DON_GIA_PHAN_BO: calcTotal(newDvkt, "DON_GIA_PHAN_BO"),
              //   THANH_TIEN_PHAN_BO: calcTotal(newDvkt, "THANH_TIEN_PHAN_BO"),
              //   TONG_TIEN: calcTotal(newDvkt, "TONG_TIEN"),
              //   DV_KEM_THEO: newDvkt
              // }
            }
          }
          //
          if (keyVal === "MIEN_GIAM") {
            //
            const calcEX = value > 100 ? value : (record.THANH_TIEN_PHAN_BO / 100 * value)
            //
            // if (record.DV_KEM_THEO?.[0]) {
            //   return {
            //     ...item,
            //     MIEN_GIAM: calcEX,
            //     MIEN_GIAM_PHAN_TRAM: value > 100 ? null : value,
            //     TONG_TIEN: item.THANH_TIEN_PHAN_BO - calcEX,
            //     DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
            //       ...dvkt,
            //       TONG_TIEN: dvkt.THANH_TIEN_PHAN_BO - calcEX / item.DV_KEM_THEO.length,
            //     }))
            //   }
            // }
            //
            if (!record.DV_KEM_THEO_ID) {
              return {
                ...item,
                MIEN_GIAM: calcEX,
                MIEN_GIAM_PHAN_TRAM: value > 100 ? null : value,
                TONG_TIEN: item.THANH_TIEN_PHAN_BO - calcEX,
              }
              //
            } else {
              // let newDvkt = item.DV_KEM_THEO?.map(dvkt =>
              //   dvkt.DV_KEM_THEO_ID === record.DV_KEM_THEO_ID
              //     ? {
              //       ...dvkt,
              //       MIEN_GIAM: calcEX,
              //       MIEN_GIAM_PHAN_TRAM: value > 100 ? null : value,
              //       TONG_TIEN: dvkt.THANH_TIEN_PHAN_BO - calcEX,
              //     }
              //     : dvkt
              // )
              // return {
              //   ...item,
              //   MIEN_GIAM: calcTotal(newDvkt, "MIEN_GIAM"),
              //   MIEN_GIAM_PHAN_TRAM: null,
              //   TONG_TIEN: calcTotal(newDvkt, "TONG_TIEN"),
              //   DV_KEM_THEO: newDvkt
              // }
            }
          }
          //
          return { ...item, [keyVal]: value }
        } else {
          return item
        }
      })
      //
      return getNewRateArr(newArr)
    });
  }


  const handleDelete = (record) => {
    setDataSource(() => {
      let newArr = []
      if (planMode) {
        newArr = dataSource.filter((item) => record.DV_KEM_THEO?.[0] ? item.DICH_VU_GOC !== record.DV_ID : item._id !== record._id)
      } else {
        if (selectedTab === "PHAC_DO_SU_DUNG") {
          newArr = dataSource.filter(i => i.DV_ID !== record.DV_ID)
        } else {
          newArr = dataSource.filter((_, index) => record.index !== index)
        }
      }
      return getNewRateArr(newArr)
    })
  }


  const filterColumns = () => {
    switch (selectedTab) {
      case "PHAC_DO_DE_XUAT":
        return ColumnsTable.filter(item =>
          !["isDone", "BAC_SI_DE_XUAT", "HO_TEN_NGUOI_TAO", "NGAY_TAO"].some(i => i === item.dataIndex)
        );
      case "PHAC_DO_KHOI_TAO":
        return ColumnsTable.filter(item =>
          !["isDone"].some(i => i === item.dataIndex)
        );
      case "KE_HOACH_SD":
        return ColumnsTable.filter(item =>
          !["isDone", "BAC_SI_DE_XUAT", "DON_GIA_PHAN_BO", "THANH_TIEN_PHAN_BO", "MIEN_GIAM_PHAN_TRAM", "TONG_TIEN", "TY_LE"].some(i => i === item.dataIndex)
        );
      case "PHAC_DO_SU_DUNG":
        return ColumnsTable.filter(item =>
          !["BAC_SI_DE_XUAT", "HO_TEN_NGUOI_TAO", "NGAY_TAO"].some(i => i === item.dataIndex)
        );
      default:
        return ColumnsTable;
    }
  }


  return (
    <Layout>

      {!hideHeader && (
        <Layout.Header className={styles.headerTable}>
          <h4>{i18n.t(languageKeys.tt_kham_goi_lieu_trinh)}</h4>
          {selectedTab !== "KE_HOACH_SD" && selectedTab !== "PHAC_DO_SU_DUNG" && (
            <Form form={formBundle}>
              <Form.Item
                // rules={[{ required: true, message: "" }]}
                label={i18n.t(languageKeys.so_buoi_sd)}
                name="SO_BUOI_SU_DUNG"
              >
                <InputNumber
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  min={1}
                  readOnly={readOnly}
                  onPressEnter={({ target: { value } }) =>
                    setDataSource(dataSource.map(item => ({
                      ...item,
                      SO_LUONG: value,
                      THANH_TIEN_PHAN_BO: value * item.DON_GIA_PHAN_BO,
                      THANH_TIEN: value * item.DON_GIA,
                    })))
                  }
                />
              </Form.Item>
            </Form>
          )}
        </Layout.Header >
      )}

      <Layout.Content
        className={styles.contentTable}
        style={
          hideHeader
            ? selectedTab === "KE_HOACH_SD" ? { paddingTop: 0 } : { padding: 0 }
            : {}
        }
      >
        <Table
          className={styles["table"]}
          scroll={{ y: selectedTab === "KE_HOACH_SD" ? 326 : 350 }}
          columns={filterColumns()}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowKeys: expandedRow,
            onExpand: (expanded, record) =>
              setExpandedRow(!expanded
                ? expandedRow.filter(i => i !== record[planMode ? '_id' : 'index'])
                : [...expandedRow, record[planMode ? '_id' : 'index']]
              ),
            expandIcon: ({ expanded, onExpand, record }) =>
              record.DV_KEM_THEO?.length
                ? (
                  expanded
                    ? <ArrowDownCollapse onClick={(e) => onExpand(record, e)} />
                    : <ArrowRightCollapse onClick={(e) => onExpand(record, e)} />
                )
                : <></>,
            childrenColumnName: "DV_KEM_THEO"
          }}
          dataSource={
            planMode || selectedTab === "PHAC_DO_SU_DUNG"
              ? enhanceDataSource(dataSource)
              : dataSource.map((item, index) => ({ ...item, index }))
          }
          rowKey={planMode ? '_id' : 'index'}
        />

        {
          !planMode
            ? (
              <div
                className={styles["footer-table"]}
                style={{ paddingRight: selectedTab === "PHAC_DO_KHOI_TAO" ? 7 : 12 }}
              >
                <div>
                  <p>{i18n.t(languageKeys.tong_SL)}:</p>
                  <p className={styles["text-color"]}>{calcTotal(dataSource, "SO_LUONG")}</p>
                </div>
                <div>
                  <p>{i18n.t(languageKeys.tong_thanh_tien)}:</p>
                  <p className={styles["text-color"]}>
                    {formatCurrency2(calcTotal(dataSource, "THANH_TIEN"), '', true)}
                  </p>
                </div>
                <div>
                  <p>{i18n.t(languageKeys.tong_tien_phan_bo)}:</p>
                  <p className={styles["text-color"]}>
                    {formatCurrency2(calcTotal(dataSource, "THANH_TIEN_PHAN_BO"), '', true)}
                  </p>
                </div>
                <div>
                  <p>{i18n.t(languageKeys.field_Mien_giam)}:</p>
                  <p className={styles["text-color"]}>
                    {formatCurrency2(calcTotal(dataSource, "MIEN_GIAM"), '', true)}
                  </p>
                </div>
                <div>
                  <p>{i18n.t(languageKeys.tong_tien)}:</p>
                  {
                    selectedTab === "PHAC_DO_KHOI_TAO"
                      ? (
                        <InputAutoFillPrice
                          dataSource={dataSource}
                          setDataSource={setDataSource}
                          readOnly={readOnly}
                        />
                      ) : (
                        <p className={styles["text-color"]}>
                          {formatCurrency2(calcTotal(dataSource, "TONG_TIEN"), '', true)}
                        </p>
                      )
                  }
                </div>
              </div>
            )
            : (
              <div className={styles["footer-table"]}>
                <div>
                  {i18n.t(languageKeys.tong_SL)}:
                  <span className={styles["text-color"]}> {calcTotal(dataSource, "SO_LUONG")}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.tong_thanh_tien)}:
                  <span className={styles["text-color"]}> {formatCurrency2(calcTotal(dataSource, "THANH_TIEN_PHAN_BO"), '', true)}</span>
                </div>
                <div />
                <div />
              </div>
            )
        }

      </Layout.Content>
    </Layout >
  )
}

export default TableDeXuat;