import { languageKeys } from "../../../i18n";
import { keysDanhMuc } from "../../../constants/keys";
import {
  apiKhoaDsBenhVien,
  apiLayDsBenhVien,
  apiNhapDsBenhVien,
  apiSuaBenhVien,
  apiThemBenhVien,
  apiXoaBenhVien,
  apiXoaDsBenhVien,
} from "./apisDanhMuc/apisDmBenhVien";
import {
  apiExportKhoa,
  apiKhoaDsKhoa,
  apiLayDsKhoa,
  apiNhapDsKhoa,
  apiSuaKhoa,
  apiThemKhoa,
  apiXoaDsKhoa,
  apiXoaKhoa,
} from "./apisDanhMuc/apisDmKhoa";
import {
  apiExportPhong,
  apiKhoaDsPhong,
  apiLayDsPhong,
  apiNhapDsPhong,
  apiSuaPhong,
  apiThemPhong,
  apiXoaDsPhong,
  apiXoaPhong,
} from "./apisDanhMuc/apisDmPhong";
import {
  apiExportNhomHoaDon,
  apiKhoaDsNhomHoadon,
  apiLayDsNhomHoaDon,
  apiNhapDsNhomHoaDon,
  apiSuaNhomHoaDon,
  apiThemNhomHoaDon,
  apiXoaDsNhomHoaDon,
  apiXoaNhomHoaDon,
} from "./apisDanhMuc/apisDmNhomHoaDon";
import {
  FormBenhVien,
  FormKhoa,
  FormLoaiDvKiThuat,
  FormNhomDvkt,
  FormNhomHoaDon,
  FormDvkt,
  FormTinh,
  FormHuyen,
  FormXa,
  FormIcd10,
  FormNhomIcd10,
  FormNgheNghiep,
  FormDanToc,
  FormQuocGia,
  FormDonViTinh,
  FormPhongNoiTru,
  FormPPVoCam,
  FormPhuongPhapPTTT,
  FormCachThucPTTT,
  FormCachDung,
  FormHinhThuc,
} from "./FormDanhMuc";
import {
  fieldBenhVien,
  fieldDanhMucMay,
  fieldDantoc,
  fieldDichVuKiThuat,
  fieldDonViTinh,
  fieldDuongDung,
  fieldGiuong,
  fieldHangSanXuat,
  fieldHoatChat,
  fieldHuyen,
  fieldICD10,
  fieldKhoa,
  fieldKhoTuTruc,
  fieldLoaiDvKiThuat,
  fieldNgheNghiep,
  fieldNguon,
  fieldNhomDvKiThuat,
  fieldNhomHoaDon,
  fieldNhomICD10,
  fieldPhong,
  fieldQuocGia,
  fieldThuoc,
  fieldTinh,
  fieldXa,
  fieldXuatKhac,
  fieldDanhMucDoiTuong,
  fieldDanhMucNguonKhach,
  fieldDanhMucCachDung,
  fieldDanhMucHinhThuc,
  fieldPPTT,
  fieldCTTT,
} from "./fieldsDanhMuc";
import {
  apiExportLoaiDvkt,
  apiKhoaDsLoaiDvkt,
  apiLayDsLoaiDvkt,
  apiNhapDsLoaiDvkt,
  apiSuaLoaiDvkt,
  apiThemLoaiDvkt,
  apiXoaDsLoaiDvkt,
  apiXoaLoaiDvkt,
} from "./apisDanhMuc/apisDmLoaiDvkt";
import {
  apiExportNhomDvkt,
  apiKhoaDsNhomDvkt,
  apiLayDsNhomDvkt,
  apiNhapDsNhomDvkt,
  apiSuaNhomDvkt,
  apiThemNhomDvkt,
  apiXoaDsNhomDvkt,
  apiXoaNhomDvkt,
} from "./apisDanhMuc/apiDmNhomDvkt";
import {
  apiExportDvkt,
  apiKhoaDsDvkt,
  apiLayDsDvKiThuat,
  apiNhapDsDvkt,
  apiSuaDvkt,
  apiThemDvkt,
  apiXoaDsDvkt,
  apiXoaDvkt,
} from "./apisDanhMuc/apisDmDvkt";
import {
  apiLayDsICD10,
  apiLayDsDanToc,
  apiLayDsHuyen,
  apiLayDsNgheNghiep,
  apiLayDsNhomICD10,
  apiLayDsQuocGia,
  apiLayDsTinh,
  apiLayDsXaPhuong,
  apiNhapDsQuanHuyen,
  apiNhapDsTinh,
  apiNhapDsXaPhuong,
  apiSuaQuanHuyen,
  apiSuaQuocGia,
  apiSuaTinh,
  apiSuaXaPhuong,
  apiThemQuanHuyen,
  apiThemQuocGia,
  apiThemTinh,
  apiThemXaPhuong,
  apiThemICD10,
  apiSuaICD10,
  apiXoaICD10,
  apiNhapDsICD10,
  apiNhapDsNhomICD10,
  apiThemNhomICD10,
  apiSuaNhomICD10,
  apiXoaNhomICD10,
} from "./apisDanhMuc/apisDanhMuc";
import { apiLayDsXuatKhac, apiSuaXuatKhac, apiThemXuatKhac, apiXoaXuatKhac } from "./apisDanhMuc/apisDmXuatKhac";
import FormXuatKhac from "./FormDanhMuc/FormXuatKhac";
import FormThuoc from "./FormDanhMuc/FormThuoc";
import FormHangSanXuat from "./FormDanhMuc/FormHangSanXuat";
import FormDuongDung from "./FormDanhMuc/FormDuongDung";
import FormHoatChat from "./FormDanhMuc/FormHoatChat";
import {
  apiSuaKhoTuTruc,
  apiLayDsDuongDung,
  apiLayDsHangSx,
  apiLayDsHoatChat,
  apiLayDsKhoTuTruc,
  apiLayDsThuoc,
  apiSuaDuongDung,
  apiSuaHangSx,
  apiSuaHoatChat,
  apiSuaThuoc,
  apiThemDuongDung,
  apiThemHangSx,
  apiThemHoatChat,
  apiThemKhoTuTruc,
  apiThemThuoc,
  apiXoaKhoTuTruc,
  apiXoaThuoc,
  apiXoaHangSx,
  apiXoaDuongDung,
  apiXoaHoatChat,
  apiNhapDsKhoTuTruc,
  apiKhoaDsKhoTuTruc,
  apiXoaDsKhoTuTruc,
  apiNhapDsHoatChat,
  apiKhoaDsHoatChat,
  apiXoaDsHoatChat,
  apiNhapDsDuongDung,
  apiKhoaDsDuongDung,
  apiXoaDsDuongDung,
  apiNhapDsHangSx,
  apiKhoaDsHangSx,
  apiXoaDsHangSx,
  apiNhapDsThuoc,
  apiKhoaDsThuoc,
  apiXoaDsThuoc,
  apiExportThuoc,
  apiExportHangSx,
  apiExportDuongDung,
  apiExportHoatChat,
  apiExportKhoTuTruc,
} from "./apisDanhMuc/apisDmThuoc";
import FormKhoaTuTruc from "./FormDanhMuc/FormKhoTuTruc";
import {
  apiExportDonViTinh,
  apiKhoaDsDonViTinh,
  apiLayDsDonViTinh,
  apiNhapDsDonViTinh,
  apiSuaDonViTinh,
  apiThemDonViTinh,
  apiXoaDonViTinh,
  apiXoaDsDonViTinh,
} from "./apisDanhMuc/apiDmDonViTinh";
import {
  apiExportPhongNoiTru,
  apiNhapDsPhongNoiTru,
  apiSuaPhongNoiTru,
  apiThemPhongNoiTru,
  apiXoaPhongNoiTru,
  apiLayDsPhongNoiTru,
} from "./apisDanhMuc/apisDmPhongNoiTru";
import FormGiuong from "./FormDanhMuc/FormGiuong";

import {
  exportListGiuong,
  importGiuong,
  khoaNhieuGiuong,
  layDsGiuong,
  suaGiuong,
  themGiuong,
  xoaGiuong,
  xoaNhieuGiuong,
} from "./apisDanhMuc/apisDmGiuong";
import FormNguon from "./FormDanhMuc/FormNguon";
import { apiLayDsNguonNhapThuoc, apiSuaNguonNhapThuoc, apiThemNguonNhapThuoc, apiXoaNguonNhapThuoc } from "./apisDanhMuc/apiDmNguon";
import FormKhoThuoc from "./FormDanhMuc/FormKhoThuoc";
import FormDanhMucMay from "./FormDanhMuc/FormDanhMucMay";
import {
  apiKhoaDsMayThucHien,
  apiLayDsMayThucHIen,
  apisThemMayThucHien,
  apiSuaMayThucHien,
  apiXoaDsMayThucHien,
  apiXoaMayThucHien,
} from "./apisDanhMuc/apiDMMay";
import {
  FileMauDmCachDung,
  FileMauDmDichVu,
  FileMauDmDoiTuong,
  FileMauDmDonViTinh,
  FileMauDmDuongDung,
  FileMauDmHangSanXuat,
  FileMauDmHoatChat,
  FileMauDmKhoa,
  FileMauDmLoaiDichVu,
  FileMauDmNguonKhach,
  FileMauDmNhomDichVu,
  FileMauDmPhong,
  FileMauDmThuoc,
} from "../../../assets/HDSD/mauDm";
import { FormDoiTuong } from "./FormDanhMuc/FormDoiTuong";
import {
  apiExportDmDoiTuong,
  apiImportDmDoiTuong,
  apiKhoaDmDoiTuong,
  apiLayDmDoiTuong,
  apiSuaDmDoiTuong,
  apiThemDmDoiTuong,
  apiXoaDmDoiTuong,
} from "./apisDanhMuc/apiDMdoiTuong";
import {
  apiExportDmNguonKhach,
  apiImportDmNguonKhach,
  apiKhoaDmNguonKhach,
  apiLayDmNguonKhach,
  apiSuaDmNguonKhach,
  apiThemDmNguonKhach,
  apiXoaDmNguonKhach,
} from "./apisDanhMuc/apiDMnguonkhach";
import {
  apiExportDmCachDung,
  apiImportDmCachDung,
  apiKhoaDmCachDung,
  apiLayDmCachDung,
  apiSuaDmCachDung,
  apiThemDmCachDung,
  apiXoaDmCachDung,
} from "./apisDanhMuc/apiDmCachDung";
import {
  apiKhoaDmHinhThucThanhToan,
  apiLayHinhThucThanhToan,
  apiSuaDmHinhThucThanhToan,
  apiThemDmHinhThucThanhToan,
  apiXoaDmHinhThucThanhToan,
} from "./apisDanhMuc/apiDmHInhThuc";
import {
  apiLayDmCachThucThuThuat,
  apiLayDmPPTT,
  apiSuaDmCachThucThuThuat,
  apiSuaDmPPTT,
  apiThemDMCTTT,
  apiThemDMPPTT,
  apiXoaHoacSuaNhieuDmCachThucThuThuat,
  apiXoaHoacSuaNhieuDmPPTT,
} from "./apisDanhMuc/apiDmThuThuat";
import { FormNguonKhach } from "./FormDanhMuc/FormNguonKhach";

const mapCateKey = (props) => ({
  /**
   * Danh mục bệnh viện
   */
  [keysDanhMuc.dm_benh_vien]: {
    fieldData: fieldBenhVien,
    formItem: <FormBenhVien {...props} />,
    langKey: languageKeys.cate_benh_vien,
    getList: apiLayDsBenhVien,
    importList: apiNhapDsBenhVien,
    create: apiThemBenhVien,
    edit: apiSuaBenhVien,
    delete: apiXoaBenhVien,
    lockList: apiKhoaDsBenhVien,
    deleteList: apiXoaDsBenhVien,
  },
  /**
   * Danh mục khoa
   */
  [keysDanhMuc.dm_khoa]: {
    fieldData: fieldKhoa,
    formItem: <FormKhoa {...props} />,
    langKey: languageKeys.cate_khoa,
    getList: apiLayDsKhoa,
    importList: apiNhapDsKhoa,
    create: apiThemKhoa,
    edit: apiSuaKhoa,
    delete: apiXoaKhoa,
    lockList: apiKhoaDsKhoa,
    deleteList: apiXoaDsKhoa,
    exportList: apiExportKhoa,
    templateInfo: {
      fileName: "mau_dm_khoa",
      filePath: FileMauDmKhoa,
    },
  },

  // danh mục phòng nội trú
  [keysDanhMuc.dm_noi_tru_phong]: {
    fieldData: fieldPhong,
    formItem: <FormPhongNoiTru {...props} />,
    langKey: "Phòng nội trú",
    getList: apiLayDsPhongNoiTru,
    importList: apiNhapDsPhongNoiTru,
    create: apiThemPhongNoiTru,
    edit: apiSuaPhongNoiTru,
    delete: apiXoaPhongNoiTru,
    lockList: apiKhoaDsPhong,
    deleteList: apiXoaDsPhong,
    exportList: apiExportPhongNoiTru,
  },
  /**
   * Danh mục dịch vụ kĩ thuật
   */
  [keysDanhMuc.dm_dich_vu_ki_thuat]: {
    fieldData: fieldDichVuKiThuat,
    formItem: <FormDvkt {...props} />,
    langKey: languageKeys.cate_dich_vu_ki_thuat,
    getList: apiLayDsDvKiThuat,
    importList: apiNhapDsDvkt,
    create: apiThemDvkt,
    edit: apiSuaDvkt,
    delete: apiXoaDvkt,
    lockList: apiKhoaDsDvkt,
    deleteList: apiXoaDsDvkt,
    exportList: apiExportDvkt,
    templateInfo: {
      fileName: "mau_dm_dich_vu",
      filePath: FileMauDmDichVu,
    },
  },
  /**
   * Danh mục nhóm dịch vụ kĩ thuật
   */
  [keysDanhMuc.dm_nhom_dv_ki_thuat]: {
    fieldData: fieldNhomDvKiThuat,
    formItem: <FormNhomDvkt {...props} />,
    langKey: languageKeys.cate_nhom_dv_ki_thuat,
    getList: apiLayDsNhomDvkt,
    importList: apiNhapDsNhomDvkt,
    create: apiThemNhomDvkt,
    edit: apiSuaNhomDvkt,
    delete: apiXoaNhomDvkt,
    lockList: apiKhoaDsNhomDvkt,
    deleteList: apiXoaDsNhomDvkt,
    exportList: apiExportNhomDvkt,
    templateInfo: {
      fileName: "mau_dm_nhom_dich_vu",
      filePath: FileMauDmNhomDichVu,
    },
  },
  /**
   * Danh mục loại dịch vụ kĩ thuật
   */
  [keysDanhMuc.dm_loai_dv_ki_thuat]: {
    fieldData: fieldLoaiDvKiThuat,
    formItem: <FormLoaiDvKiThuat {...props} />,
    langKey: languageKeys.cate_loai_dv_ki_thuat,
    getList: apiLayDsLoaiDvkt,
    importList: apiNhapDsLoaiDvkt,
    create: apiThemLoaiDvkt,
    edit: apiSuaLoaiDvkt,
    delete: apiXoaLoaiDvkt,
    lockList: apiKhoaDsLoaiDvkt,
    deleteList: apiXoaDsLoaiDvkt,
    exportList: apiExportLoaiDvkt,
    templateInfo: {
      fileName: "mau_dm_loai_dich_vu",
      filePath: FileMauDmLoaiDichVu,
    },
  },
  /**
   * Danh mục nhóm hóa đơn
   */
  [keysDanhMuc.dm_nhom_hoa_don]: {
    fieldData: fieldNhomHoaDon,
    formItem: <FormNhomHoaDon {...props} />,
    langKey: languageKeys.cate_nhom_hoa_don,
    getList: apiLayDsNhomHoaDon,
    importList: apiNhapDsNhomHoaDon,
    create: apiThemNhomHoaDon,
    edit: apiSuaNhomHoaDon,
    delete: apiXoaNhomHoaDon,
    lockList: apiKhoaDsNhomHoadon,
    deleteList: apiXoaDsNhomHoaDon,
    exportList: apiExportNhomHoaDon,
  },
  [keysDanhMuc.dm_thuoc]: {
    fieldData: fieldThuoc,
    // formItem: <ThemMoiThuoc {...props}/>,
    formItem: <FormThuoc {...props} />,
    langKey: languageKeys.cate_thuoc,
    getList: apiLayDsThuoc,
    importList: apiNhapDsThuoc,
    create: apiThemThuoc,
    edit: apiSuaThuoc,
    delete: apiXoaThuoc,
    lockList: apiKhoaDsThuoc,
    deleteList: apiXoaDsThuoc,
    exportList: apiExportThuoc,
    templateInfo: {
      fileName: "mau_dm_thuoc",
      filePath: FileMauDmThuoc,
    },
  },
  [keysDanhMuc.dm_xuat_khac]: {
    fieldData: fieldXuatKhac,
    formItem: <FormXuatKhac {...props} />,
    langKey: languageKeys.cate_xuat_khac,
    getList: apiLayDsXuatKhac,
    create: apiThemXuatKhac,
    edit: apiSuaXuatKhac,
    delete: apiXoaXuatKhac,
  },
  [keysDanhMuc.dm_nguon]: {
    fieldData: fieldNguon,
    formItem: <FormNguon {...props} />,
    langKey: languageKeys.cate_nguon,
    getList: apiLayDsNguonNhapThuoc,
    create: apiThemNguonNhapThuoc,
    edit: apiSuaNguonNhapThuoc,
    delete: apiXoaNguonNhapThuoc,
  },
  [keysDanhMuc.dm_nha_thuoc]: {
    getList: () => {},
    //fieldData: fieldHangSanXuat,
    formItem: <h1>Hang</h1>,
    langKey: languageKeys.cate_nha_thuoc,
    columns: "",
  },
  // /**
  //  * Danh mục hãng sản xuất
  //  */
  [keysDanhMuc.dm_hang_san_xuat]: {
    fieldData: fieldHangSanXuat,
    formItem: <FormHangSanXuat {...props} />,
    langKey: languageKeys.cate_hang_san_xuat,
    getList: apiLayDsHangSx,
    importList: apiNhapDsHangSx,
    create: apiThemHangSx,
    edit: apiSuaHangSx,
    delete: apiXoaHangSx,
    lockList: apiKhoaDsHangSx,
    deleteList: apiXoaDsHangSx,
    exportList: apiExportHangSx,
    templateInfo: {
      fileName: "mau_dm_hang_san_xuat",
      filePath: FileMauDmHangSanXuat,
    },
  },
  /**
   * Danh mục đường dùng
   */
  [keysDanhMuc.dm_duong_dung]: {
    fieldData: fieldDuongDung,
    formItem: <FormDuongDung {...props} />,
    langKey: languageKeys.cate_duong_dung,
    getList: apiLayDsDuongDung,
    importList: apiNhapDsDuongDung,
    create: apiThemDuongDung,
    edit: apiSuaDuongDung,
    delete: apiXoaDuongDung,
    lockList: apiKhoaDsDuongDung,
    deleteList: apiXoaDsDuongDung,
    exportList: apiExportDuongDung,
    templateInfo: {
      fileName: "mau_dm_duong_dung",
      filePath: FileMauDmDuongDung,
    },
  },

  [keysDanhMuc.dm_hoat_chat]: {
    fieldData: fieldHoatChat,
    formItem: <FormHoatChat {...props} />,
    langKey: languageKeys.cate_hoat_chat,
    getList: apiLayDsHoatChat,
    importList: apiNhapDsHoatChat,
    create: apiThemHoatChat,
    edit: apiSuaHoatChat,
    delete: apiXoaHoatChat,
    lockList: apiKhoaDsHoatChat,
    deleteList: apiXoaDsHoatChat,
    exportList: apiExportHoatChat,
    templateInfo: {
      fileName: "mau_dm_hoat_chat",
      filePath: FileMauDmHoatChat,
    },
  },
  /**
   * Danh mục kho, tủ trực
   */
  [keysDanhMuc.dm_kho_tu_truc]: {
    fieldData: fieldKhoTuTruc,
    formItem: <FormKhoaTuTruc {...props} />,
    langKey: languageKeys.cate_kho_tu_truc,
    getList: apiLayDsKhoTuTruc,
    importList: apiNhapDsKhoTuTruc,
    create: apiThemKhoTuTruc,
    edit: apiSuaKhoTuTruc,
    delete: apiXoaKhoTuTruc,
    lockList: apiKhoaDsKhoTuTruc,
    deleteList: apiXoaDsKhoTuTruc,
    exportList: apiExportKhoTuTruc,
  },
  /**
   * Danh mục đơn vị tính
   */
  [keysDanhMuc.dm_don_vi_tinh]: {
    fieldData: fieldDonViTinh,
    formItem: <FormDonViTinh {...props} />,
    langKey: languageKeys.cate_don_vi_tinh,
    getList: apiLayDsDonViTinh,
    importList: apiNhapDsDonViTinh,
    create: apiThemDonViTinh,
    edit: apiSuaDonViTinh,
    delete: apiXoaDonViTinh,
    lockList: apiKhoaDsDonViTinh,
    deleteList: apiXoaDsDonViTinh,
    exportList: apiExportDonViTinh,
    templateInfo: {
      fileName: "mau_dm_don_vi_tinh",
      filePath: FileMauDmDonViTinh,
    },
  },

  
  
  /**
   * Danh mục tỉnh
   */
  [keysDanhMuc.dm_tinh]: {
    fieldData: fieldTinh,
    formItem: <FormTinh {...props} />,
    langKey: languageKeys.cate_tinh,
    getList: apiLayDsTinh,
    importList: apiNhapDsTinh,
    create: apiThemTinh,
    edit: apiSuaTinh,
  },
  /**
   * Danh mục huyện
   */
  [keysDanhMuc.dm_huyen]: {
    fieldData: fieldHuyen,
    formItem: <FormHuyen {...props} />,
    langKey: languageKeys.cate_huyen,
    getList: apiLayDsHuyen,
    importList: apiNhapDsQuanHuyen,
    create: apiThemQuanHuyen,
    edit: apiSuaQuanHuyen,
  },
  /**
   * Danh mục xã
   */
  [keysDanhMuc.dm_xa]: {
    fieldData: fieldXa,
    formItem: <FormXa {...props} />,
    langKey: languageKeys.cate_xa,
    getList: apiLayDsXaPhuong,
    importList: apiNhapDsXaPhuong,
    create: apiThemXaPhuong,
    edit: apiSuaXaPhuong,
  },
  /**
   * Danh mục icd-10
   */
  [keysDanhMuc.dm_icd10]: {
    fieldData: fieldICD10,
    formItem: <FormIcd10 {...props} />,
    langKey: languageKeys.cate_icd10,
    getList: apiLayDsICD10,
    importList: apiNhapDsICD10,
    create: apiThemICD10,
    edit: apiSuaICD10,
    delete: apiXoaICD10,
  },
  /**
   * Danh mục nhóm icd-10
   */
  [keysDanhMuc.dm_nhom_icd10]: {
    fieldData: fieldNhomICD10,
    formItem: <FormNhomIcd10 {...props} />,
    langKey: languageKeys.cate_nhom_icd10,
    getList: apiLayDsNhomICD10,
    importList: apiNhapDsNhomICD10,
    create: apiThemNhomICD10,
    edit: apiSuaNhomICD10,
    delete: apiXoaNhomICD10,
  },
  /**
   * Danh mục nghề nghiệp
   */
  [keysDanhMuc.dm_nghe_nghiep]: {
    fieldData: fieldNgheNghiep,
    formItem: <FormNgheNghiep {...props} />,
    langKey: languageKeys.cate_nghe_nghiep,
    getList: apiLayDsNgheNghiep,
  },
  /**
   * Danh mục dân tộc
   */
  [keysDanhMuc.dm_dan_toc]: {
    fieldData: fieldDantoc,
    formItem: <FormDanToc {...props} />,
    langKey: languageKeys.cate_dan_toc,
    getList: apiLayDsDanToc,
  },
  /**
   * Danh mục quốc gia
   */
  [keysDanhMuc.dm_quoc_gia]: {
    fieldData: fieldQuocGia,
    formItem: <FormQuocGia {...props} />,
    langKey: languageKeys.cate_quoc_gia,
    getList: apiLayDsQuocGia,
    create: apiThemQuocGia,
    edit: apiSuaQuocGia,
    delete: apiThemQuocGia,
  },

  /**
   * Danh mục giường
   */
  [keysDanhMuc.dm_giuong]: {
    fieldData: fieldGiuong,
    formItem: <FormGiuong {...props} />,
    langKey: languageKeys.cate_giuong,
    getList: layDsGiuong,
    importList: importGiuong,
    create: themGiuong,
    edit: suaGiuong,
    delete: xoaGiuong,
    lockList: khoaNhieuGiuong,
    deleteList: xoaNhieuGiuong,
    exportList: exportListGiuong,
  },

  /**
   * Danh mục cách thức PTTT
   */
  [keysDanhMuc.dm_cach_thuc_pttt]: {
    fieldData: fieldCTTT,
    formItem: <FormCachThucPTTT {...props} />,
    langKey: languageKeys.cate_cach_thuc_PTTT,
    getList: apiLayDmCachThucThuThuat,
    // importList: importGiuong,
    create: apiThemDMCTTT,
    edit: apiSuaDmCachThucThuThuat,
    delete: apiXoaHoacSuaNhieuDmCachThucThuThuat,
    lockList: apiXoaHoacSuaNhieuDmCachThucThuThuat,
    deleteList: apiXoaHoacSuaNhieuDmCachThucThuThuat,
    //exportList: exportListGiuong,
  },

  /**
   * Danh mục phương pháp PTTT
   */
  [keysDanhMuc.dm_phuong_phap_pttt]: {
    fieldData: fieldPPTT,
    formItem: <FormPhuongPhapPTTT {...props} />,
    langKey: languageKeys.cate_phuong_phap_PTTT,
    getList: apiLayDmPPTT,
    // importList: importGiuong,
    create: apiThemDMPPTT,
    edit: apiSuaDmPPTT,
    delete: apiXoaHoacSuaNhieuDmPPTT,
    lockList: apiXoaHoacSuaNhieuDmPPTT,
    deleteList: apiXoaHoacSuaNhieuDmPPTT,
    //exportList: exportListGiuong,
  },

  /**
   * Danh mục phương pháp vô cảm
   */
  [keysDanhMuc.dm_pp_vo_cam]: {
    fieldData: fieldGiuong,
    formItem: <FormPPVoCam {...props} />,
    langKey: languageKeys.cate_pp_vo_cam,
    getList: layDsGiuong,
    // importList: importGiuong,
    create: themGiuong,
    edit: suaGiuong,
    delete: xoaGiuong,
    lockList: khoaNhieuGiuong,
    deleteList: xoaNhieuGiuong,
    //exportList: exportListGiuong,
  },

  /**
   * Danh mục Máy
   */
  [keysDanhMuc.dm_may]: {
    fieldData: fieldDanhMucMay,
    formItem: <FormDanhMucMay {...props} />,
    langKey: languageKeys.dm_may,
    getList: apiLayDsMayThucHIen,
    create: apisThemMayThucHien,
    edit: apiSuaMayThucHien,
    delete: apiXoaMayThucHien,
    lockList: apiKhoaDsMayThucHien,
    deleteList: apiXoaDsMayThucHien,
  },
  /**
   * Danh mục nguồn khách
   */
  [keysDanhMuc.dm_nguon_khach]: {
    fieldData: fieldDanhMucNguonKhach,
    formItem: <FormNguonKhach {...props} />,
    langKey: languageKeys.cate_nguon_khach,
    getList: apiLayDmNguonKhach,
    importList: apiImportDmNguonKhach,
    create: apiThemDmNguonKhach,
    edit: apiSuaDmNguonKhach,
    delete: apiXoaDmNguonKhach,
    exportList: apiExportDmNguonKhach,
    lockList: apiKhoaDmNguonKhach,
    deleteList: apiXoaDmNguonKhach,
    templateInfo: {
      fileName: "mau_dm_nguon_khach",
      filePath: FileMauDmNguonKhach,
    },
  },
  /**
   * Danh mục đối tượng
   */
  [keysDanhMuc.dm_doi_tuong]: {
    fieldData: fieldDanhMucDoiTuong,
    formItem: <FormDoiTuong {...props} />,
    langKey: languageKeys.cate_doi_tuong,
    getList: apiLayDmDoiTuong,
    edit: apiSuaDmDoiTuong,
    importList: apiImportDmDoiTuong,
    create: apiThemDmDoiTuong,
    delete: apiXoaDmDoiTuong,
    lockList: apiKhoaDmDoiTuong,
    deleteList: apiXoaDmDoiTuong,
    exportList: apiExportDmDoiTuong,
    templateInfo: {
      fileName: "dm_doi_tuong_mau",
      filePath: FileMauDmDoiTuong,
    },
  },

  /**
   * Danh mục cách dùng
   */
  [keysDanhMuc.dm_cach_dung]: {
    fieldData: fieldDanhMucCachDung,
    formItem: <FormCachDung {...props} />,
    langKey: languageKeys.cate_cach_dung,
    getList: apiLayDmCachDung,
    importList: apiImportDmCachDung,
    create: apiThemDmCachDung,
    edit: apiSuaDmCachDung,
    delete: apiXoaDmCachDung,
    exportList: apiExportDmCachDung,
    lockList: apiKhoaDmCachDung,
    deleteList: apiXoaDmCachDung,
    templateInfo: {
      fileName: "dm_mau_cach_dung",
      filePath: FileMauDmCachDung,
    },
  },
  /**
   * Danh mục hình thức thanh toán
   */
  [keysDanhMuc.dm_hinh_thuc_thanh_toan]: {
    fieldData: fieldDanhMucHinhThuc,
    formItem: <FormHinhThuc {...props} />,
    langKey: languageKeys.cate_hinh_thuc_thanh_toan,
    getList: apiLayHinhThucThanhToan,
    // importList: () => {},
    create: apiThemDmHinhThucThanhToan,
    edit: apiSuaDmHinhThucThanhToan,
    delete: apiXoaDmHinhThucThanhToan,
    // exportList: () => {},
    lockList: apiKhoaDmHinhThucThanhToan,
    deleteList: apiXoaDmHinhThucThanhToan,
    // templateInfo: {
    //   fileName: "dm_mau_cach_dung",
    //   filePath: FileMauDmCachDung,
    // },
  },
});

export default mapCateKey;
